import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '../dialogservice.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomersService } from '../utils/services/customers.service';
import { Customer } from '../utils/models/customer.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-user',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './new-user.component.html',
  styleUrl: './new-user.component.scss',
})
export class NewUserComponent implements OnInit {
  userForm: FormGroup;

  @Input() selectedUser:any = {};
  @Input() isForUpdate: boolean = false;

  @Output() changeinUser: EventEmitter<any> = new EventEmitter();

  public createNewCustomer: boolean = false;
  public customers: string[] = [];

  constructor(public dialogService: DialogService, private fb: FormBuilder, private readonly customersService: CustomersService) {
    this.userForm = this.fb.group({
      username: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      groups: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      enabled: [true, [Validators.required]],
      temporary: [true, [Validators.required]],
      emailVerified: [false, [Validators.required]],
      customerName: [''],
    });
  }

  ngOnInit() {
    this.customersService.findAll().subscribe((customers: Customer[]) => {
      this.customers = customers.map((customer: Customer) => customer.customerName);
      this.customers = [...new Set(this.customers)];

      if (this.isForUpdate) {
        this.userForm.patchValue({
          username: this.selectedUser.username,
          firstName: this.selectedUser.firstName,
          lastName: this.selectedUser.lastName,
          groups: this.selectedUser.role,
          email: this.selectedUser.email,
          enabled: this.selectedUser.enabled,
          emailVerified: this.selectedUser.emailVerified,
          customerName: customers.find((customer: Customer) => customer.userId === this.selectedUser.id)?.customerName,
        });
        this.userForm?.get('password')?.clearValidators();
        this.userForm?.get('temporary')?.clearValidators();
        this.userForm.updateValueAndValidity();
      }
    });
  }

  ngOnChanges() {
    if (this.isForUpdate) {
      this.userForm.get('password')!.clearValidators();
      this.userForm.get('temporary')!.clearValidators();
      this.userForm.updateValueAndValidity();
    } else {
      this.userForm.get('password')!.setValidators([Validators.required]);
      this.userForm.get('temporary')!.setValidators([Validators.required]);
      this.userForm.updateValueAndValidity();
    }
  }

  public toggleCreateNewCustomer(): void {
    this.createNewCustomer = !this.createNewCustomer;
  }

  closeDailog() {
    this.dialogService.showNewUserDialog = false;
  }

  onClickSave(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const {
      username,
      firstName,
      lastName,
      groups,
      email,
      password,
      enabled,
      temporary,
      emailVerified,
      customerName
    } = form.value;
    const obj = {
      username,
      firstName,
      lastName,
      groups: [groups],
      email,
      enabled,
      emailVerified,
      credentials: [
        {
          type: 'password',
          value: password,
          temporary,
        },
      ],
      customerName
    };

    this.changeinUser.emit(obj);
  }
}
