import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { KeycloakGuard } from './app/utils/guards/keycloak.guard';
import { LoginComponent } from './app/login/login.component';
import { LogoutComponent } from './app/logout/logout.component';
import { MainPageComponent } from './app/mainpage/mainpage.component';
import { ProjektübersichtComponent } from './app/projektübersicht/projektübersicht.component';
import { SingleProjectViewComponent } from './app/singleProjectView/singleProjectView.component';
import { AgentviewComponent } from './app/agentview/agentview.component';
import { TimeSlotsDialogComponent } from './app/timeSlotsDialog/timeSlotsDialog.component';
import { OpenTasksComponent } from './app/open-tasks/open-tasks.component';
import { ArchivedAgentsComponent } from './app/archived-agents/archived-agents.component';
import { UserManagementComponent } from './app/user-management/user-management.component';
import { RecordingService } from './app/utils/services/recording.service';
import { VideoRecordingService } from './app/utils/services/video-recording.service';
import { UserService } from './app/utils/services/user.service';
import { WhatsAppMessagesService } from './app/utils/services/whats-app-messages.service';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ConfigService } from './app/config.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AuthInterceptor } from './app/utils/interceptors/auth.interceptor';
import { SocketIoModule } from 'ngx-socket-io';

function initializeKeycloak(configService: ConfigService, keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: configService.config.keycloakUrl,
        realm: 'Smylo',
        clientId: configService.config.keycloakClient
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      shouldAddToken: (request) => {
        const acceptablePaths = ['/api/call'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => request.url.includes(path));
        return !isAcceptablePathMatch;
      },
      loadUserProfileAtStartUp: true
    })
}

const routes: Routes = [
  { path: '', canActivate: [KeycloakGuard], component: AppComponent },
  // { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: MainPageComponent, children: [
		{ path: 'projectoverview', canActivate: [KeycloakGuard], component: ProjektübersichtComponent },
		{ path: 'singleProjectView/:id', canActivate: [KeycloakGuard], component: SingleProjectViewComponent },
		{ path: 'agentView/:id', canActivate: [KeycloakGuard], component: AgentviewComponent },
		{ path: 'agentView', canActivate: [KeycloakGuard], component: AgentviewComponent },
		{ path: 'public/agentView/:publicId', component: AgentviewComponent },
    { path: 'timeslotmodal', canActivate: [KeycloakGuard], component: TimeSlotsDialogComponent },
    { path: 'open-tasks', canActivate: [KeycloakGuard], component: OpenTasksComponent },
    { path: 'archived-agents', canActivate: [KeycloakGuard], component: ArchivedAgentsComponent },
    { path: 'user-management', canActivate: [KeycloakGuard], component: UserManagementComponent }
	]}
];

registerLicense(environment.syncFusionLicenseKey);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(),
    ConfigService,
    KeycloakService,
    RecordingService,
    VideoRecordingService,
    UserService,
    WhatsAppMessagesService,
    BrowserModule,
    provideAnimations(),
    KeycloakAngularModule,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [ConfigService, KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js'
    },
    importProvidersFrom(SocketIoModule.forRoot({ url: `${environment.protocol.endsWith('s') ? 'wss' : 'http'}://${environment.domain}${environment.domain === 'localhost' ? ':' + environment.port : ''}`, options: {} }))
  ]
};

bootstrapApplication(AppComponent, appConfig).catch(error => console.error(error));
