import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogService } from 'src/app/dialogservice.service';
import { AgentStatus } from 'src/app/utils/models/agent.model';
import { AgentsService } from 'src/app/utils/services/agents.service';
import { SharedService } from 'src/app/utils/services/shared.service';

@Component({
  selector: 'app-agent-withdraw-application',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './agent-withdraw-application.component.html',
  styleUrl: './agent-withdraw-application.component.scss',
})
export class AgentWithdrawApplicationComponent {
  isFeedback = false;
  feedback: string = '';
  isArchived: boolean = false;
  isFeedbackSubmitted: boolean = false;
  agentInfo:any;
  @Output() onWithdrawApplication: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogService: DialogService,
    public agentService: AgentsService,
    public sharedService: SharedService
  ) {
    this.sharedService.agent$.subscribe((agent)=>{
      this.agentInfo = agent;
    });
  }

  sendingFeedback(): void {
    this.isFeedbackSubmitted = true;
    const payload = {
      feedback: this.feedback,
      projectId: this.agentInfo.project.id,
      agentPublicId: this.agentInfo.public_id,
    };
    if(this.feedback != ''){
      this.agentService.sendFeedback(payload).subscribe(() => {
        this.dialogService.showWithdrawApplicationDialog = false;
        this.onWithdrawApplication.emit();
        const text = `Der Agent zieht den Antrag mit Begründung zurück ${this.feedback}`;
        this.sharedService.logAgentMoment(this.agentInfo,this.agentInfo.emailAddress,'Agent',text);
      });
    }
  }

  withdrawApplication(): void {
    // const agentStatus = {
    //   AgentStatus: AgentStatus.ApplicantArchived,
    // };
    // this.agentService.updateAgentStatus(Number(this.agentInfo.id), agentStatus).subscribe(() => {
    //   this.isArchived = true;
    // });
    const obj = {
      reason: 'Agent canceled or cannot keep an appointment',
      AgentStatus: AgentStatus.ApplicantArchived,
      isApplicationWithdraw: true
    };
    this.agentService.archiveAgent(Number(this.agentInfo.id), obj).subscribe(() => {
      this.isArchived = true;
      this.isFeedback = true;
    });
  }
}
