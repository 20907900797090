import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgentsService } from '../utils/services/agents.service';
import { Agent } from '../utils/models/agent.model';
import { DialogService } from '../dialogservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SharedService } from '../utils/services/shared.service';
import { MessageService } from '../utils/services/message.service';
import { Message } from '../utils/models/message.model';
import { catchError } from 'rxjs/operators';
import { UserService } from '../utils/services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { Socket } from 'ngx-socket-io';
import { BookableTimeSlotsService } from '../utils/services/bookable-time-slots.service';
import moment from 'moment';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DateAdapter } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { WhatsAppMessagesService } from '../utils/services/whats-app-messages.service';
import { WhatsAppMessage } from '../utils/models/whats-app-message.model';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { CheckBoxModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MoveAgentComponent } from '../move-agent/move-agent.component';
import { AgentWithdrawApplicationComponent } from '../dialogs/agent-withdraw-application/agent-withdraw-application.component';
import { RecordComponent } from '../record/record.component';
import { VoicePlayerComponent } from '../voice-player/voice-player.component';

@Component({
  selector: 'app-agentview',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    CheckBoxModule,
    SwitchModule,
    DropDownListModule,
    EditorModule,
    MoveAgentComponent,
    AgentWithdrawApplicationComponent,
    RecordComponent,
    VoicePlayerComponent,
    SpinnerComponent
  ],
  templateUrl: './agentview.component.html',
  styleUrl: './agentview.component.scss'
})
export class AgentviewComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private agentsService: AgentsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialogService: DialogService,
    public snackBar: MatSnackBar,
    private router: Router,
    public sharedService: SharedService,
    private messageService: MessageService,
    private userService: UserService,
    protected readonly keycloak: KeycloakService,
    private socket: Socket,
    private btsService: BookableTimeSlotsService,
    private dateAdapter: DateAdapter<any>,
    private sanitizer: DomSanitizer,
    private readonly whatsAppMessagesService: WhatsAppMessagesService
  ) {
    this.messageSubscription = this.socket.fromEvent('newMessage').subscribe((message: any) => {
      if (
        this.currentRole === message.receiverRole ||
        this.currentRole === message.sendersRole
      ) {
        this.messages.push(message);
        setTimeout(() => {
          this.scrollToBottom();
        }, 50);
        setTimeout(() => {
          this.messageService.markMessagesAsRead(message).subscribe();
        }, 50);
      }
    });

    this.whatsAppMessageSubscription = this.whatsAppMessagesService.onNewWhatsAppMessage().subscribe((whatsAppMessage: WhatsAppMessage) => {
      if (this.currentRole === 'Admin') {
        this.whatsAppMessages.push(whatsAppMessage);
        setTimeout(() => {
          this.scrollToBottom();
        }, 50);
        setTimeout(() => {
          this.whatsAppMessagesService.markWhatsAppMessagesAsRead(whatsAppMessage).subscribe();
        }, 50);
      }
    });

    this.detectDevice();

    this.dateAdapter.setLocale('de-DE');
  }

  private messageSubscription: Subscription;
  private whatsAppMessageSubscription: Subscription;

  public id: number = -1;
  public publicId: string | null = '';
  public agent: Agent | any = null;
  public isAccessedPublicly: boolean = false;
  public currentRole: string = 'Client';
  public isAdminLoggedIn: boolean = false;

  public internalNotePartner: string = '';
  public internalNoteClient: string = '';
  public internalNoteAdmin: string = '';
  public selectedAgentVoiceFiles: [] = [];
  public currentPath = '';
  public isAgentUser: boolean = false;
  public agentId: string = '';
  public projectId: number = -1;
  public messageToSent: string = '';
  public messages: Message[] = [];
  public whatsAppMessages: WhatsAppMessage[] = [];
  public currentUser: string = '';
  public currentRoles: any;
  public isSingleProjectView = true;
  isEditable: boolean = false;
  chatChannel: string = '';
  whatsAppChatChannel: string = '';
  defaultProfileImageSrc =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvUjKpRjWsF2voE76xA88VIoqx_WemiQxhsQ&usqp=CAU';
  profileImageSrc = this.defaultProfileImageSrc;
  profilePhoto: any;
  isSpinnerActive: boolean = false;
  componentInitialized: boolean = false;

  @ViewChild('chatBot') chatBot!: ElementRef;

  @Input()
  public data: any = {};

  @Input()
  public projectViewsCurrentRole: string = '';

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  kanbanStatus: any = [];
  agentStatus = '';
  @Output() onstatusChange: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAgent: EventEmitter<any> = new EventEmitter();
  public agentMoveDate = {};
  public timeSlotList: any = [];
  agentAppointment: any = {};
  initialAppointment: any;
  agentProjectHistory: any;

  @Input()
  public statuses: any[] = [];

  countryCode=[
    "+49",
    "+90",
    "+1",
    "+1-242",
    "+1-246",
    "+1-264",
    "+1-268",
    "+1-284",
    "+1-340",
    "+1-345",
    "+1-441",
    "+1-473",
    "+1-649",
    "+1-664",
    "+1-670",
    "+1-671",
    "+1-684",
    "+1-721",
    "+1-758",
    "+1-767",
    "+1-784",
    "+1-787",
    "+1-809",
    "+1-829",
    "+1-849",
    "+1-868",
    "+1-869",
    "+1-876",
    "+1-939",
    "+20",
    "+211",
    "+212",
    "+213",
    "+216",
    "+218",
    "+220",
    "+221",
    "+222",
    "+223",
    "+224",
    "+225",
    "+226",
    "+227",
    "+228",
    "+229",
    "+230",
    "+231",
    "+232",
    "+233",
    "+234",
    "+235",
    "+236",
    "+237",
    "+238",
    "+239",
    "+240",
    "+241",
    "+242",
    "+243",
    "+244",
    "+245",
    "+246",
    "+248",
    "+249",
    "+250",
    "+251",
    "+252",
    "+253",
    "+254",
    "+255",
    "+256",
    "+257",
    "+258",
    "+260",
    "+261",
    "+262",
    "+263",
    "+264",
    "+265",
    "+266",
    "+267",
    "+268",
    "+269",
    "+27",
    "+290",
    "+291",
    "+297",
    "+298",
    "+299",
    "+30",
    "+31",
    "+32",
    "+33",
    "+34",
    "+350",
    "+351",
    "+352",
    "+353",
    "+354",
    "+355",
    "+356",
    "+357",
    "+358",
    "+359",
    "+36",
    "+370",
    "+371",
    "+372",
    "+373",
    "+374",
    "+375",
    "+376",
    "+377",
    "+378",
    "+379",
    "+380",
    "+381",
    "+382",
    "+383",
    "+385",
    "+386",
    "+387",
    "+389",
    "+39",
    "+40",
    "+41",
    "+420",
    "+421",
    "+423",
    "+43",
    "+44",
    "+44-1481",
    "+44-1534",
    "+44-1624",
    "+45",
    "+46",
    "+47",
    "+48",
    "+500",
    "+501",
    "+502",
    "+503",
    "+504",
    "+505",
    "+506",
    "+507",
    "+508",
    "+509",
    "+51",
    "+52",
    "+53",
    "+54",
    "+55",
    "+56",
    "+57",
    "+58",
    "+590",
    "+591",
    "+592",
    "+593",
    "+595",
    "+597",
    "+598",
    "+599",
    "+60",
    "+61",
    "+62",
    "+63",
    "+64",
    "+65",
    "+66",
    "+670",
    "+672",
    "+673",
    "+674",
    "+675",
    "+676",
    "+677",
    "+678",
    "+679",
    "+680",
    "+681",
    "+682",
    "+683",
    "+685",
    "+686",
    "+687",
    "+688",
    "+689",
    "+690",
    "+691",
    "+692",
    "+7",
    "+81",
    "+82",
    "+84",
    "+850",
    "+852",
    "+853",
    "+855",
    "+856",
    "+86",
    "+880",
    "+886",
    "+91",
    "+92",
    "+93",
    "+94",
    "+95",
    "+960",
    "+961",
    "+962",
    "+963",
    "+964",
    "+965",
    "+966",
    "+967",
    "+968",
    "+970",
    "+971",
    "+972",
    "+973",
    "+974",
    "+975",
    "+976",
    "+977",
    "+98",
    "+992",
    "+993",
    "+994",
    "+995",
    "+996",
    "+998"
  ];
  public fields: { text: string; value: string } = { text: '', value: '' };
  public field: { text: string; value: string } = { text: 'text', value: 'value' };

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  deviceType = '';
  userAgent = '';
  videoStream: MediaStream | null = null;
  hasWebcam:boolean = false;
  isWebcamOn:boolean = false;
  isFromOpenTasks:boolean = false;
  salutation = ['Herr','Frau','Divers'];
  question = [{ text: 'Ja', value: 'true' }, { text: 'Nein', value: 'false' }];
  languageLevel = [{ text: 'Schlecht', value: 'Bad' }, { text: 'Mittel', value: 'Medium' }, { text: 'Gut', value: 'Good' }];
  outboundExperienceLevel = [{ text: 'Keine', value: 'None' }, { text: 'Mittel', value: 'Medium' }, { text: 'Gut', value: 'Good' }];
  noticePeriod = [{value:'no notice period', text:'keine Kündigungsfrist'},{value:'2 weeks', text:'2 Wochen'},{value:'4 weeks', text:'4 Wochen'},{value:'6 weeks', text:'6 Wochen'}]
  dropdownFields: Object = { text: 'display', value: 'name' };
  appointmentDropdownFields: Object = { text: 'display', value: 'display' };
  @Output() onselectInterviewDate: EventEmitter<any> = new EventEmitter();

  @Input()
  public partners: string[] = [];

  @Input()
  public partnerId: string = 'false';

  @Input()
  public partnerName: string = '';

  getTextForValueForOutboundExperienceLevel(value: string) {
    if (!value) {
      return '';
    }
    return this.outboundExperienceLevel.find((item) => item.value === value)?.text ?? '';
  }

  ngOnInit() {
    this.isSpinnerActive = true;
    if (this.router.url == '/open-tasks') {
      this.isFromOpenTasks = true;
    }
    if (this.data) {
      this.agentId = this.data.agentId;
      this.projectId = this.data.projectId;

      if (this.data.agentId) {
        this.id = this.data.agentId;
        this.agentsService
          .getAgentById(this.data.agentId)
          .subscribe((agent: any) => {
            this.agent = agent;
            this.agentProjectHistory = JSON.parse(this.agent.projectHistory);

            const splitPhoneNumber: string[] = this.agent.phoneNumber.split(' ');
            if (splitPhoneNumber.length > 1) {
              this.agent.countryCode = splitPhoneNumber.shift();
              this.agent.phoneNumber = splitPhoneNumber.join(' ');
            } else {
              this.agent.phoneNumber = this.agent.phoneNumber;
            }

            this.agentStatus = this.agent.status;
            if (this.agent.agentProfilePhoto) {
              this.profileImageSrc = this.agentsService.convertImagetoUrl(
                this.agent.agentProfilePhoto.photoBlob
              );
            } else {
              this.profileImageSrc = this.defaultProfileImageSrc;
            }
            if (this.agent?.partnerId) {
              this.getPartnerInfo(this.agent.partnerId);
            }
            if(this.agent?.project.client){
              this.getClientInfo(this.agent.project.client);
            }
            this.sharedService.agentSubject.next(agent);
            this.publicId = this.agent?.public_id || '';
            this.getMessages();

            this.chatChannel = `${this.projectId}-${this.publicId}`;
            this.messageService.joinChannel(this.chatChannel);
            this.whatsAppChatChannel = `${this.projectId}-${this.agent.id}`;
            this.whatsAppMessagesService.joinChannel(this.whatsAppChatChannel);

            this.getProjectTimeSlot(this.projectId);
            this.getstatusArray();

            this.isAccessedPublicly = false;
            // if (this.currentRole === 'Admin') {
            //   let internalNotes = JSON.parse(agent.internalNote ?? '[]');

            //   this.internalNoteAdmin = internalNotes.filter(
            //     (note: { role: string; note: string }) => note.role === 'Admin'
            //   )[0]?.note;
            //   this.internalNotePartner = internalNotes.filter(
            //     (note: { role: string; note: string }) =>
            //       note.role === 'Partner'
            //   )[0]?.note;
            //   this.internalNoteClient = internalNotes.filter(
            //     (note: { role: string; note: string }) => note.role === 'Client'
            //   )[0]?.note;
            // } else {
            //   let internalNotes = JSON.parse(agent.internalNote ?? '[]');
            //   agent.internalNote = internalNotes?.filter(
            //     (note: { role: string; note: string }) =>
            //       note.role === this.currentRole
            //   )[0]?.note;
            // }
            this.isSpinnerActive = false;
          });
      }
    }
    this.currentPath = this.router.url;

    if (this.currentPath.includes('public')) {
      this.isAgentUser = true;
    }

    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];


    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
      this.isAdminLoggedIn = true;
      this.changeDetectorRef.detectChanges();
    }

    this.route.params.subscribe((params) => {
      const id = params['id'];
      const publicId = params['publicId'];

      if (publicId) {
        this.isSingleProjectView = false;
        this.publicId = publicId;
        this.agentsService
          .getAgentByPublicId(publicId)
          .subscribe({
           next:(agent: any) => {
            this.agent = agent;

            const splitPhoneNumber = this.agent.phoneNumber.split(' ');
            if (splitPhoneNumber.length > 1) {
              this.agent.countryCode = splitPhoneNumber.shift();
              this.agent.phoneNumber = splitPhoneNumber.join(' ');
            } else {
              this.agent.phoneNumber = this.agent.phoneNumber;
            }

            this.agentStatus = this.agent.status;
            if (this.agent.agentProfilePhoto) {
              this.profileImageSrc = this.agentsService.convertImagetoUrl(
                this.agent.agentProfilePhoto.photoBlob
              );
            } else {
              this.profileImageSrc = this.defaultProfileImageSrc;
            }
            if (this.agent?.partnerId && !this.agent.isApplicationWithdraw) {
              this.getPartnerInfo(this.agent.partnerId);
            }
            if(this.agent?.project.client && !this.agent.isApplicationWithdraw){
              this.getClientInfo(this.agent.project.client);
            }
            if(this.agent?.project){
              this.getProjectTimeSlot(this.agent.project.id);
            }
            this.sharedService.agentSubject.next(agent);
            this.publicId = this.agent?.public_id || '';
            this.getMessages();
            this.isAccessedPublicly = true;
            this.isSpinnerActive = false;
          },
          error: (err) => {
            this.snackBar.open(err.error.message, '', {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
          }
        });
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 150);
    });
  }

  ngAfterViewInit() {
    if (!this.isAgentUser) {
      this.scrollToBottom();
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.componentInitialized = true;
    },5000);
  }

  getMessages() {
    this.messageService
      .getMessagesByAgentandProjectId(
        this.publicId,
        this.projectId,
        this.currentRole,
        this.data.coloumnName
      )
      .subscribe((messages: Message[]) => {
        this.messages = messages;
        this.messages.forEach((message) => {
          this.messageService.markMessagesAsRead(message).subscribe();
        });
      });

    this.whatsAppMessagesService
      .findAllWhatsAppMessagesByProjectAndAgent(
        this.projectId,
        this.agent.id
      )
      .subscribe((whatsAppMessages: WhatsAppMessage[]) => {
        this.whatsAppMessages = whatsAppMessages;
        this.whatsAppMessages.forEach((message) => {
          this.whatsAppMessagesService.markWhatsAppMessagesAsRead(message).subscribe();
        });
      });
  }

  openChat: 'chat' | 'whatsapp' = 'chat';

  sendMessage(isLogged:boolean) {
    if (this.openChat === 'chat') {
      let message: Message = {
        senderId: '',
        sendersRole: '',
        receiverId: '',
        receiverRole: '',
        content: '',
        projectId: 0,
        agentPublicId: '',
        timestamp: '',
        isLogged:isLogged
      };
  
      if (this.currentRole !== 'Admin' || isLogged) {
        message.senderId = this.currentUser;
        message.sendersRole = this.currentRole;
        message.receiverId = 'admin.user@photonic-codes.com';
        message.receiverRole = 'Admin';
      } else {
        if (this.data.coloumnName === 'AgentCreatedFilesMissing' || this.data.coloumnName === 'ApplicantGetsChecked' || this.data.coloumnName === 'WaitingForAgent') {
          message.senderId = this.currentUser;
          message.sendersRole = this.currentRole;
          message.receiverId =  '';
          message.receiverRole = 'Partner';
        } else {
          message.senderId = this.currentUser;
          message.sendersRole = this.currentRole;
          message.receiverId = '';
          message.receiverRole = 'Client';
        }
      }
      message.content = this.messageToSent;
      message.projectId = this.projectId;
      message.agentPublicId = this.publicId || '';
      message.timestamp = new Date().toISOString();
  
      this.messageService
        .sendMessage(message)
        .pipe(
          catchError((error) => {
            console.error('Error sending message:', error);
            return error;
          })
        )
        .subscribe((res) => {
          setTimeout(() => {
            this.scrollToBottom();
          }, 50);
          this.messageToSent = '';
        });
    } else if (this.openChat === 'whatsapp') {
      const whatsAppMessage: WhatsAppMessage = {
        id: '',
        projectId: this.projectId,
        agentId: this.agent.id,
        phoneNumber: '',
        senderName: 'Admin',
        content: this.messageToSent,
        receivedAt: new Date().toISOString(),
        readAt: ''
      };

      this.whatsAppMessagesService
        .create(whatsAppMessage)
        .subscribe((res) => {
          setTimeout(() => {
            this.scrollToBottom();
          }, 50);
          this.messageToSent = '';
        });
    } else {
      console.error('Invalid chat type', this.openChat);
    }
  }

  formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const currentDate = new Date();

    const isCurrentWeek =
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() >= currentDate.getDate() - currentDate.getDay() &&
      date.getDate() < currentDate.getDate() + (7 - currentDate.getDay());

    if (isCurrentWeek) {
      const days = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ];
      const dayName = days[date.getDay()];
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      return `${dayName} ${hours}:${minutes}`;
    } else {
      // const formattedDate = date.toISOString().split('T')[0];
      // const hours = ('0' + date.getHours()).slice(-2);
      // const minutes = ('0' + date.getMinutes()).slice(-2);
      const formattedDate = moment(date).format('DD.MM.YYYY HH:mm');
      return `${formattedDate}`;
    }
  }
  getFormattedTimestamp(): string {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date();
    const dayOfWeek = days[date.getDay()];
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-indexed, so we add 1
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z${dayOfWeek}`;
  }

  scrollToBottom(): void {
    try {
      if(!this.isAgentUser){
        this.chatBot.nativeElement.scrollTop =
          this.chatBot.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  onInputFocus(event: any) {
    event.target.classList.add('input-focus');
  }

  onInputBlur(event: any) {
    event.target.classList.remove('input-focus');
  }

  onInputBlurForPhoneNumber(event: any) {
    event.target.classList.remove('input-focus');

    if (!this.agent.phoneNumber.startsWith('0')) {
      this.agent.phoneNumber = `0 ${this.agent.phoneNumber}`;
    }
  }

  ngOnDestroy(): void {
    this.messageService.leaveChannel(this.chatChannel);
    this.messageSubscription.unsubscribe();

    this.whatsAppMessagesService.leaveChannel(this.whatsAppChatChannel);
    this.whatsAppMessageSubscription.unsubscribe();
  }

  updateAgent(): void {
    this.isSpinnerActive = true;
    const agentData = { ...this.agent };
    delete agentData.videoFiles;
    delete agentData.voiceFiles;
    delete agentData.project;
    delete agentData.agentProfilePhoto;
    delete agentData.interviewAppointment;
    agentData.phoneNumber = `${agentData.countryCode} ${agentData.phoneNumber}`;
    delete agentData.countryCode;
    if (agentData.birthday instanceof Date) {
      const year = agentData.birthday.getFullYear();
      const month = String(agentData.birthday.getMonth() + 1).padStart(2, '0');
      const day = String(agentData.birthday.getDate()).padStart(2, '0');

      agentData.birthday = `${year}-${month}-${day}`;
    }
    if (agentData.availableFrom instanceof Date) {
      agentData.availableFrom = agentData.availableFrom.toISOString().split('T')[0];
    }
    this.agentsService
      .partiallyUpdateAgentById(this.agent?.id, agentData)
      .subscribe({
        next: (res) => {
          this.isSpinnerActive = false;
          this.dialogService.showAgentView = false;
          const status = this.statuses.find(
            (status) => status.name === this.agent.status
          );
          if (status) {
            status.agents.forEach((agent: any) => {
              if (agent.id == this.agent.id) {
                agent.name = `${this.agent.firstname} ${this.agent.lastname}`;
                agent.city = this.agent.city;
                agent.country = this.agent.country;
                agent.interviewAppointment = this.agent.interviewAppointment;
                // agent.profileImage= agent.profileImage;
              }
            });
          }
          this.snackBar.open('Agent Daten erfolgreich gespeichert' , '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        },
        error: (error) => {
          this.dialogService.showAgentView = false;
          this.isSpinnerActive = false;
        }
      });
  }

  showWithdraw(): void {
    this.dialogService.showWithdrawApplicationDialog = true;
  }

  reactivatAgent() {
    // this.agentsService
    //   .updateAgentStatus(this.agent?.id, { AgentStatus: this.agent.status })
    //   .subscribe({
    //     next: (res) => {
    //       this.dialogService.showAgentView = false;
    //       this.onClose.emit(true);
    //     },
    //     error: (error) => {
    //       this.dialogService.showAgentView = false;
    //       this.onClose.emit(false);
    //     },
    //   });
    this.dialogService.showMoveAgentDialog = true;
    this.agentMoveDate = { ...this.agent, for: 'otherProject' };
  }

  getPartnerInfo(partnerId: number) {
    this.agentsService.getPartnerInfo(partnerId).subscribe((res) => {
      this.agent.project.partnerInfo = res;
    });
  }

  getClientInfo(clientId: number) {
    this.agentsService.getClientInfo(clientId).subscribe((res) => {
      this.agent.project.clientInfo = res;
    });
  }

  getstatusArray() {
    let isAllow = [];
    this.kanbanStatus = [];
    switch (this.agent.status) {
      case 'AgentCreatedFilesMissing':
        if (this.currentRole === 'Admin') {
          isAllow.push('ApplicantGetsChecked');
        }
        break;
      case 'ApplicantGetsChecked':
        if (this.currentRole === 'Admin') {
          isAllow.push('ApplicantRejected', 'WaitingForAgent', 'AgentCreatedFilesMissing');
        }
        // else if(this.currentRole === 'Partner'){
        //   isAllow.push('AgentCreatedFilesMissing');
        // }
        break;
      case 'WaitingForAgent':
        if (this.currentRole === 'Admin') {
          if (this.agent.voiceFiles.some((obj: any) => obj.status === 'accepted') ||
              this.agent.videoFiles.some((obj: any) => obj.status === 'accepted')) {
            isAllow.push('ApplicantGetsSuggested');
          }
          isAllow.push('ApplicantRejected', 'ApplicantGetsChecked');
        }
        break;
      case 'ApplicantGetsSuggested':
        if (this.currentRole === 'Client') {
          isAllow.push('ApplicantRejected', 'AppointmentScheduled');
        } else if (this.currentRole === 'Admin') {
          isAllow.push('WaitingForAgent','ApplicantRejected','AppointmentScheduled');
        }
        break;
      case 'ApplicantRejected':
        if (
          this.agent.previousStatus === 'AgentCreatedFilesMissing' ||
          this.agent.previousStatus === 'ApplicantGetsChecked' ||
          this.agent.previousStatus === 'WaitingForAgent'
        ) {
          if (this.currentRole === 'Admin') {
            isAllow.push(this.agent.previousStatus);
          }
        } else {
          isAllow.push(this.agent.previousStatus);
        }
        break;
      case 'AppointmentScheduled':
        if (this.currentRole !== 'Partner' && this.agent.interviewAppointment) {
          isAllow.push('ApplicantAccepted');
        }
        if (this.currentRole !== 'Partner') {
          isAllow.push('ApplicantRejected', 'ApplicantGetsSuggested');
        }
        break;
      case 'ApplicantAccepted':
        if (this.currentRole === 'Client') {
          isAllow.push('ApplicantRejected', 'AppointmentScheduled');
        } else if (this.currentRole === 'Admin') {
          isAllow.push('ApplicantRejected', 'ApplicantConfirmed', 'AppointmentScheduled');
        }
        break;
      case 'ApplicantConfirmed':
        if (this.currentRole === 'Admin') {
          isAllow.push('ApplicantAccepted','ApplicantRejected');
        }
        break;
      default:
        break;
    }
    isAllow.forEach((value)=>{
      this.kanbanStatus.push(this.sharedService.statuses[value]);
    });
    this.kanbanStatus.push(this.sharedService.statuses[this.agent.status]);
    if(this.currentRole === 'Client'){
      this.kanbanStatus = this.kanbanStatus.map((statu:any) => {
        return {
          ...statu,
          display:  statu.customerDisplayName || statu.display
        };
      });
    }
  }

  onChange() {
    this.isSpinnerActive = true;
    this.agentsService
      .updateAgentStatus(this.agent.id, { AgentStatus: this.agentStatus })
      .subscribe((res: any) => {
        this.isSpinnerActive = false;
        // ToDo:event emit
        this.agent.previousStatus = this.agent.status;
        this.agent.status = this.agentStatus;
        this.onstatusChange.emit(res);
        this.getstatusArray();

        if (this.componentInitialized) {
          this.snackBar.open('Der Status des Agenten wurde erfolgreich aktualisiert', '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      });
  }

  openMoveAgent(to: string) {
    this.dialogService.showMoveAgentDialog = true;
    this.agentMoveDate = { ...this.agent, for: to };
  }

  onAgentMove() {
    this.onMoveAgent.emit();
    this.onClose.emit(true);
    this.dialogService.showAgentView = false;
  }

  getProjectTimeSlot(projectId: number) {
    this.btsService
      .getAllBookableTimeSlotsByProjectId(projectId)
      .subscribe((res: any) => {
        this.timeSlotList = this.formateTimeSlot(res);
        if (this.agent.interviewAppointment) {
          const date = new Date(this.agent.interviewAppointment);
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString();

          const dayOfWeekIndex = date.getDay();
          const days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ];
          const dayOfWeek = days[dayOfWeekIndex];
          const timeSlot = res.find((timeSlot: any) => {
              if (
                moment(this.agent.interviewAppointment).isSame(moment(timeSlot.toDate), 'day') ||
                moment(this.agent.interviewAppointment).isSame(moment(timeSlot.fromDate), 'day')
            ) {
                return true;
            } else if (
                moment(timeSlot.toDate).isAfter(moment(this.agent.interviewAppointment)) &&
                moment(this.agent.interviewAppointment).isAfter(moment(timeSlot.fromDate))
            ) {
                return true;
            } else {
                return false;
            }
          });
          if (!!timeSlot) {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const formattedHours = ('0' + hours).slice(-2);
            const formattedMinutes = ('0' + minutes).slice(-2);
            const hhmm = `${formattedHours}:${formattedMinutes}`;
            const slot = timeSlot.timeWindows[dayOfWeek].find(
              (element: any) => element.from === hhmm
            );
            if (slot) {
              const slotObj = {
                date: `${date
                  .getDate()
                  .toString()
                  .padStart(2, '0')}.${month}.${year}`,
                day: dayOfWeek,
                slot,
                slotId: timeSlot.id,
                display: `${date.getDate().toString().padStart(2, '0')}.${month}.${year} ${slot.from} - ${slot.to}`
              };
              this.agentAppointment = slotObj.display;
              this.initialAppointment = slotObj.display;
              this.timeSlotList.push(slotObj);
            }
          }
        }
      });
  }

  formateTimeSlot(data: any) {
    const formatedData: any[] = [];

    const order: string[] = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    data.forEach((element: any) => {
      const date = new Date(element.fromDate);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();

      order.forEach((day, index) => {
        const dayOfWeek = order.indexOf(day);
        const dayDate = new Date(date);
        dayDate.setDate(dayDate.getDate() + dayOfWeek);
        dayDate.setHours(0, 0, 0, 0);

        if (dayDate > today) {
          element.timeWindows[day].forEach((slot: any) => {
            if (slot.status === 'vacant') {
              formatedData.push({
                date: `${dayDate
                  .getDate()
                  .toString()
                  .padStart(2, '0')}.${month}.${year}`,
                day: day,
                slot,
                slotId: element.id,
                display: `${dayDate.getDate().toString().padStart(2, '0')}.${month}.${year} ${slot.from} - ${slot.to}`
              });
            }
          });
        }
      });
    });
    return formatedData;
  }

  onAppointmentChange() {
    if (this.initialAppointment === this.agentAppointment) return;
    const selectedSlot = this.timeSlotList.find((slot: any) => slot.display === this.agentAppointment);
    if (selectedSlot) {
      this.isSpinnerActive = true;
      const [day, month, year] = selectedSlot?.date.split('.');
      const [hour, minute] = selectedSlot.slot.from.split(':');
      const formattedDate = moment(
        `${year}-${month}-${day} ${hour}:${minute}`
      ).format();
      const interviewAppointmentFormatted = `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}T${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}`;

      this.agent.interviewAppointment = new Date(formattedDate);
      this.agentsService
        .scheduleAgentAppointment(this.agent.id, {
          ...selectedSlot,
          formattedDate: interviewAppointmentFormatted,
        })
        .subscribe((res) => {
          console.log('Appointmenr change res: ', res);
          // this.isSpinnerActive = false;
          this.agent.interviewAppointment = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`).toISOString();
          this.onselectInterviewDate.emit(this.agent);
          this.sharedService.emitUpdateScheduledCount(true);
          console.log('Agent after change Appointment-=-=-=>', this.agent);
          if (this.componentInitialized) {
            this.snackBar.open('Interviewtermin zugewiesen', '', {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
          }
          this.isSpinnerActive = false;
        });
    }
  }

  onRemoveAppointment() {
    const selectedSlot = this.timeSlotList.find((slot:any) => slot.display === this.agentAppointment);
    const [day, month, year] = selectedSlot.date.split('.');
    const [hour, minute] = selectedSlot.slot.from.split(':');
    const formattedDate =  moment(
      `${year}-${month}-${day} ${hour}:${minute}`
    ).format();

    this.agent.interviewAppointment = null;
    this.agentAppointment = null;
    this.changeDetectorRef.detectChanges();
    this.agentsService
      .removeAgentAppointment(this.agent.id, {
        ...selectedSlot,
        formattedDate: moment(
          `${year}-${month}-${day} ${hour}:${minute}`
        ).format(),
      })
      .subscribe((res) => {
        console.log('update emitted');
        this.sharedService.emitUpdateScheduledCount(true);
      });
  }


  navigateToProject(projectId: number): void {
    this.dialogService.showAgentView = false;
    this.router.navigate([`/singleProjectView`, projectId]);
    this.changeDetectorRef.detectChanges();
  }

  changeAgentStatus(status: string) {
    this.agentsService
      .updateAgentStatus(this.agent.id, { AgentStatus: status })
      .subscribe((res: any) => {
        // ToDo:event emit
        this.dialogService.showAgentView = false;
        this.onstatusChange.emit(res);
      });
  }

  chooseProfilePhoto(event: any): void {
    this.profilePhoto = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = () => {
    //   this.profileImageSrc = reader.result as string;
    // };
    // reader.readAsDataURL(event.target.files[0]);
    this.uploadProfilePhoto(this.profilePhoto,this.profilePhoto.type,this.agent.id);
  }

  uploadProfilePhoto(profilePhoto: any, type: string, agentId: number) {
    this.isSpinnerActive = true;
    let blob: any;
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(profilePhoto);
    reader.onload = () => {
      blob = new Blob([reader.result], { type: profilePhoto.type });
      const formData: any = new FormData();
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const fileName = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      if (blob instanceof Blob) {
        formData.append('file', blob, fileName);
        formData.append('type', type);
      }
      this.agentsService.setAgentProfilePhoto(agentId, formData).subscribe({
        next: (res: any) => {
          this.isSpinnerActive = false;
          this.profileImageSrc = this.agentsService.convertImagetoUrl(
            res.photoBlob
          );
          this.profilePhoto = null;
        },
        error: (error) => {
          this.isSpinnerActive = false;
          this.profilePhoto = null;
        },
      });
    };
  }

  public onFiltering: EmitType<FilteringEventArgs>  =  (e: FilteringEventArgs) => {
    let query = new Query();

    if (e.text && e.text.length > 0) {
      // Use "contains" to allow searching anywhere within the country code
      query = query.where('', 'contains', e.text, true); // Case-insensitive
    }

    // Update the data source with the filtered results
    e.updateData(this.countryCode, query);
  };

  detectDevice(){
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    this.userAgent = navigator.userAgent;
    if(regex.test(navigator.userAgent)){
      const regexx = /iPhone|iPad|iPod/i;
      if(regexx.test(navigator.userAgent)){
        this.deviceType = 'iphone';
      }else{
        this.deviceType = 'android';
      }
    } else {
      this.deviceType = 'desktop';
      this.detectWebcam();
    }
  }

  async startCamera() {
    this.isWebcamOn = true;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.videoStream = stream;
      this.videoElement.nativeElement.srcObject = stream;
    } catch (error) {
      this.isWebcamOn = false;
      console.error('Error accessing camera:', error);
    }
  }

  takeSnapshot() {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');

    if (context) {
      this.isSpinnerActive = true;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          if (this.videoStream) {
            this.videoStream.getTracks().forEach(track => track.stop());
          }
          this.isWebcamOn = false;
          const formData: any = new FormData();
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          const fileName = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

          if (blob instanceof Blob) {
            formData.append('file', blob, fileName);
            formData.append('type', blob.type);
          }
          this.agentsService.setAgentProfilePhoto(this.agent.id, formData).subscribe({
            next: (res: any) => {
              this.profileImageSrc = this.agentsService.convertImagetoUrl(
                res.photoBlob
              );
              this.profilePhoto = null;
              this.isSpinnerActive = false;
            },
            error: (error) => {
              this.isSpinnerActive = false;
              this.profilePhoto = null;
            },
          });
        }
      }, 'image/png');
    }
  }

  detectWebcam(){
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) {
      this.hasWebcam = false;
      return
    };
    md.enumerateDevices().then(devices => {
      this.hasWebcam = devices.some(device => 'videoinput' === device.kind);
    });
  }

  closeWebcam(){
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
    }
    this.isWebcamOn = false;
  }

  AddTimeStamp(msg:any){
   return `${moment(msg.timestamp).format(
      'DD.MM.YYYY HH:mm'
    )} ${msg.content}`
  }

  onApplicationWithdraw(){
    this.agent.isApplicationWithdraw = true;
  }

  sanitizerHtml(htmlString:string){
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  adjustImage(image: HTMLImageElement): void {
    if (image.naturalWidth === image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = '100%';
    } else if (image.naturalWidth < image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = 'auto';
    } else {
      image.style.width = 'auto';
      image.style.height = '100%';
    }
  }

  public disabledSwitch: Map<number, boolean> = new Map<number, boolean>();

  public onSwitchToggle(event: any, message: Message): void {
    this.disabledSwitch.set(message.id!, true);

    this.messageService.updateInternFlag(message).subscribe({
      next: (value) => {
        const index = this.messages.findIndex((msg) => msg.id === message.id);
        this.messages[index] = value;
        
        this.disabledSwitch.set(message.id!, false);
      },
      error: (err) => {
        console.error('Error:', err);
      }
    });
  }
}
