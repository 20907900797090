import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from 'src/app/config.service';
import { WhatsAppMessage } from '../models/whats-app-message.model';
import { Project } from '../models/project.model';
import { Agent } from '../models/agent.model';
import { CreateWhatsAppMessageDTO } from '../models/create-whats-app-message.dto';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class WhatsAppMessagesService {
  private apiUrl = '';

  public constructor(
    private readonly configService: ConfigService,
    private readonly http: HttpClient,
    private readonly socket: Socket
  ) {
    this.apiUrl = `${this.configService.baseUrl}/whats-app-messages`;
  }

  public findAllWhatsAppMessagesByProjectAndAgent(projectId: number, agentId: number): Observable<WhatsAppMessage[]> {
    return this.http.get<WhatsAppMessage[]>(`${this.apiUrl}/${projectId}/${agentId}`);
  }

  public create(whatsAppMessage: WhatsAppMessage): Observable<WhatsAppMessage> {
    const create: CreateWhatsAppMessageDTO = {
      projectId: whatsAppMessage.projectId,
      agentId: whatsAppMessage.agentId,
      phoneNumber: whatsAppMessage.phoneNumber,
      senderName: whatsAppMessage.senderName,
      content: whatsAppMessage.content,
      receivedAt: whatsAppMessage.receivedAt,
      readAt: whatsAppMessage.readAt
    };

    return this.http.post<WhatsAppMessage>(`${this.apiUrl}`, create);
  }

  public countUnreadWhatsAppMessages(project: Project, agent: Agent): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/${project.id}/${agent.id}/actions/count-unread-whats-app-messages`);
  }

  public markWhatsAppMessagesAsRead(whatsAppMessage: WhatsAppMessage): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/${whatsAppMessage.projectId}/${whatsAppMessage.agentId}/actions/mark-whats-app-messages-as-read`);
  }

  public joinChannel(channel: string): void {
    this.socket.emit('joinChannel', channel);
  }

  public leaveChannel(channel: string): void {
    this.socket.emit('leaveChannel', channel);
  }

  public onUnreadWhatsAppMessagesCount(): Observable<{ id: number, count: number }> {
    return this.socket.fromEvent<{ id: number, count: number }>('unreadWhatsAppMessagesCount');
  }

  public onNewWhatsAppMessage(): Observable<WhatsAppMessage> {
    return this.socket.fromEvent<WhatsAppMessage>('newWhatsAppMessage');
  }

  public onWhatsAppMessagesRead(): Observable<{ id: number }> {
    return this.socket.fromEvent<{ id: number }>('whatsAppMessagesRead');
  }
}
