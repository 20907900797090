<div class="app">
  <div class="heading">
    <div>
      <h2>Partnerverwaltung</h2>
    </div>
    <button type="button" class="add-user-btn" (click)="addPartnerAssignment()">Benutzer dem Partner hinzufügen</button>
  </div>

  <div class="user-details">
    <table class="table mb-0">
      <thead>
        <tr>
          <th>Benutzername</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let partnerAssignment of partnersService.partnerAssignments; let i = index;">
          <ng-container *ngIf="i === 0 || partnerAssignment.partner.partnerName !== partnersService.partnerAssignments[i - 1].partner.partnerName">
            <tr>
              <td [attr.colspan]="3" style="background-color: ghostwhite; font-weight: bold;">
                {{ partnerAssignment.partner.partnerName }}
              </td>
              <td [attr.colspan]="1" style="background-color: ghostwhite; font-weight: bold; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
                <ejs-switch #sw (change)="onSwitch($event, partnerAssignment.partner, sw)" [checked]="partnerAssignment.partner.isActivated" cssClass="e-small"></ejs-switch>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td>{{ partnerAssignment.user.username }}</td>
            <td>{{ partnerAssignment.user.firstName }}</td>
            <td>{{ partnerAssignment.user.lastName }}</td>
            <td style="text-align: end;">
              <button class="action-btn" (click)="onClickDelete(partnerAssignment)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="rgba(238,14,14,1)"><path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path></svg>
              </button>
              <button class="action-btn" (click)="onClickUser(partnerAssignment)">
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
                  id="screenshot-bb74426a-280e-8067-8003-ed21d11004ec" version="1.1" viewBox="22899 1213 20 20"
                  height="20">
                  <g id="shape-bb74426a-280e-8067-8003-ed21d11004ec" style="fill:#000000" width="1em" height="1em" rx="0"
                    ry="0">
                    <g id="shape-bb74426a-280e-8067-8003-ed21d1111ecb">
                      <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d1111ecb">
                        <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                          d="M22909.000,1218.000L22908.106,1218.871L22911.594,1222.375L22904.000,1222.375L22904.000,1223.625L22911.594,1223.625L22908.106,1227.108L22909.000,1228.000L22914.000,1223.000Z">
                        </path>
                      </g>
                    </g>
                    <g id="shape-bb74426a-280e-8067-8003-ed21d11151d4">
                      <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d11151d4">
                        <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                          d="M22909.000,1231.750C22904.168,1231.750,22900.250,1227.832,22900.250,1223.000C22900.250,1218.168,22904.168,1214.250,22909.000,1214.250C22913.832,1214.250,22917.750,1218.168,22917.750,1223.000C22917.744,1227.830,22913.830,1231.744,22909.000,1231.750ZM22909.000,1215.500C22904.858,1215.500,22901.500,1218.858,22901.500,1223.000C22901.500,1227.142,22904.858,1230.500,22909.000,1230.500C22913.142,1230.500,22916.500,1227.142,22916.500,1223.000C22916.495,1218.860,22913.140,1215.505,22909.000,1215.500Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="dialogService.showNewPartnerDialog" class="backdrop"></div>
<new-partner-assignment *ngIf="dialogService.showNewPartnerDialog" [selectedPartner]="selectedPartner" [isForUpdate]="partnersService.isForUpdate" (changeinPartner)="partnersService.change($event)"></new-partner-assignment>

<div *ngIf="deleteConfirmationPopup" class="backdrop"></div>
<div class="deleteConfirmation-popup-container"  *ngIf="deleteConfirmationPopup">
  <h2>
    Sind Sie sich sicher, dass Sie den Benutzer {{this.partnerForDelete?.user.username}} von dem Partner “{{this.partnerForDelete?.partner?.partnerName}}” trennen möchten?
  </h2>
  <div class="d-flex gap-4">
    <button (click)="deleteConfirmationPopup = !deleteConfirmationPopup" mat-stroked-button class="mt-3">Abbrechen</button>
    <button (click)="onClickJa()" mat-stroked-button class="mt-3">Ja</button>
  </div>
</div>

<div *ngIf="deactivateConfirmationPopup" class="backdrop"></div>
<div class="deleteConfirmation-popup-container"  *ngIf="deactivateConfirmationPopup">
  <h2>
    Sind Sie sich sicher, dass Sie den Partner “{{this.partnerForDeactivate?.partnerName}}” deaktivieren möchten?
  </h2>
  <div class="d-flex gap-4">
    <button (click)="onSwitchDialogNo()" mat-stroked-button class="mt-3">Abbrechen</button>
    <button (click)="onSwitchDialogYes()" mat-stroked-button class="mt-3">Ja</button>
  </div>
</div>

@if (isSpinnerActive) {
  <app-spinner></app-spinner>
}
