import { HttpClient } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigService } from 'src/app/config.service';
import { Message } from '../models/message.model';
import { MessageService } from './message.service';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private apiUrl = '';
  public statuses:any = {
    AgentCreatedFilesMissing: {
      name: 'AgentCreatedFilesMissing',
      display: 'Neuer Agent',
      isToDisplayOnKanban:true,
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantGetsChecked: {
      name: 'ApplicantGetsChecked',
      display: 'In Prüfung',
      isToDisplayOnKanban:true,
      roles: ['Partner'],
      agents: [],
      collapsed: false,
    },
    WaitingForAgent: {
      name: 'WaitingForAgent',
      display: 'Warte auf Agent / voicefile',
      isToDisplayOnKanban:true,
      roles: ['Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantGetsSuggested: {
      name: 'ApplicantGetsSuggested',
      display: 'Dem Kunden vorgeschlagen',
      customerDisplayName: "Neue Bewerber",
      isToDisplayOnKanban:true,
      roles: ['Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantRejected: {
      name: 'ApplicantRejected',
      display: 'Negative Auswahl',
      isToDisplayOnKanban:true,
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    AppointmentScheduled: {
      name: 'AppointmentScheduled',
      display: 'Termin gewünscht / vereinbart',
      isToDisplayOnKanban:true,
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantAccepted:{
      name: 'ApplicantAccepted',
      display: 'Positive Auswahl',
      isToDisplayOnKanban:true,
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantConfirmed:{
      name: 'ApplicantConfirmed',
      display: 'Zusage',
      isToDisplayOnKanban:true,
      roles: ['Client', 'Partner'],
      agents: [],
      collapsed: false,
    },
    ApplicantArchived:{
      name: 'ApplicantArchived',
      display: 'Archiviert',
      isToDisplayOnKanban:false,
    },
    ApplicantChecked:{
      name: 'ApplicantChecked',
      display: 'Geprüft',
      isToDisplayOnKanban:false,
    },
    AppointmentSuggested:{
      name: 'AppointmentSuggested',
      display: 'Vorgeschlagene',
      isToDisplayOnKanban:false,
    }
  };

  agentVoicesSubject = new BehaviorSubject([]);
  agentVoices$ = this.agentVoicesSubject.asObservable();

  agentVideoSubject = new BehaviorSubject([]);
  agentVideos$ = this.agentVoicesSubject.asObservable();

  agentSubject = new BehaviorSubject([]);
  agent$ = this.agentSubject.asObservable();

  scheduledStatusSubject = new BehaviorSubject({});

  agentVoiceFileSubject = new BehaviorSubject({});
  agentVoiceFile$ = this.agentVoiceFileSubject.asObservable();
  negativeAgentsFetchedSubject = new BehaviorSubject(false);
  negativeAgentsFetchedSubject$ = this.negativeAgentsFetchedSubject.asObservable();

  onFileAccepted = new Subject<any>();
  onFileRejected= new Subject<any>();
  updatescheduledCountSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private readonly configService: ConfigService,private messageService: MessageService,) {
    this.apiUrl = `${this.configService.baseUrl}`;
  }

  getUsersOfPartnersGroup(role: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/keycloak/users/${role}`);
  }

  logAgentMoment(agent: any, currentUser: string, currentRole: string,text:string) {
    let message: Message = {
      senderId: '',
      sendersRole: '',
      receiverId: '',
      receiverRole: '',
      content: '',
      projectId: 0,
      agentPublicId: '',
      timestamp: new Date().toISOString(),
      isLogged: true,
    };
    message.senderId = currentUser;
    message.sendersRole = currentRole;
    message.content = text;
    message.projectId = agent.project.id;
    message.agentPublicId = agent.public_id || '';
    message.receiverId = 'admin.user@photonic-codes.com';
    message.receiverRole = 'Admin';
    // if (agent.status === 'AgentCreatedFilesMissing' || agent.status === 'ApplicantGetsChecked' || agent.status === 'WaitingForAgent') {
    //   message.receiverId = agent?.project?.partnerInfo?.email || 'partner.user@photonic-codes.com';
    //   message.receiverRole = 'Partner';
    // }else{
    //   message.receiverId = agent?.project?.clientInfo?.email || 'client.user@photonic-codes.com';
    //   message.receiverRole = 'Client';
    // }

    this.messageService
    .sendMessage(message)
    .pipe(
      catchError((error) => {
        console.error('Error sending message:', error);
        return error;
      })
    )
    .subscribe((res) => {
      const channel = `${agent.project.id}-${agent.public_id}`;
      this.messageService.emitNewMessage({ ...message, channel });
      // this.messageToSent = '';
    });
  }

  emitUpdateScheduledCount(value: boolean): void {
    this.updatescheduledCountSubject.next(value);
  }
}
