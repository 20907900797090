import { Component, OnInit, ViewChild } from '@angular/core';
import { AgentsService } from '../utils/services/agents.service';
import { AgentStatus } from '../utils/models/agent.model';
import { DialogService } from '../dialogservice.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, delay, finalize, mergeMap, tap, toArray } from 'rxjs/operators';
import moment from 'moment';
import { KeycloakService } from 'keycloak-angular';
import { SharedService } from '../utils/services/shared.service';
import { forkJoin, from } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsService } from '../utils/services/projects.service';
import { MaterialModule } from '../material.module';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { AgentsFilterComponent } from './agents-filter/agents-filter.component';
import { AgentviewComponent } from '../agentview/agentview.component';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { RuleModel } from '@syncfusion/ej2-angular-querybuilder';
import { PartnersService } from '../utils/services/partners.service';

@Component({
  selector: 'app-archived-agents',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CheckBoxModule,
    DropDownListModule,
    AgentsFilterComponent,
    AgentviewComponent,
    SpinnerComponent
  ],
  templateUrl: './archived-agents.component.html',
  styleUrl: './archived-agents.component.scss'
})
export class ArchivedAgentsComponent implements OnInit {
  @ViewChild('agentsfilter') agentsFilter!: AgentsFilterComponent;

  archivedAgents: any = [];
  filteredAgents: any = [];
  public data: any = {};
  search = new FormControl('');
  isAllAgent: boolean = false;
  partnerId: string = '';
  partnerName: string = '';
  allAgents: any = [];
  uniquePartnerIdArray: any = [];
  isSpinnerActive: any = false;
  dropdownFields: any = { text: 'name', value: 'id' };
  b2b: boolean = false;

  currentRole: string = '';
  currentUser: string = '';

  constructor(
    private agentsService: AgentsService,
    public dialogService: DialogService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private projectsService: ProjectsService,
    public partnersService: PartnersService,
    private readonly keycloak: KeycloakService
  ) {
  }

  ngOnInit(): void {
    this.projectsService.getAllProjects('', 'Admin').subscribe({
      next: (res: any) => {
        this.projects = res;
      },
      error: (error) => {
        console.error('Error fetching projects: ', error);
      },
    });

    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];

    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
    }

    const status = AgentStatus.ApplicantArchived;
    this.isSpinnerActive = true;
    this.agentsService.getAgentByStatus(status).subscribe((res) => {
      this.archivedAgents = res;
      this.archivedAgents.forEach((agent: any) => {
        const { project, status, createdAt, agentProfilePhoto } = agent;

        agent.projectName = project ? project.name : '';
        agent.statusName = this.sharedService.statuses[status].display;
        agent.createdAt = moment(createdAt).format('DD.MM.YYYY');
        agent.profileImageSrc = agentProfilePhoto
          ? this.agentsService.convertImagetoUrl(agentProfilePhoto.photoBlob)
          : null;
      });
      this.filteredAgents = this.archivedAgents;
      this.isSpinnerActive = false;
      this.getAllAgent();
    });

    // const tokenData = this.keyCloak.getKeycloakInstance().tokenParsed;
    // const userRoles = tokenData?.realm_access?.roles;
    // if (userRoles?.includes('Partner')) {
    //   this.partnerId = tokenData?.['sub'] || '';
    // } else {
    //   this.partnerId = '';
    // }

    this.partnersService.fetchAll(() => {
      this.partnerName = this.partnersService.partners.find(partner => partner.userId === this.partnerId)?.partnerName ?? '';
    });

    this.search.valueChanges.pipe(debounceTime(300)).subscribe((newValue: string | null) => this.filterAgentsByValue(newValue));
  }

  public filterAgentsByValue(newValue: string | null): void {
    let all = this.allAgents;
    let archived = this.archivedAgents;

    if (this.b2b) {
      all = all.filter((agent: any) => agent.b2bCapable);
      archived = archived.filter((agent: any) => agent.b2bCapable);
    }

    if (this.isAllAgent) {
      if (newValue && newValue !== '') {
        this.filteredAgents = this.filterAgents(all, newValue);
      } else {
        this.filteredAgents = [...all];
      }
    } else {
      if (newValue && newValue !== '') {
        this.filteredAgents = this.filterAgents(archived, newValue);
      } else {
        this.filteredAgents = [...archived];
      }
    }
  }

  public filter() {
    this.filterAgentsByValue(this.search.value);

    const ruleModel = this.agentsFilter.getFilter();
    this.filteredAgents = this.filteredAgents.filter((agent: any) => this.applyFilter(agent, ruleModel));
  }

  private applyFilter(agent: any, ruleModel: RuleModel): boolean {
    let result = ruleModel.condition === 'and';

    const stack = [ruleModel];
    const parents = new Map<RuleModel, RuleModel>();

    while (stack.length > 0) {
      const current = stack.shift();

      if (!current) {
        continue;
      }

      if (!current.rules) {
        continue;
      }

      const ruleListResult = this.applyRuleList(agent, current);

      const parent = parents.size > 0 ? parents.get(current)! : current;
      result = parent.condition === 'and' ? result && ruleListResult : result || ruleListResult;

      if (current.rules) {
        for (const rule of current.rules) {
          if (rule.rules) {
            stack.push(rule);
          }
          parents.set(rule, current);
        }
      }
    }

    return result;
  }

  private applyRuleList(agent: any, ruleModel: RuleModel): boolean {
    const condition = ruleModel.condition!;
    const rules = ruleModel.rules!;

    let result = condition === 'and';

    for (const rule of rules) {
      if (rule.condition) {
        continue;
      }

      const field = rule.field!;
      const operator = rule.operator!;
      const type = rule.type!;
      const value = rule.value!;

      let subResult = false;

      switch (operator) {
        case 'startswith':
          subResult = agent[field].startsWith(value);
          break;
        case 'notstartswith':
          subResult = !agent[field].startsWith(value);
          break;
        case 'endswith':
          subResult = agent[field].endsWith(value);
          break;
        case 'notendswith':
          subResult = !agent[field].endsWith(value);
          break;
        case 'contains':
          subResult = agent[field].includes(value);
          break;
        case 'notcontains':
          subResult = !agent[field].includes(value);
          break;
        case 'equal':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() === new Date(value as string).getTime();
          } else if (value === 'Keine' || value === 'Schlecht' || value === 'Mittel' || value === 'Gut') {
            switch (value) {
              case 'Keine':
                subResult = agent[field] === 'None';
                break;
              case 'Schlecht':
                subResult = agent[field] === 'Bad';
                break;
              case 'Mittel':
                subResult = agent[field] === 'Medium';
                break;
              case 'Gut':
                subResult = agent[field] === 'Good';
                break;
            }
          } else if (value === 'Ja' || value === 'Nein') {
            subResult = agent[field] === (value === 'Ja' ? 'true' : 'false');
          } else {
            subResult = agent[field] === value;
          }
          break;
        case 'notequal':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() !== new Date(value as string).getTime();
          } else if (value === 'Keine' || value === 'Schlecht' || value === 'Mittel' || value === 'Gut') {
            switch (value) {
              case 'Keine':
                subResult = agent[field] !== 'None';
                break;
              case 'Schlecht':
                subResult = agent[field] !== 'Bad';
                break;
              case 'Mittel':
                subResult = agent[field] !== 'Medium';
                break;
              case 'Gut':
                subResult = agent[field] !== 'Good';
                break;
            }
          } else if (value === 'Ja' || value === 'Nein') {
            subResult = agent[field] !== (value === 'Ja' ? 'true' : 'false');
          } else {
            subResult = agent[field] !== value;
          }
          break;
        case 'in':
          subResult = (value as any[]).includes(agent[field] as any);
          break;
        case 'notin':
          subResult = !(value as any[]).includes(agent[field] as any);
          break;
        case 'isempty':
          subResult = agent[field] === '';
          break;
        case 'isnotempty':
          subResult = agent[field] !== '';
          break;
        case 'isnull':
          subResult = agent[field] === null;
          break;
        case 'isnotnull':
          subResult = agent[field] !== null;
          break;

        case 'greaterthanorequal':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() >= new Date(value as string).getTime(); 
          } else if (type === 'number') {
            subResult = agent[field] >= value;
          }
          break;
        case 'greaterthan':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() > new Date(value as string).getTime();
          } else if (type === 'number') {
            subResult = agent[field] > value;
          }
          break;
        case 'between':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() >= new Date((value as string[])[0]).getTime() && new Date(agent[field]).getTime() <= new Date((value as string[])[1]).getTime();
          } else if (type === 'number') {
            subResult = agent[field] >= (value as number[])[0] && agent[field] <= (value as number[])[1];
          }
          break;
        case 'notbetween':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() < new Date((value as string[])[0]).getTime() || new Date(agent[field]).getTime() > new Date((value as string[])[1]).getTime();
          } else if (type === 'number') {
            subResult = agent[field] < (value as number[])[0] || agent[field] > (value as number[])[1];
          }
          break;
        case 'lessthan':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() < new Date(value as string).getTime();
          } else if (type === 'number') {
            subResult = agent[field] < value;
          }
          break;
        case 'lessthanorequal':
          if (type === 'date') {
            subResult = new Date(agent[field]).getTime() <= new Date(value as string).getTime();
          } else if (type === 'number') {
            subResult = agent[field] <= value;
          }
          break;
        
        case 'equalorbetter':
          if (value === 'Keine' || value === 'Schlecht') {
            subResult = agent[field] === 'None' || agent[field] === 'Bad' || agent[field] === 'Medium' || agent[field] === 'Good';
          } else if (value === 'Mittel') {
            subResult = agent[field] === 'Medium' || agent[field] === 'Good';
          } else if (value === 'Gut') {
            subResult = agent[field] === 'Good';
          }
      }

      result = condition === 'and' ? result && subResult : result || subResult;

      if (condition === 'or' && result) {
        break;
      }
    }

    return result;
  }

  onClickAgent(agent: any) {
    this.data = {
      agentId: agent.id,
      projectId: agent.project?.id || null,
      coloumnName: agent.status,
      ...agent,
    };
    this.dialogService.showAgentView = true;
  }

  onClose(event: any) {
    this.archivedAgents = this.archivedAgents.filter(
      (agent: any) => agent.id != this.data.agentId
    );
    this.filteredAgents = this.archivedAgents;
  }

  filterAgents(agentArray: any[], searchTerm: string): any[] {
    return agentArray.filter((agent: any) => {
      const excludedFields = [
        'profileImageSrc',
        'voiceFiles',
        'videoFiles',
        'agentProfilePhoto',
        'internalNote',
        'project',
        'updatedAt',
        'partnerId',
      ];

      const fullName = `${agent.firstname} ${agent.lastname}`.toLowerCase();
      const searchLower = searchTerm.toLowerCase();

      if (fullName.includes(searchLower)) {
        return true;
      }

      return Object.keys(agent).some((key) => {
        if (!excludedFields.includes(key)) {
          const value = agent[key];
          if (key === 'phoneNumber') {
            return this.hasPhoneNumber(searchLower, value);
          } else if (typeof value === 'string') {
            return value.toLowerCase().includes(searchLower);
          }
        }
        return false;
      });
    });
  }

  private hasPhoneNumber(searchedPhoneNumber: string, currentPhoneNumber: string): boolean {
    const phoneNumberFromSearch = searchedPhoneNumber.replaceAll(' ', '');

    const variants = [];
    variants.push(currentPhoneNumber.replaceAll(' ', ''));
    variants.push(currentPhoneNumber.replaceAll(' 0', '').replaceAll(' ', ''));
    variants.push(currentPhoneNumber.replaceAll('+', '').replaceAll(' 0', '').replaceAll(' ', ''));

    const splitedPhoneNumber: string[] = currentPhoneNumber.split(' ');
    if (splitedPhoneNumber.length > 1) {
      splitedPhoneNumber.shift();
      const phoneNumberWithoutCountryCode = splitedPhoneNumber.join(' ');
      variants.push(phoneNumberWithoutCountryCode.replaceAll(' ', ''));
      variants.push(phoneNumberWithoutCountryCode.replaceAll('0 ', '').replaceAll(' ', ''));
    }

    return variants.some((variant) => variant.includes(phoneNumberFromSearch) || phoneNumberFromSearch.includes(variant));
  }

  public onToggleSwitch(): void {
    this.search.setValue(this.search.value);
  }

  fetchPartnerInfo() {
    const partnerObservables = this.uniquePartnerIdArray.map((partnerId: any) =>
      this.agentsService.getPartnerInfo(partnerId)
    );
    forkJoin(partnerObservables).subscribe({
      next: (partnerInfos: any) => {
        const partnerInfoMap: any = {};

        // Map partnerId to its fetched info
        this.uniquePartnerIdArray.forEach((id: any, index: any) => {
          partnerInfoMap[id] = partnerInfos[index];
        });

        this.allAgents = this.allAgents.map((item: any) => ({
          ...item,
          partnerLastName: partnerInfoMap[item.partnerId]?.lastName,
          partnerFirstName: partnerInfoMap[item.partnerId]?.firstName,
        }));
      },
      error: (error: any) => {
        console.error('Error fetching partner info:', error);
      },
    });
  }

  getAllAgent() {
    this.agentsService.getAllAgentsByUserRole('').subscribe((res) => {
      this.allAgents = res;
      this.allAgents.forEach((agent: any) => {
        agent.projectName = agent.project ? agent.project.name : '';
        agent.statusName = this.sharedService.statuses[agent.status].display;
        agent.createdAt = moment(agent.createdAt).format('DD.MM.YYYY');
        if (agent.agentProfilePhoto) {
          agent.profileImageSrc = this.agentsService.convertImagetoUrl(
            agent.agentProfilePhoto.photoBlob
          );
        } else {
          agent.profileImageSrc = null;
        }
        if (!this.uniquePartnerIdArray.includes(agent.partnerId)) {
          this.uniquePartnerIdArray.push(agent.partnerId);
        }
      });
      this.fetchPartnerInfo();
      // this.filteredAgents = this.allAgents;
    });
  }

  public isMediaPlay: boolean = false;
  public selectedAudioUrl: any;
  public selectedVideoUrl: any;

  openMediaModal(event: Event, id: number) {
    event.stopPropagation();
    this.getSelectedAgentsSelectedMedia(id);
  }

  getSelectedAgentsSelectedMedia(id: number) {
    this.isSpinnerActive = true;
    const agent: any = this.filteredAgents.find((agent: any) => agent.id === id);
    const videoBlob = agent?.videoFiles.find(
      (video: any) => video.status === 'accepted'
    );
    const voiceBlob = agent?.voiceFiles.find(
      (voice: any) => voice.status === 'accepted'
    );

    if (videoBlob) {
      // this.agentsService.getVideoById(videoBlob.id).subscribe((res: any) => {
        // this.convertToVideoBlobUrl(res.videoBlob.data);
      this.selectedVideoUrl = this.agentsService.getVideoStreamUrl(videoBlob.id);
        if (voiceBlob) {
          // this.agentsService
          //   .getAudioById(voiceBlob.id)
          //   .subscribe((res: any) => {
              // this.convertToAudioBlobUrl(res.voiceBlob.data);
              this.selectedAudioUrl = this.agentsService.getAudioStreamUrl(voiceBlob.id);
              this.isMediaPlay = true;
              this.isSpinnerActive = false;
            // });
        } else {
          this.isMediaPlay = true;
          this.isSpinnerActive = false;
        }
      // });
    } else if (voiceBlob) {
      // this.agentsService.getAudioById(voiceBlob.id).subscribe((res: any) => {
      //   this.convertToAudioBlobUrl(res.voiceBlob.data);
        this.selectedAudioUrl = this.agentsService.getAudioStreamUrl(voiceBlob.id);
        this.isMediaPlay = true;
        this.isSpinnerActive = false;
      // });
    }
    if (!videoBlob && !voiceBlob) {
      this.snackBar.open('Nicht akzeptiert Audiodatei oder Videodatei.', '', {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      this.isSpinnerActive = false;
    }
  }

  isPlayIconVisible(entry: any) {
    switch (entry.status) {
      case 'WaitingForAgent':
      case 'ApplicantGetsSuggested':
      case 'ApplicantRejected':
      case 'AppointmentScheduled':
      case 'ApplicantAccepted':
      case 'ApplicantConfirmed':
        return entry.voiceFiles.length > 0 || entry.videoFiles.length > 0;
      default:
        return false;
    }
  }

  getPlayIconColor(entry: any) {
    if (entry.voiceFiles.some((obj: any) => obj.status === 'accepted') || entry.videoFiles.some((obj: any) => obj.status === 'accepted')) {
      return '#24b89b';
    } else if (
      entry.voiceFiles.every((obj: any) => obj.status !== 'accepted')
    ) {
      return '#af1c3d';
    } else {
      return '#6c6c6c';
    }
    // if (entry.voiceFiles.some((obj: any) => obj.isPlayed === false)) {
    //   return '#af1c3d';
    // } else if (entry.voiceFiles.every((obj: any) => obj.isPlayed === true)) {
    //   return '#24b89b';
    // } else {
    //   return '#6c6c6c';
    // }
  }

  public selectedAgents: Map<number, any> = new Map<number, any>();

  onSelectAgent(args: any, agent: any) {
    args.event.stopPropagation();
    if (args.checked) {
      this.selectedAgents.set(agent.id, agent);
    } else {
      this.selectedAgents.delete(agent.id);
    }
  }

  public projects: any = [];
  public selectedProject: any = null;

  onMoveToProject() {
    if (!this.selectedProject) {
      this.snackBar.open('Bitte wählen Sie ein Projekt aus.', '', {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      return;
    }

    const reasonToMoveInOtherProject = 'Mehrere Agenten wurden in ein anderes Projekt verschoben';

    from(this.selectedAgents).pipe(
      mergeMap(agent => this.agentsService.agentMoveToOtherProject(agent[0], { projectId: this.selectedProject, reasonToMoveInOtherProject: reasonToMoveInOtherProject })),
      tap(receivedAgent => {
        const text = `Verschieben in ein anderes Projekt mit Grund "${reasonToMoveInOtherProject}"`;
        this.sharedService.logAgentMoment(receivedAgent, this.currentUser, this.currentRole, text);
      }),
      toArray(),
      tap(() => {
        this.snackBar.open('Alle Agenten wurden erfolgreich verschoben!', '', {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
      }),
      delay(1000),
      finalize(() => window.location.reload())
    ).subscribe();
  }

  adjustImage(image: HTMLImageElement): void {
    if (image.naturalWidth === image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = '100%';
    } else if (image.naturalWidth < image.naturalHeight) {
      image.style.width = '100%';
      image.style.height = 'auto';
    } else {
      image.style.width = 'auto';
      image.style.height = '100%';
    }
  }
}
