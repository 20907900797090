<div class="MainPage">
  <div class="seitenmenue" *ngIf="!isAgentUser">
    <div class="menuEntry" [routerLink]="['/projectoverview']" routerLinkActive="active-link">
      <div style="color: #1FC2A4;">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="currentColor" d="M4.75 7a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5zM5 4.75A.75.75 0 0 1 5.75 4h5.5a.75.75 0 0 1 0 1.5h-5.5A.75.75 0 0 1 5 4.75M6.75 10a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5z"/><path fill="currentColor" d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25Zm1.75-.25a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25V1.75a.25.25 0 0 0-.25-.25Z"/></svg>
      </div>
      <span style="font-size: 14pt">Projekte</span>
    </div>

    <div class="meineProjekte" [routerLink]="['/open-tasks']" routerLinkActive="active-link" *ngIf="currentRole === 'Admin'">
      <span style="color: #1FC2A4;">
      <svg
        xmlns:xlink="http://www.w3.org/1999/xlink"
        fill="none"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        style="-webkit-print-color-adjust: exact"
        id="screenshot-784c5983-b509-808f-8003-ecae2bab5480"
        version="1.1"
        viewBox="-552 -1609.472 24 23.333"
        height="23.333"
      >
        <g
          id="shape-784c5983-b509-808f-8003-ecae2bab5480"
          style="fill: #000000"
          width="1em"
          height="1em"
          rx="0"
          ry="0"
        >
          <g id="shape-784c5983-b509-808f-8003-ecae2bab9d43">
            <g class="fills" id="fills-784c5983-b509-808f-8003-ecae2bab9d43">
              <path
                rx="0"
                ry="0"
                style="fill: #24b89b; fill-opacity: 1"
                d="M-537.700,-1588.375C-538.400,-1588.181,-539.200,-1588.083,-540.000,-1588.083C-545.500,-1588.083,-550.000,-1592.458,-550.000,-1597.806C-550.000,-1603.153,-545.500,-1607.528,-540.000,-1607.528C-538.700,-1607.528,-537.400,-1607.236,-536.200,-1606.847L-537.800,-1605.292C-538.500,-1605.486,-539.200,-1605.583,-540.000,-1605.583C-544.400,-1605.583,-548.000,-1602.083,-548.000,-1597.806C-548.000,-1593.528,-544.400,-1590.028,-540.000,-1590.028C-539.600,-1590.028,-539.100,-1590.028,-538.700,-1590.125C-538.500,-1589.444,-538.100,-1588.861,-537.700,-1588.375ZM-544.100,-1599.653L-545.500,-1598.292L-541.000,-1593.917L-531.000,-1603.639L-532.400,-1605.000L-541.000,-1596.639ZM-534.000,-1595.861L-534.000,-1592.944L-537.000,-1592.944L-537.000,-1591.000L-534.000,-1591.000L-534.000,-1588.083L-532.000,-1588.083L-532.000,-1591.000L-529.000,-1591.000L-529.000,-1592.944L-532.000,-1592.944L-532.000,-1595.861Z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      </span>
      <span style="font-size: 14pt; vertical-align: middle;"
        >Offene Aufgaben</span
      >
    </div>

    <div class="menuEntry" *ngIf="currentRole === 'Admin'" routerLinkActive="active-link" [routerLink]="['/archived-agents']">
      <span style="color: #1FC2A4;">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M2 5c0-.943 0-1.414.293-1.707C2.586 3 3.057 3 4 3h16c.943 0 1.414 0 1.707.293C22 3.586 22 4.057 22 5c0 .943 0 1.414-.293 1.707C21.414 7 20.943 7 20 7H4c-.943 0-1.414 0-1.707-.293C2 6.414 2 5.943 2 5"/><path fill="currentColor" fill-rule="evenodd" d="M20.069 8.5c.141 0 .286 0 .431-.002V13c0 3.771 0 5.657-1.172 6.828C18.157 21 16.271 21 12.5 21h-1c-3.771 0-5.657 0-6.828-1.172C3.5 18.657 3.5 16.771 3.5 13V8.498c.145.002.29.002.431.002zM9 12c0-.466 0-.699.076-.883a1 1 0 0 1 .541-.541c.184-.076.417-.076.883-.076h3c.466 0 .699 0 .883.076a1 1 0 0 1 .54.541c.077.184.077.417.077.883s0 .699-.076.883a1 1 0 0 1-.541.54c-.184.077-.417.077-.883.077h-3c-.466 0-.699 0-.883-.076a1 1 0 0 1-.54-.541C9 12.699 9 12.466 9 12" clip-rule="evenodd"/></svg>
      </span>
      <span style="font-size: 14pt">Archiv</span>
    </div>

    <div class="menuEntry" *ngIf="currentRole == 'Admin'" routerLinkActive="active-link" [routerLink]="['/user-management']">
      <span style="color: #1FC2A4;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="rgba(31,194,164,1)"><path d="M12 14V22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM14.5946 18.8115C14.5327 18.5511 14.5 18.2794 14.5 18C14.5 17.7207 14.5327 17.449 14.5945 17.1886L13.6029 16.6161L14.6029 14.884L15.5952 15.4569C15.9883 15.0851 16.4676 14.8034 17 14.6449V13.5H19V14.6449C19.5324 14.8034 20.0116 15.0851 20.4047 15.4569L21.3971 14.8839L22.3972 16.616L21.4055 17.1885C21.4673 17.449 21.5 17.7207 21.5 18C21.5 18.2793 21.4673 18.551 21.4055 18.8114L22.3972 19.3839L21.3972 21.116L20.4048 20.543C20.0117 20.9149 19.5325 21.1966 19.0001 21.355V22.5H17.0001V21.3551C16.4677 21.1967 15.9884 20.915 15.5953 20.5431L14.603 21.1161L13.6029 19.384L14.5946 18.8115ZM18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17Z"></path></svg>
      </span>
      <span style="font-size: 14pt">Userverwaltung</span>
    </div>
  </div>
  <div class="mainContentArea" [ngClass]="{mainContent: isAgentUser }" >
    <div class="obersteZeile" >
      <img
        src="https://github.com/Photonic-Codes-GmbH/resource-collection/blob/main/smylo%20logo%20transparent%20(1).png?raw=true"
        alt=""
        class="brand-logo"
      />

      <div class="userbox" *ngIf="isLoggedIn && !isAgentUser">
        <span class="userIcon"></span>

        <div class="rightBox">
          <div style="font-size: large">{{currentName}}</div>
          <div class="emailText">
            <div>{{ currentUser }}</div>
          </div>
        </div>
        <div style="cursor: pointer;" (click)="onClickLogout()">
          <mat-icon  matTooltip="Abmeldung">exit_to_app</mat-icon>
        </div>
      </div>
    </div>
    <div class="hauptscreen">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- <div *ngIf="islogoutPopup" class="backdrop"></div>
<div class="logout-popup-container"  *ngIf="islogoutPopup">
  <h2>
    Sind Sie sicher, dass Sie sich abmelden möchten?
  </h2>
  <div class="d-flex gap-4">
    <button (click)="islogoutPopup = !islogoutPopup" mat-stroked-button class="mt-3">Abbrechen</button>
    <button (click)="onClickLogout()" mat-stroked-button class="mt-3">Ja</button>
  </div>
</div> -->
