import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from '../dialogservice.service';
import { AdminService } from '../utils/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { CustomersService } from '../utils/services/customers.service';
import { CommonModule } from '@angular/common';
import { NewUserComponent } from '../new-user/new-user.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CustomerManagementComponent } from '../customer-management/customer-management.component';
import { PartnerManagementComponent } from '../partner-management/partner-management.component';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatButtonModule } from '@angular/material/button';
import { PartnersService } from '../utils/services/partners.service';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    SwitchModule,
    NewUserComponent,
    CustomerManagementComponent,
    PartnerManagementComponent,
    SpinnerComponent
  ],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
})
export class UserManagementComponent implements OnInit {
  users: any = [];

  selectedUser: any = {};
  isForUpdate: boolean = false;
  deleteConfirmationPopup: boolean = false;
  userForDelete: any = {};
  currentSW: any = {};
  isSpinnerActive: boolean = false;

  constructor(
    public dialogService: DialogService,
    public adminService: AdminService,
    private snackBar: MatSnackBar,
    private readonly customersService: CustomersService,
    private readonly partnersService: PartnersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getAllKeyCloakUser();
  }

  onClickAddUser() {
    this.dialogService.showNewUserDialog = true;
    this.isForUpdate = false;
    this.selectedUser = {};
  }

  onClickUser(user: any) {
    this.selectedUser = user;
    this.isForUpdate = true;
    this.dialogService.showNewUserDialog = true;
  }

  userChange(event: any) {
    this.dialogService.showNewUserDialog = false;

    const userObject = { ...event };
    delete userObject.customerName;

    if (this.isForUpdate) {
      const { credentials, ...rest } = userObject;

      this.adminService
        .updateKeyCloakUser({ ...rest, id: this.selectedUser.id })
        .subscribe((res) => {
          const index = this.users.findIndex(
            (user: any) => user.id === this.selectedUser.id
          );
          this.users[index] = {
            ...userObject,
            id: this.selectedUser.id,
            role: this.selectedUser.role,
            rolle: this.selectedUser.rolle,
          };

          if (event.customerName) {
            this.changeCustomer(this.selectedUser.id, event.customerName);
          }
        });
    } else {
      this.adminService.createKeyCloakUser(userObject).subscribe({
        next: (res) => {
          this.getAllKeyCloakUser(() => {
            if (event.customerName) {
              const id = this.users.find((user: any) => user.username === (event.username as string).toLocaleLowerCase())?.id;
              this.changeCustomer(id, event.customerName);
            }
          });
        },
        error: (error: any) => {
          let message = 'Benutzer kann nicht erstellt werden';
          if (
            error.error.message ==
            'Failed to create user: User exists with same username'
          ) {
            message =
              'Benutzer kann nicht erstellt werden: Benutzer existiert mit demselben Benutzernamen';
          } else if (
            error.error.message ==
            'Failed to create user: User exists with same email'
          ) {
            message =
              'Benutzer kann nicht erstellt werden: Benutzer existiert mit derselben E-Mail';
          }
          this.snackBar.open(message, '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        },
      });
    }
  }

  private changeCustomer(userId: string, customerName: string) {
    const customer = {
      id: this.customersService.customers.find((customer) => customer.userId === userId)?.id,
      userId: userId,
      customerName: customerName
    };
    this.customersService.isForUpdate = customer.id ? true : false;
    this.customersService.change(customer);
  }

  public getAllKeyCloakUser(fn?: () => void): void {
    this.isSpinnerActive = true;

    const userStreams = [
      this.adminService.getAllKeyCloakUserGroupVise('Admins'),
      this.adminService.getAllKeyCloakUserGroupVise('Clients'),
      this.adminService.getAllKeyCloakUserGroupVise('Partners')
    ];

    forkJoin(userStreams).subscribe({
      next: ([adminsResponse, clientsResponse, partnersResponse]) => {
        this.users = [
          ...adminsResponse.map((user) => ({ ...user, role: 'Admins', rolle: 'Admin' })),
          ...clientsResponse.map((user) => ({ ...user, role: 'Clients', rolle: 'Client' })),
          ...partnersResponse.map((user) => ({ ...user, role: 'Partners', rolle: 'Partner' }))
        ];

        this.isSpinnerActive = false;

        if (fn) {
          fn();
        }
      },
      error: (error: any) => {
        this.users = [];

        console.error('Error fetching users:', error);
      }
    });
  }

  onUserEnabledSwitch(event: any, user: any, sw: any) {
    this.userForDelete = user;
    this.currentSW = sw;

    if (event.checked) {
      this.adminService
        .activateKeyCloakUser(this.userForDelete.id)
        .subscribe(() => {
          this.deleteConfirmationPopup = false;
          this.userForDelete = {};
          this.currentSW = {};
          this.customersService.fetchAll(() => {});
        });
    } else {
      this.deleteConfirmationPopup = true;
    }
  }

  onClickJa() {
    this.adminService
      .deleteKeyCloakUser(this.userForDelete.id)
      .subscribe(() => {
        this.deleteConfirmationPopup = false;
        this.userForDelete = {};
        this.currentSW = {};
        this.customersService.fetchAll(() => {});
      });
  }

  onClickNein() {
    setTimeout(() => {
      this.userForDelete.enabled = true;
      this.currentSW.checked = true;
      this.changeDetectorRef.detectChanges();

      this.deleteConfirmationPopup = !this.deleteConfirmationPopup;
      this.userForDelete = {};
      this.currentSW = {};
    }, 500);
  }
}
