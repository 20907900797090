import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeycloakService } from 'keycloak-angular';
import { DialogService } from 'src/app/dialogservice.service';
import { AgentsService } from 'src/app/utils/services/agents.service';
import { BookableTimeSlotsService } from 'src/app/utils/services/bookable-time-slots.service';
import { SharedService } from 'src/app/utils/services/shared.service';
import { DragAndDropCardComponent } from '../drag-and-drop-card/drag-and-drop-card.component';

@Component({
  selector: 'drag-and-drop-card-list',
  standalone: true,
  imports: [CommonModule, DragAndDropCardComponent],
  templateUrl: './drag-and-drop-card-list.component.html',
  styleUrls: ['./drag-and-drop-card-list.component.scss'],
})
export class DragAndDropCardListComponent implements OnInit {
  @Input()
  public id: string;

  @Input()
  public headerText: any;

  @Input()
  public data: any;

  @ContentChild('cardTemplate', { static: true })
  public cardTemplate!: TemplateRef<any>;

  @Output()
  public dropEntry: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  scheduledCount: number = 0;

  @Input()
  terminAgentsCount: number = 0;

  headersArray: { header: string; collapsed: boolean }[] = [];
  currentUser: string = '';
  currentRole: string = '';

  public constructor(
    public dialogService: DialogService,
    public sharedService: SharedService,
    private btsService: BookableTimeSlotsService,
    private keyCloak: KeycloakService,
    public agentService: AgentsService,
    public snackBar: MatSnackBar
  ) {
    this.id = '';
    this.headerText = '';
    // this.data = [];
  }

  ngOnInit(): void {
    const tokenData = this.keyCloak.getKeycloakInstance().tokenParsed;
    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];

    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
    }
    this.currentUser = tokenData?.['email'];
  }
  public onDragStart(event: any, entry: any): void {
    const eventData = {
      id: this.id,
      data: entry,
    };
    event.dataTransfer.setData('cards-entry', JSON.stringify(eventData));
    event.dataTransfer.effectAllowed = 'move';
  }

  public allowDrop(event: any): void {
    event.preventDefault();
    if (this.currentRole === 'Partner') {
      event.dataTransfer.dropEffect = 'none';
    }
  }

  public onDrop(event: any): void {
    event.preventDefault();

    const eventData = event.dataTransfer.getData('cards-entry');
    if (!eventData) {
      return;
    }

    const parsedEventData = JSON.parse(eventData);
    if (!parsedEventData) {
      return;
    }
    const dropAllow = this.isAllowDrop(
      parsedEventData.id,
      this.id,
      parsedEventData.data
    );
    if (this.dropEntry && !dropAllow) {
      return;
    }
    this.dropEntry.emit({
      from: parsedEventData.id,
      to: this.id,
      data: parsedEventData.data,
    });
  }

  isAllowDrop(fromColumn: string, toColumn: string, agent: any) {
    let isAllow = false;
    let warningMessage = '';

    switch (fromColumn) {
      case 'AgentCreatedFilesMissing':
        if (
          toColumn === 'ApplicantGetsChecked' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        }
        break;
      case 'ApplicantGetsChecked':
        if (
          (toColumn === 'WaitingForAgent' ||
            toColumn === 'ApplicantRejected') &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'AgentCreatedFilesMissing' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        }
        break;
      case 'WaitingForAgent':
        if (
          toColumn === 'ApplicantGetsSuggested' &&
          this.currentRole === 'Admin' &&
          (agent.voiceFiles.some((obj: any) => obj.status === 'accepted') ||
            agent.videoFiles.some((obj: any) => obj.status === 'accepted'))
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantRejected' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantGetsChecked' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantGetsSuggested' &&
          this.currentRole === 'Admin' &&
          !(
            agent.voiceFiles.some((obj: any) => obj.status === 'accepted') ||
            agent.videoFiles.some((obj: any) => obj.status === 'accepted')
          )
        ) {
          this.showDialog();
          isAllow = true;
        }
        break;
      case 'ApplicantGetsSuggested':
        if (
          (toColumn === 'AppointmentScheduled' ||
            toColumn === 'ApplicantRejected') &&
          this.currentRole === 'Client'
        ) {
          isAllow = true;
        } else if (
          (toColumn === 'WaitingForAgent' ||
            toColumn === 'AppointmentScheduled' ||
            toColumn === 'ApplicantRejected') &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        }
        break;
      case 'ApplicantRejected':
        if (
          agent.status === 'ApplicantRejected' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        } else if (
          agent.status === 'ApplicantRejected' &&
          toColumn === agent.previousStatus
        ) {
          isAllow = true;
        }
        break;
      case 'AppointmentScheduled':
        if (
          toColumn === 'ApplicantAccepted' &&
          this.currentRole !== 'Partner' &&
          agent.interviewAppointment
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantRejected' &&
          this.currentRole !== 'Partner'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantGetsSuggested' &&
          this.currentRole !== 'Partner'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantAccepted' &&
          this.currentRole !== 'Partner' &&
          !agent.interviewAppointment
        ) {
          warningMessage =
            'Zum Verschieben wird ein Interviewtermin mit diesem Agent benötigt.';
        }
        break;
      case 'ApplicantAccepted':
        if (
          toColumn === 'ApplicantRejected' &&
          this.currentRole !== 'Partner'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantConfirmed' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        } else if (
          toColumn === 'AppointmentScheduled' &&
          this.currentRole !== 'Partner'
        ) {
          isAllow = true;
        }
        break;
      case 'ApplicantConfirmed':
        if (toColumn === 'ApplicantAccepted' && this.currentRole === 'Admin') {
          isAllow = true;
        } else if (
          toColumn === 'ApplicantRejected' &&
          this.currentRole === 'Admin'
        ) {
          isAllow = true;
        }
        break;

      default:
        break;
    }

    if (warningMessage == '') {
      warningMessage = `Als ${this.currentRole} können Sie nicht den Agenten von ${this.sharedService.statuses[fromColumn].display} zu ${this.sharedService.statuses[toColumn].display} verschieben.`;
    }

    if (!isAllow) {
      this.showToster(warningMessage);
    }

    return isAllow;
  }

  showToster(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  showDialog() {
    this.dialogService.performDropAction = false;
    this.dialogService.showMoveAgentWithoutVoicefileDialog = true;
  }
}
