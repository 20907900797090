<ejs-querybuilder
  id="querybuilder"
  #querybuilder
  locale="de-DE"
  cssClass="row"
  [dataSource]="dataSource"
  [columns]="columns"
  width="100%"
>
</ejs-querybuilder>
