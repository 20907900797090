import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private apiUrl = '';
  private keycloakUrl = '';
  constructor(
    private http: HttpClient,
    private readonly configService: ConfigService
  ) {
    this.apiUrl = `${this.configService.baseUrl}/admin`;
    this.keycloakUrl = `${this.configService.baseUrl}/keycloak`;
  }

  getAgentsGroupedByProject(status: string) {
    return this.http.get<any[]>(`${this.apiUrl}/agents/${status}`);
  }

  getWithdrawAgentsGroupedByProject() {
    return this.http.get<any[]>(`${this.apiUrl}/withdraw-agents`);
  }

  createKeyCloakUser(body: any) {
    return this.http.post(`${this.keycloakUrl}/user`, body);
  }

  getAllKeyCloakUserGroupVise(group: string) {
    return this.http.get<any[]>(`${this.keycloakUrl}/users/${group}`);
  }

  updateKeyCloakUser(body: any) {
    const { id, ...rest } = body;
    return this.http.put(`${this.keycloakUrl}/user/${id}`, rest);
  }

  deleteKeyCloakUser(id: string) {
    return this.http.delete(`${this.keycloakUrl}/user/${id}`);
  }

  activateKeyCloakUser(id: string) {
    return this.http.post(`${this.keycloakUrl}/user/${id}`, {});
  }

  getAgentsWithUnreadMessages() {
    return this.http.get<any[]>(`${this.apiUrl}/agents/agents-with-unread-messages`);
  }

  getAgentsWithUnreadWhatsAppMessages() {
    return this.http.get<any[]>(`${this.apiUrl}/agents/agents-with-unread-whatsapp-messages`);
  }
}
