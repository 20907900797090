import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  @Input() color: any = '#23BB9D';
  @Input() diameter: number = 50;
}
