import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnsModel, QueryBuilderComponent, QueryBuilderModule, RuleModel, TemplateColumn } from '@syncfusion/ej2-angular-querybuilder';
import { createElement, getComponent, L10n, setCulture } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { PartnersService } from 'src/app/utils/services/partners.service';

setCulture('de-DE');

L10n.load({
  'de-DE': {
    'querybuilder': {
      'AddGroup': 'Gruppe hinzufügen',
      'AddCondition': 'Bedingung hinzufügen',
      "AddButton": "Gruppe/Bedingung hinzufügen",
      'DeleteRule': 'Entfernen Sie diesen Zustand',
      'DeleteGroup': 'Gruppe löschen',
      'Edit': 'BEARBEITEN',
      'SelectField': 'Wählen Sie ein Feld aus',
      'SelectOperator': 'Operator auswählen',
      'StartsWith': 'Beginnt mit',
      'EndsWith': 'Endet mit',
      'DoesNotStartWith': 'Beginnt nicht mit',
      'DoesNotEndWith': 'Endet nicht mit',
      'DoesNotContain': 'Beinhaltet nicht',
      'Contains': 'Enthält',
      'Equal': 'Gleich',
      'NotEqual': 'Nicht gleich',
      'LessThan': 'Weniger als',
      'LessThanOrEqual': 'Weniger als oder gleich',
      'GreaterThan': 'Größer als',
      'GreaterThanOrEqual': 'Größer als oder gleich',
      'Between': 'Zwischen',
      'NotBetween': 'Nicht zwischen',
      'In': 'Im',
      'NotIn': 'Nicht in',
      'Remove': 'LÖSCHEN',
      'ValidationMessage': 'Dieses Feld wird benötigt',
      'True': 'Wahr',
      'False': 'Falsch',
      'IsEmpty': 'Ist leer',
      'IsNotEmpty': 'Ist nicht leer',
      'IsNull': 'Ist Null',
      'IsNotNull': 'Ist nicht Null',
      'EqualOrBetter': 'Gleich oder besser'
    }
  }
});

@Component({
  selector: 'app-agents-filter',
  standalone: true,
  imports: [QueryBuilderModule],
  templateUrl: './agents-filter.component.html',
  styleUrl: './agents-filter.component.scss'
})
export class AgentsFilterComponent implements OnInit {
  @Input() public dataSource: Object[] = [];

  @ViewChild('querybuilder') querybuilder!: QueryBuilderComponent;

  public questionOperators = [
    { value: 'equal', key: 'Gleich' },
    { value: 'notequal', key: 'Nicht gleich' }
  ];

  public levelOperators = [
    { value: 'equal', key: 'Gleich' },
    { value: 'notequal', key: 'Nicht gleich' },
    { value: 'equalorbetter', key: 'Gleich oder besser' }
  ];

  public questionTemplate: TemplateColumn = this.createTemplateColumnForEnum('Question', ['Ja', 'Nein']);
  public languageLevelTemplate: TemplateColumn = this.createTemplateColumnForEnum('Level', ['Schlecht', 'Mittel', 'Gut']);
  public outboundExperienceLevelTemplate: TemplateColumn = this.createTemplateColumnForEnum('Level', ['Keine', 'Mittel', 'Gut']);

  public columns: ColumnsModel[] = [
    { field: 'status', label: 'Status', type: 'string' },
    { field: 'previousStatus', label: 'Vorheriger Status', type: 'string' },
    { field: 'partnerName', label: 'Partner', type: 'string', operators: this.questionOperators, template: undefined },
    { field: 'salutation', label: 'Anrede', type: 'string', values: ['Herr','Frau','Divers'] },
    { field: 'firstname', label: 'Vorname', type: 'string' },
    { field: 'lastname', label: 'Nachname', type: 'string' },
    { field: 'birthday', label: 'Geburtstag', type: 'string' },
    { field: 'phoneNumber', label: 'Telefonnummer', type: 'string' },
    { field: 'emailAddress', label: 'Mailadresse', type: 'string' },
    { field: 'city', label: 'Stadt', type: 'string' },
    { field: 'country', label: 'Land', type: 'string' },
    { field: 'noticePeriod', label: 'Kündigungsfrist', type: 'string' },
    { field: 'availableFrom', label: 'Ab Wann Verfügbar', type: 'string' },
    { field: 'experiences', label: 'Erfahrungen', type: 'string' },
    { field: 'b2bCapable', label: 'B2B Fähig', type: 'string', operators: this.questionOperators, template: this.questionTemplate },
    { field: 'germanLevel', label: 'Deutsch', type: 'string', operators: this.levelOperators, template: this.languageLevelTemplate },
    { field: 'englishLevel', label: 'Englisch', type: 'string', operators: this.levelOperators, template: this.languageLevelTemplate },
    { field: 'outboundExperienceLevel', label: 'Outbound Erfahrung', type: 'string', operators: this.levelOperators, template: this.outboundExperienceLevelTemplate }
  ];

  public constructor(private readonly partnersService: PartnersService) {
  }

  public ngOnInit(): void {
    this.partnersService.fetchAll(() => this.columns[2].template = this.createTemplateColumnForEnum('Partner', [...this.partnersService.partnerNames]));
  }

  private createTemplateColumnForEnum(name: string, values: string[]): TemplateColumn {
    return {
      create: () => {
        return createElement('input', { attrs: { type: 'text' } });
      },
      destroy: (args: { elementId: string }) => {
        const dropDown: DropDownList = getComponent(document.getElementById(args.elementId) as HTMLElement, 'dropdownlist') as DropDownList;
        if (dropDown) {
          dropDown.destroy();
        }
      },
      write: (args: { elements: Element, values: string[] | string, operator: string }) => {
        const dropDown: DropDownList = new DropDownList({
          dataSource: values,
          value: args.values as string,
          placeholder: `${name} auswählen`,
          change: (e: any) => {
            this.querybuilder.notifyChange(e.itemData.value, e.element);
          }
        });
        dropDown.appendTo('#' + args.elements.id);
      }
    };
  }

  public getFilter(): RuleModel {
    return this.querybuilder.getValidRules();
  }
}
