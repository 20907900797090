import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public config: Config;

  public baseUrl: string;

  public socketUrl: string;

  public linkUrl: string;

  public constructor() {
    this.config = {
      protocol: environment.protocol,
      domain: environment.domain,
      port: environment.port,
      apiPrefix: environment.apiPrefix,
      keycloakUrl: environment.keycloakUrl,
      keycloakClient: environment.keycloakClient
    };
    const usePort = this.config.domain === 'localhost' || this.config.domain.startsWith('1');
    const port = `:${this.config.port}`;
    this.baseUrl = `${this.config.protocol}://${this.config.domain}${usePort ? port : ''}/${this.config.apiPrefix}`;
    this.socketUrl = `${this.config.protocol.endsWith('s') ? 'wss' : 'ws'}://${this.config.domain}${usePort ? port : ''}`;
    this.linkUrl = `${this.config.protocol}://${this.config.domain}${usePort ? ':4200' : ''}`;
  }
}

export interface Config {
  protocol: string;
  domain: string;
  port: string;
  apiPrefix: string;
  keycloakUrl: string;
  keycloakClient: string;
}
