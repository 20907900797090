<div class="AgentView">
  <div class="close-btn ms-auto mb-3" style="margin-right: -8px;">
    <span class="d-block" style="color: rgb(102, 102, 102);" (click)="closeDailog()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <form [formGroup]="userForm" (ngSubmit)="onClickSave(userForm)" class="w-100">
    <div class="projectContent d-flex justify-content-between align-items-start gap-3 overflow-y-auto">
      <!-- <div class="agent-profile-wrapper mb-4">
        <div class="agent-profile">
          <img [src]="thumbnailImageSrc" alt="Project thumbnail image" class="h-100 w-100">
        </div>
        <div class="d-flex align-items-center justify-content-center" *ngIf="currentRole !== 'Partner'"
          style="gap: 6px; max-width: 188px; width: 100%; padding: 0 6px; border: 1px solid #e0e0e0; border-radius: 5px; cursor: pointer;">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          <input type="file" class="file-upload" (change)="chooseProfilePhoto($event)" accept="image/*" capture="user">
        </div>
      </div> -->

      <div class="user-dsetails-wrapper w-100">
        <!-- USER FORM START -->

        <!-- ** USER NAME ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
              <circle cx="12" cy="8" r="5" fill="currentColor" />
            </svg>
            <p class="mb-0">Username</p>
          </div>
          <div class="user-input">
            <input type="text" name="username" id="username" class="user-form-control" formControlName="username" [readOnly]="isForUpdate">
          </div>
        </div>

        <!-- ** FIRST NAME ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
              class="flex-shrink-0">
              <path fill="currentColor"
                d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
              <circle cx="12" cy="8" r="5" fill="currentColor" />
            </svg>
            <p class="mb-0 label">First name</p>
          </div>
          <div class="user-input">
            <input type="text" name="fname" id="fname" class="user-form-control" formControlName="firstName">
          </div>
        </div>

        <!-- ** LAST NAME ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
              <circle cx="12" cy="8" r="5" fill="currentColor" />
            </svg>
            <p class="mb-0">Last name</p>
          </div>
          <div class="user-input">
            <input type="text" name="lname" id="lname" class="user-form-control" formControlName="lastName">
          </div>
        </div>

        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.54 5.54 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13zM0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20zm24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9z" />
            </svg>
            <p class="mb-0">Select user</p>
          </div>
          <div class="Layout234 user-input">
            <ng-container *ngIf="!isForUpdate; else readonlyDropdown">
              <select aria-label="Default select example" class="form-select" formControlName="groups">
                <option value="Admins">Admin</option>
                <option value="Clients">Client</option>
                <option value="Partners">Partner</option>
              </select>
            </ng-container>
            <ng-template #readonlyDropdown>
              <input type="text" class="user-form-control" [value]="userForm.get('groups')?.value" readonly />
            </ng-template>
          </div>
        </div>
        

        @if (userForm.value.groups === 'Clients') {
          <!-- ** IS NEW CUSTOMER? ** -->
          <div class="d-flex align-items-center gap-2 mb-3">
            <div class="d-flex align-items-center gap-1 label">
              <p class="mb-0">Neuer Kunde?</p>
            </div>
            <div class="Layout234 user-input">
              <input type="checkbox" (change)="toggleCreateNewCustomer()">
            </div>
          </div>

          @if (createNewCustomer) {
            <!-- ** ENTER CUSTOMER ** -->
            <div class="d-flex align-items-center gap-2 mb-3">
              <div class="d-flex align-items-center gap-1 label">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path fill="currentColor"
                    d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
                  <circle cx="12" cy="8" r="5" fill="currentColor" />
                </svg>
                <p class="mb-0">Kunde</p>
              </div>
              <div class="user-input">
                <input type="text" name="customerName" class="user-form-control" formControlName="customerName">
              </div>
            </div>
          }

          @if (!createNewCustomer) {
            <!-- ** SELECT CUSTOMER ** -->
            <div class="d-flex align-items-center gap-2 mb-3">
              <div class="d-flex align-items-center gap-1 label">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path fill="currentColor"
                    d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
                  <circle cx="12" cy="8" r="5" fill="currentColor" />
                </svg>
                <p class="mb-0">Kunde</p>
              </div>
              <div class="Layout234 user-input">
                <select aria-label="Default select example" class="form-select" formControlName="customerName">
                  <option *ngFor="let customer of customers" [value]="customer">{{customer}}</option>
                </select>
              </div>
            </div>
          }
        }

        <!-- ** EMAIL ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z" />
            </svg>
            <p class="mb-0">Email</p>
          </div>
          <div class="Layout234567 user-input">
            <input type="email" name="mail" id="mail" class="user-form-control" formControlName="email">
          </div>
        </div>

        <!-- ** PASSWORD ** -->
        <div class="d-flex align-items-center gap-2 mb-3" *ngIf="!isForUpdate">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2m6 3V10H6v10zm0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2zm-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3" />
            </svg>
            <p class="mb-0">Password</p>
          </div>
          <div class="user-input">
            <input type="password" name="pwd" id="pwd" class="user-form-control" formControlName="password">
          </div>
        </div>

        <!-- ** ENABLE ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
              <circle cx="12" cy="8" r="5" fill="currentColor" />
            </svg>
            <p class="mb-0">Enable</p>
          </div>
          <div class="Layout234567">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" formControlName="enabled">
              <div class="toggle-switch"></div>
            </label>
          </div>
        </div>

        <!-- ** TEMPORARY PWD ** -->
        <div class="d-flex align-items-center gap-2 mb-3" *ngIf="!isForUpdate">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M12 17a2 2 0 0 1-2-2c0-1.11.89-2 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2m6 3V10H6v10zm0-12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10c0-1.11.89-2 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2zm-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3" />
            </svg>
            <p class="mb-0">Temporary password</p>
          </div>
          <div class="Layout234567">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" formControlName="temporary">
              <div class="toggle-switch"></div>
            </label>
          </div>
        </div>

        <!-- ** EMAIL VARIFIED ** -->
        <div class="d-flex align-items-center gap-2 mb-3">
          <div class="d-flex align-items-center gap-1 label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path fill="currentColor"
                d="M13 19c0-3.31 2.69-6 6-6c1.1 0 2.12.3 3 .81V6a2 2 0 0 0-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h9.09c-.05-.33-.09-.66-.09-1M4 8V6l8 5l8-5v2l-8 5zm13.75 14.16l-2.75-3L16.16 18l1.59 1.59L21.34 16l1.16 1.41z" />
            </svg>
            <p class="mb-0">Email verified</p>
          </div>
          <div class="Layout234567">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" formControlName="emailVerified">
              <div class="toggle-switch"></div>
            </label>
          </div>
        </div>

        <!-- USER FORM END -->
      </div>
    </div>
    <!-- BOTTOM ROW BUTTONS START -->
    <div class="bottomRow">

      <button #thisbutton_cssbutton type="button" class="cssButton" (click)="closeDailog()">
        <div class="focus:outline-0 bg-transparent">
          <span> Abbrechen </span>
        </div>
      </button>

      <button #thisbutton_cssbutton2 type="submit" class="cssButton2">
        <div class="focus:outline-0 bg-transparent">
          <span> {{isForUpdate?'Aktualisieren':'Speichern'}} </span>
        </div>
      </button>

    </div>
    <!-- BOTTOM ROW BUTTONS END -->
  </form>
</div>
