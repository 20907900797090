<div class="AgentView">
  <div class="close-btn ms-auto mb-3" style="margin-right: -8px;" (click)="dialogService.showNewProjectDialog = false">
    <span class="d-block" style="color: rgb(102, 102, 102);">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <div class="projectContent d-flex justify-content-between align-items-start gap-3 overflow-y-auto">
    <div class="w-100">
      <div class="agent-profile-wrapper mb-4">
        <div class="agent-profile">
          <img [src]="currentRole === 'Partner'?defaultthumbnailImageSrc:thumbnailImageSrc" alt="Project thumbnail image" class="h-100 w-100 object-fit-contain">
        </div>
        <div class="d-flex align-items-center justify-content-center" *ngIf="currentRole !== 'Partner'"
          style="gap: 6px; max-width: 188px; width: 100%; padding: 2px 6px; border: 1px solid #e0e0e0; border-radius: 5px; cursor: pointer;">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          <input type="file" class="file-upload w-100" (change)="chooseProfilePhoto($event)" accept="image/*" capture="user">
        </div>
      </div>
      <div class="heading d-flex align-items-center gap-2" style="color: #666666;">
        <div class="d-flex align-items-center justify-content-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path></svg>
        </div>
        <div class="richText2">
          <div><span style="color: rgb(102, 102, 102)">Projektname*</span></div>
        </div>
        <div style="margin-left: 22px;">
          <h1 class="mb-0" style="font-weight: bolder;" *ngIf="!isEditable && selectedProject">
            {{ projectTitle }}
          </h1>
          <input type="text" id="projectName" class="project-name form-control" placeholder="Projektname" [(ngModel)]="projectTitle" *ngIf="(!isEditable && !selectedProject) || (isEditable && selectedProject)">
        </div>
        <div class="edit-icon" style="cursor: pointer;" (click)="toggleEditable()" *ngIf="selectedProject && !isEditable && currentRole !== 'Partner'">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="18.002" xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="3309 3860.998 18.002 18.002" height="20" width="20">
            <g id="shape-784c5983-b509-808f-8003-ecb97e122a5a">
              <g class="fills" id="fills-784c5983-b509-808f-8003-ecb97e122a5a">
                <path rx="0" ry="0" style="fill:#333333;fill-opacity:1"
                  d="M3309.000,3875.460L3309.000,3878.500C3309.000,3878.780,3309.220,3879.000,3309.500,3879.000L3312.540,3879.000C3312.670,3879.000,3312.800,3878.950,3312.890,3878.850L3323.810,3867.940L3320.060,3864.190L3309.150,3875.100C3309.050,3875.200,3309.000,3875.320,3309.000,3875.460ZM3326.710,3865.040C3326.897,3864.853,3327.002,3864.600,3327.002,3864.335C3327.002,3864.070,3326.897,3863.817,3326.710,3863.630L3324.370,3861.290C3324.183,3861.103,3323.930,3860.998,3323.665,3860.998C3323.400,3860.998,3323.147,3861.103,3322.960,3861.290L3321.130,3863.120L3324.880,3866.870Z">
                </path>
              </g>
            </g>
          </svg>
        </div>
        <div class="d-flex align-items-center justify-content-center" style="color: #333333; cursor: pointer;" *ngIf="isEditable && selectedProject" (click)="toggleEditable()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path></svg>
        </div>
      </div>
      <!-- <div class="subHeading">
        <p>Beispiel GmbH</p>
      </div> -->
      <div class="detailArea">
        <div class="leftSide">
          <div class="Layout2" *ngIf="currentRole !== 'Partner'">
            <div class="richText23">
              <div class="d-flex align-items-center justify-content-center">
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust:exact" id="screenshot-784c5983-b509-808f-8003-ecb88b7868ba" version="1.1" viewBox="3003 2149 24 24" height="24">
                  <g id="shape-784c5983-b509-808f-8003-ecb88b7868ba" style="fill:#000000" width="1em" height="1em" rx="0" ry="0">
                    <g id="shape-784c5983-b509-808f-8003-ecb88b78e4f5">
                      <g class="fills" id="fills-784c5983-b509-808f-8003-ecb88b78e4f5">
                        <path rx="0" ry="0" style="fill:#6c6c6c;fill-opacity:1" d="M3021.000,2164.000L3019.000,2164.000L3019.000,2166.000L3021.000,2166.000M3021.000,2160.000L3019.000,2160.000L3019.000,2162.000L3021.000,2162.000M3023.000,2168.000L3015.000,2168.000L3015.000,2166.000L3017.000,2166.000L3017.000,2164.000L3015.000,2164.000L3015.000,2162.000L3017.000,2162.000L3017.000,2160.000L3015.000,2160.000L3015.000,2158.000L3023.000,2158.000M3013.000,2156.000L3011.000,2156.000L3011.000,2154.000L3013.000,2154.000M3013.000,2160.000L3011.000,2160.000L3011.000,2158.000L3013.000,2158.000M3013.000,2164.000L3011.000,2164.000L3011.000,2162.000L3013.000,2162.000M3013.000,2168.000L3011.000,2168.000L3011.000,2166.000L3013.000,2166.000M3009.000,2156.000L3007.000,2156.000L3007.000,2154.000L3009.000,2154.000M3009.000,2160.000L3007.000,2160.000L3007.000,2158.000L3009.000,2158.000M3009.000,2164.000L3007.000,2164.000L3007.000,2162.000L3009.000,2162.000M3009.000,2168.000L3007.000,2168.000L3007.000,2166.000L3009.000,2166.000M3015.000,2156.000L3015.000,2152.000L3005.000,2152.000L3005.000,2170.000L3025.000,2170.000L3025.000,2156.000Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="richText22">
              <div><span style="color: rgb(102, 102, 102)">Kunde*</span></div>
            </div>
          </div>
          <div class="Layout">
            <div class="richText">
              <div>
                <img src="https://api.iconify.design/material-symbols/support-agent.svg?color=%23666" alt="" width="22"
                  height="22" />
              </div>
            </div>
            <div class="richText2">
              <div><span style="color: rgb(102, 102, 102)">Agent Anzahl*</span></div>
            </div>
          </div>
          <div class="Layout2">
            <div class="richText23">
              <div>
                <img src="https://api.iconify.design/bx/calendar.svg?color=%23666" alt="" width="22" height="22" />
              </div>
            </div>
            <div class="richText22">
              <div><span style="color: rgb(102, 102, 102)">Start Datum</span></div>
            </div>
          </div>
          <div class="Layout22">
            <div class="Layout2345">
              <div class="richText232">
                <div>
                  <img src="https://api.iconify.design/iconamoon/clock.svg?color=%23666" alt="" width="22"
                    height="22" />
                </div>
              </div>
              <div class="richText222">
                <div>
                  <span style="color: rgb(102, 102, 102)">Arbeitszeiten</span>
                </div>
              </div>
            </div>
          </div>
          <div class="Layout2">
            <div class="richText23">
              <div>
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="17.256" xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust:exact" id="screenshot-784c5983-b509-808f-8003-ecb793918c4b" version="1.1" viewBox="3007.667 2586.5 17.256 16.178" height="16.178">
                  <g id="shape-784c5983-b509-808f-8003-ecb793918c4b">
                    <g class="fills" id="fills-784c5983-b509-808f-8003-ecb793918c4b">
                      <path rx="0" ry="0" style="fill:#6c6c6c;fill-opacity:1" d="M3009.333,2588.167L3009.333,2599.833L3016.833,2599.833L3016.833,2601.500L3008.500,2601.500C3008.040,2601.500,3007.667,2601.127,3007.667,2600.667L3007.667,2587.333C3007.667,2586.873,3008.040,2586.500,3008.500,2586.500L3023.500,2586.500C3023.960,2586.500,3024.333,2586.873,3024.333,2587.333L3024.333,2591.500L3022.667,2591.500L3022.667,2588.167ZM3022.422,2593.411L3021.833,2592.822L3021.244,2593.411L3018.744,2595.911L3019.922,2597.089L3021.833,2595.178L3023.744,2597.089L3024.922,2595.911ZM3019.922,2598.411L3021.833,2600.322L3023.744,2598.411L3024.922,2599.589L3022.422,2602.089L3021.833,2602.678L3021.244,2602.089L3018.744,2599.589Z">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="richText22">
              <div><span style="color: rgb(102, 102, 102)">In-/Outbound*</span></div>
            </div>
          </div>
          <div class="Layout222">
            <div class="richText2322">
              <div>
                <img src="https://api.iconify.design/fluent/text-description-20-filled.svg?color=%23666" alt=""
                  width="22" height="22" />
              </div>
            </div>
            <div class="richText2222">
              <div><span style="color: rgb(102, 102, 102)">Beschreibung*</span></div>
            </div>
          </div>
          <div class="Layout2223">
            <div class="richText23223">
              <div>
                <img src="https://api.iconify.design/pajamas/issue-type-requirements.svg?color=%23666" alt="" width="22"
                  height="22" />
              </div>
            </div>
            <div class="richText22223">
              <div>
                <span style="color: rgb(102, 102, 102)">Anforderungen*</span>
              </div>
            </div>
          </div>

          <div class="Layout222">
            <div class="richText2322">
              <div style="color: #666666;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M20 2C20.5523 2 21 2.44772 21 3V6.757L12.0012 15.7562L11.995 19.995L16.2414 20.0012L21 15.242V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761ZM12 12H7V14H12V12ZM15 8H7V10H15V8Z"></path></svg>
              </div>
            </div>
            <div class="richText2222">
              <div><span style="color: rgb(102, 102, 102)">Konditionen*</span></div>
            </div>
          </div>

          <div class="Layout2223" *ngIf="currentRole === 'Client'">
            <div class="richText23223">
              <div>
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24"
                  xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="3004 2880 24 24" height="24">
                  <g id="shape-784c5983-b509-808f-8003-ecb793918c90" style="fill:#000000" width="1em" height="1em"
                    rx="0" ry="0">
                    <g id="shape-784c5983-b509-808f-8003-ecb793918c92">
                      <g class="fills" id="fills-784c5983-b509-808f-8003-ecb793918c92">
                        <path rx="0" ry="0" style="fill:#6c6c6c;fill-opacity:1"
                          d="M3023.000,2893.586L3023.000,2890.000C3023.000,2886.783,3020.815,2884.073,3017.855,2883.258C3017.562,2882.520,3016.846,2882.000,3016.000,2882.000C3015.154,2882.000,3014.438,2882.520,3014.145,2883.258C3011.185,2884.074,3009.000,2886.783,3009.000,2890.000L3009.000,2893.586L3007.293,2895.293C3007.105,2895.480,3007.000,2895.735,3007.000,2896.000L3007.000,2898.000C3007.000,2898.552,3007.448,2899.000,3008.000,2899.000L3024.000,2899.000C3024.552,2899.000,3025.000,2898.552,3025.000,2898.000L3025.000,2896.000C3025.000,2895.735,3024.895,2895.480,3024.707,2895.293ZM3023.000,2897.000L3009.000,2897.000L3009.000,2896.414L3010.707,2894.707C3010.895,2894.520,3011.000,2894.265,3011.000,2894.000L3011.000,2890.000C3011.000,2887.243,3013.243,2885.000,3016.000,2885.000C3018.757,2885.000,3021.000,2887.243,3021.000,2890.000L3021.000,2894.000C3021.000,2894.266,3021.105,2894.520,3021.293,2894.707L3023.000,2896.414ZM3016.000,2902.000C3017.269,2902.002,3018.401,2901.199,3018.818,2900.000L3013.182,2900.000C3013.599,2901.199,3014.731,2902.002,3016.000,2902.000Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="richText22223">
              <div>
                <span style="color: rgb(102, 102, 102)">Aktualisierungen per E-Mail</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rightSide">
          <div class="Layout234" *ngIf="currentRole === 'Admin'">
            <select class="form-select" [(ngModel)]="selectedCustomerName">
              <option *ngFor="let customerName of customersService.customerNames" [value]="customerName">{{customerName}}</option>
            </select>
          </div>
          <div class="Layout234" *ngIf="currentRole === 'Client'">
            {{ selectedCustomerName }}
          </div>
          <div class="Layout23">
            <div class="richText234">
              <div>
                <input #thisinput_numberInput [id]="prefix + 'numberInput'" class="timeInput" type="number"
                  [(ngModel)]="numberOfAgentsRequested"
                  [value]="selectedProject ? selectedProject.numberOfAgentsRequested : 1" min="1"
                  [style.backgroundcolor]="'#ffffff'" [readOnly]="currentRole === 'Partner'">
              </div>
            </div>
          </div>
          <div class="Layout234">
              <mat-form-field appearance="outline" class="cust-mat-cal">
                <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" [disabled]="currentRole === 'Partner'">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="d-flex align-items-start gap-4">
            <div class="dritteZeile">
              <div class="tagesZeile">
                <div class="Layout2345678">
                  <div class="richText2345">
                    <div>Montag*</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput2'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="mondayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText23456">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="mondayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile2">
                <div class="Layout23456782">
                  <div class="richText23452">
                    <div>Dienstag*</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="tuesdayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText234562">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="tuesdayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile23">
                <div class="Layout234567823">
                  <div class="richText234523">
                    <div>Mittwoch*</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="wednesdayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText2345623">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="wednesdayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile232">
                <div class="Layout2345678232">
                  <div class="richText2345232">
                    <div>Donnerstag*</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="thursdayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText23456232">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="thursdayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile22">
                <div class="Layout234567822">
                  <div class="richText234522">
                    <div>Freitag*</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="fridayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText2345622">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="fridayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile222">
                <div class="Layout2345678222">
                  <div class="richText2345222">
                    <div>Samstag</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="saturdayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText23456222">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="saturdayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
              <div class="tagesZeile2222">
                <div class="Layout23456782222">
                  <div class="richText23452222">
                    <div>Sonntag</div>
                  </div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="sundayStart" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
                <div class="richText23456222">
                  <div>bis</div>
                </div>
                <ejs-timepicker [id]="prefix + 'cssInput22'" placeholder='Zeit auswählen' [step]='interval'
                  [format]='formatString' [(ngModel)]="sundayEnd" [readonly]="currentRole === 'Partner'"></ejs-timepicker>
              </div>
            </div>
            <div class="adopt-time-wrapper d-flex align-items-center justify-content-between gap-2" style="cursor: pointer;" (click)="adoptTime()" [ngClass]="{disabled: mondayStart == '' || mondayEnd == '' || currentRole === 'Partner'}">
              <div class="d-flex align-items-center justify-content-center">
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg" id="screenshot-784c5983-b509-808f-8003-ecb793918c96" version="1.1" viewBox="3486 2320.571 24 22.857" height="24">
                  <g id="shape-784c5983-b509-808f-8003-ecb793918c96" style="fill:#000000" width="1em" height="1em" rx="0" ry="0">
                    <g id="shape-784c5983-b509-808f-8003-ecb793918c9a">
                      <g class="fills" id="fills-784c5983-b509-808f-8003-ecb793918c9a">
                      <path rx="0" ry="0" style="fill-opacity:1" [ngStyle]="{'fill': (mondayStart == '' || mondayEnd == '' || currentRole === 'Partner') ? '#b3b3b3' : '#24b89b'}"
                      d="M3506.000,2336.762L3509.000,2336.762L3505.000,2340.571L3501.000,2336.762L3504.000,2336.762L3504.000,2323.429L3506.000,2323.429ZM3494.000,2325.333C3490.140,2325.333,3487.000,2328.314,3487.000,2332.000C3487.000,2335.686,3490.130,2338.667,3494.000,2338.667C3497.860,2338.667,3501.000,2335.686,3501.000,2332.000C3501.000,2328.314,3497.870,2325.333,3494.000,2325.333ZM3494.000,2327.381C3496.670,2327.381,3498.850,2329.448,3498.850,2332.000C3498.850,2334.552,3496.680,2336.619,3494.000,2336.619C3491.320,2336.619,3489.150,2334.552,3489.150,2332.000C3489.150,2329.448,3491.320,2327.381,3494.000,2327.381ZM3493.000,2329.143L3493.000,2332.657L3496.190,2334.410L3496.940,2333.171L3494.500,2331.829L3494.500,2329.143">
                      </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="mb-0">Uhrzeit übernehmen</p>
            </div>
           </div>
           <div class="Layout234">
            <select class="form-select" aria-label="Default select example" [(ngModel)]="boundValue" [disabled]="currentRole === 'Partner'">
              <option [value]="'Inbound'">Inbound</option>
              <option [value]="'Outbound'">Outbound</option>
              <option [value]="'Inbound/Outbound'">Inbound/Outbound</option>
            </select>
          </div>
          <div class="Layout23456">
            <textarea #thisinput_textarea1 [id]="prefix + 'textarea1'" class="textarea"
              [value]="selectedProject ? selectedProject.taskDescription : ''" [(ngModel)]="taskDescription"
              (input)="thisinput_textarea1.value" [style.backgroundcolor]="'#ffffff'" [readOnly]="currentRole === 'Partner'"></textarea>
          </div>
          <div class="Layout234567">
            <textarea #thisinput_textarea2 [id]="prefix + 'textarea2'" class="textarea"
              [value]="selectedProject ? selectedProject.requirements : ''" [(ngModel)]="requirements"
              (input)="thisinput_textarea2.value" [style.backgroundcolor]="'#ffffff'" [readOnly]="currentRole === 'Partner'"></textarea>
          </div>
          <div class="Layout234567">
            <textarea #thisinput_textarea2 [id]="prefix + 'textarea2'" class="textarea"
              [value]="selectedProject ? selectedProject.conditions : ''" [(ngModel)]="conditions"
              (input)="thisinput_textarea2.value" [style.backgroundcolor]="'#ffffff'" [readOnly]="currentRole === 'Partner'"></textarea>
          </div>
          <div class="Layout234567" *ngIf="currentRole === 'Client'">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" [(ngModel)]="emailUpdates">
              <div class="toggle-switch"></div>
              <span class="toggle-label">Tägliche Benachrichtigungen</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="assign-partner-wrapper" *ngIf="currentRole === 'Admin'">
      <div class="mb-2 border-bottom" style="padding-bottom: 10px;">
        <p class="assigned-partner mb-0 text-blacklh-base d-inline-block">{{selectedPartnersList.length}} Partner zugewiesen</p>
      </div>
      <div class="all-assign-partners border-bottom overflow-y-auto">
        <p *ngIf="!selectedPartnersList.length" style="color: #b3b3b3">Kein Partner ausgewählt</p>
        <div class="partner-name d-flex align-items-center justify-content-between gap-1" *ngFor="let partner of selectedPartnersList; let i = index">
          <p class="mb-0">{{ partner }}</p>
          <span style="cursor: pointer;" (click)="removePartner(i)" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="#212529">
              <path
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM7 11H17V13H7V11Z">
              </path>
            </svg>
          </span>
        </div>
      </div>
      <div class="all-partners">
        <div class="my-2">
          <div class="partner-name d-flex align-items-center justify-content-between gap-1" *ngFor="let partner of partnersList; let i = index">
            <p class="mb-0">{{ partner }}</p>
            <span style="cursor: pointer;" (click)="addPartner(i)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="#212529">
                <path
                  d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z">
                </path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottomRow">

    <button #thisbutton_cssbutton type="button" class="cssButton" (click)="dialogService.showNewProjectDialog = false">
      <div class="focus:outline-0 bg-transparent">
        <span> Abbrechen </span>
      </div>
    </button>

    <button #thisbutton_cssbutton2 type="button" class="cssButton2" (click)="saveProject()" *ngIf="currentRole !== 'Partner'">
      <div class="focus:outline-0 bg-transparent">
        <span> Speichern </span>
      </div>
    </button>

  </div>
</div>
