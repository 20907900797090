import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { BookableTimeSlot } from '../models/time-slot.model';

@Injectable({
  providedIn: 'root'
})
export class BookableTimeSlotsService {
  private apiUrl = '';

  constructor(private readonly configService: ConfigService, private readonly http: HttpClient) {
    this.apiUrl = `${this.configService.baseUrl}/bookable-time-slots`;
  }

  getBookableTimeSlots() {
    return this.http.get<BookableTimeSlot[]>(`${this.apiUrl}`);
  }

  createBookableTimeSlots(slot: BookableTimeSlot) {
    return this.http.post<BookableTimeSlot>(`${this.apiUrl}`, slot);
  }

  getBookableTimeSlotById(id: number) {
    return this.http.get<BookableTimeSlot>(`${this.apiUrl}/${id}`);
  }

  getBookableTimeSlotByClientAndProjectId(clientId: string, projectId: number) {
    return this.http.get<BookableTimeSlot>(`${this.apiUrl}/clientId/${clientId}/${projectId}`);
  }

  updateBookableTimeSlotById(id: number, updatedSlot: BookableTimeSlot) {
    return this.http.put<BookableTimeSlot>(`${this.apiUrl}/${id}`, updatedSlot);
  }

  deleteBookableTimeSlotById(id: number) {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getAllBookableTimeSlotsByProjectId(projectId: number) {
    return this.http.get<BookableTimeSlot[]>(`${this.apiUrl}/getTimeSlotByprojectId/${projectId}`);
  }
}
