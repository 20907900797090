<div class="App">
  <div class="loginCard">
    <div class="smyloLogo">
      <p><img
          src="https://github.com/Photonic-Codes-GmbH/resource-collection/blob/main/smylo%20logo%20transparent%20(1).png?raw=true"
          alt="" width="220" height="124"></p>
    </div>
    <div class="heading">
      <p>Geben Sie Ihre E-Mail und Ihr Passwort ein.</p>
    </div>
    <input #thisinput_emailfield [id]="prefix + 'emailField'" class="emailField" type="email"
      [(ngModel)]="userEmail" (input)="thisinput_emailfield.value">
    <div *ngIf="showError">
      <p>Error: Invalid email</p>
    </div>

    <input #thisinput_passwordfield [id]="prefix + 'passwordField'" class="passwordField" type="password"
      [value]="'Password123'" (input)="thisinput_passwordfield.value">

    <button #thisbutton_weiterbutton type="button" class="weiterButton" (click)="login()">
      <div class="focus:outline-0 bg-transparent">
        <span> Weiter </span>
      </div>
    </button>

    <div class="lastRow">
      <div class="richText">
        <p><span style="text-decoration: underline;">Passwort vergessen?</span></p>
      </div>
      <div class="richText23">
        <p><span style="text-decoration: underline;">Probleme beim Login?</span></p>
      </div>
    </div>
  </div>
</div>
