import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project, ProjectThumbnailPhoto } from '../models/project.model';
import { ConfigService } from 'src/app/config.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private apiUrl = '';

  constructor(private readonly configService: ConfigService, private readonly http: HttpClient) {
    this.apiUrl = `${this.configService.baseUrl}/projects`;
  }

  createProject(project: Project) {
    return this.http.post<Project>(`${this.apiUrl}`, project);
  }

  getAllProjects(userId: string, role: string): Observable<Project[]> {
    const headers: any = {
      role,
    };
    if (userId && userId != '') {
      headers['userId'] = userId;
    }
    return this.http.get<Project[]>(`${this.apiUrl}`, {
      headers,
    });
  }

  getProjectById(id: number): Observable<Project> {
    return this.http.get<Project>(`${this.apiUrl}/${id}`);
  }

  updateProject(id: number, project: Project): Observable<Project> {
    const request = this.http.patch<Project>(`${this.apiUrl}/${id}`, project);
    request.subscribe({
      next: (response) => console.log('PUT request successful, response:', response),
      error: (error) => console.error('PUT request failed, error:', error),
    });
    return request;
  }

  partiallyUpdateProject(id: number, project: Partial<Project>): Observable<Project> {
    const request = this.http.patch<Project>(`${this.apiUrl}/${id}`, project);
    request.subscribe({
      next: (response) => console.log('PATCH request successful, response:', response),
      error: (error) => console.error('PATCH request failed, error:', error),
    });
    return request;
  }

  deleteProject(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  setProjectThumbnailPhoto(id: number, projectThumbnailPhoto: any, isEdit: boolean) {
    return this.http.post<ProjectThumbnailPhoto>(`${this.apiUrl}/${id}/set-agent-profile-photo/${isEdit}`, projectThumbnailPhoto);
  }
}
