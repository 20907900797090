import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ConfigService } from '../../config.service';
import { Customer } from '../models/customer.model';
import { AdminService } from './admin.service';
import { DialogService } from 'src/app/dialogservice.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private apiUrl = '';

  public constructor(
    private readonly configService: ConfigService,
    private readonly http: HttpClient,
    private readonly adminService: AdminService,
    public readonly dialogService: DialogService,
    private readonly snackBar: MatSnackBar
  ) {
    this.apiUrl = `${this.configService.baseUrl}/customers`;
  }

  public findAll() {
    return this.http.get<Customer[]>(this.apiUrl);
  }

  public findOne(id: string) {
    return this.http.get<Customer>(`${this.apiUrl}/${id}`);
  }

  public create(customer: Customer) {
    return this.http.post<Customer>(this.apiUrl, customer);
  }

  public update(customer: Customer) {
    return this.http.put<Customer>(`${this.apiUrl}/${customer.id}`, customer);
  }

  public delete(id: string) {
    return this.http.delete(`${this.apiUrl}/${id}`);
  }

  public deactivate(customer: Customer) {
    return this.http.post(`${this.apiUrl}/names/${customer.customerName}/deactivate`, {});
  }

  public reactivate(customer: Customer) {
    return this.http.post(`${this.apiUrl}/names/${customer.customerName}/reactivate`, {});
  }

  // Functionality

  public users: any = [];
  public customers: Customer[] = [];
  public customerAssignments: { customer: Customer, user: any }[] = [];
  public customerNames: string[] = [];

  public isForUpdate: boolean = false;

  public change(event: any): void {
    this.dialogService.showNewCustomerDialog = false;

    console.log('Change for Customers', event, this.isForUpdate);

    if (this.isForUpdate) {
      this.update(event).subscribe((customer: Customer) => {
        const index = this.customers.findIndex((c: Customer) => c.id === customer.id);
        this.customers[index] = customer;
        this.fetchAll(() => {});
      });
    } else {
      this.create(event).subscribe({
        next: (customer: Customer) => {
          this.customers.push(customer);
          this.fetchAll(() => {});
        },
        error: (error: any) => {
          let message = 'Kunde kann nicht erstellt werden';

          this.snackBar.open(message, '', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      });
    }
  }

  public fetchAll(fn: () => void): void {
    this.adminService.getAllKeyCloakUserGroupVise('Clients').subscribe((users: any) => {
      this.users = users;

      this.findAll().subscribe((customers: Customer[]) => {
        this.customers = customers;
        this.recreate();

        fn();
      });
    });
  }

  private recreate(): void {
    this.customerAssignments = this.customers.map((customer: Customer) => {
      const assignedUser = this.users.find((user: any) => user.id === customer.userId);
      return {
        customer: customer,
        user: assignedUser
      };
    });

    this.customerAssignments.sort((a, b) => {
      if (a.customer.customerName < b.customer.customerName) {
        return -1;
      }
      if (a.customer.customerName > b.customer.customerName) {
        return 1;
      }
      return 0;
    });

    this.customerNames = this.customerAssignments.map((ca) => ca.customer.customerName);
    this.customerNames = this.customerNames.filter((value, index, self) => self.indexOf(value) === index);
  }
}
