import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { ProjectsService } from "../utils/services/projects.service";
import { ProjectDataCacheService } from "src/app/project-data-cache.service";
import { finalize, debounceTime } from "rxjs/operators";
import { DialogService } from "../dialogservice.service";
import { Project } from "../utils/models/project.model";
import { Router } from "@angular/router";
import { AgentsService } from "../utils/services/agents.service";
import { AgentStatus } from "../utils/models/agent.model";
import { KeycloakService } from "keycloak-angular";
import { SharedService } from "../utils/services/shared.service";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NewProjectComponent } from "../newProject/newProject.component";
import { SpinnerComponent } from "../spinner/spinner.component";
import { MaterialModule } from "../material.module";

@Component({
  selector: "app-projektübersicht",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NewProjectComponent,
    SpinnerComponent
  ],
  templateUrl: "./projektübersicht.component.html",
  styleUrls: ["./projektübersicht.component.scss"],
})
export class ProjektübersichtComponent implements OnInit {

  @Input()
  public prefix: string = "";

  public selectedProject: any = null;
  public selectedProjectId: number | null = null;
  public isLoading: boolean = false;
  public projects: any[] = [];
  currentUser: string = '';
  currentRole: string = '';
  currentUserId: string = '';
  isAdminLoggedIn: boolean = false;
  currentUserName:string = '';

  defaultthumbnailImageSrc =
    'https://github.com/Photonic-Codes-GmbH/resource-collection/blob/main/Test%20Logo.png?raw=true';
  thumbnailImageSrc = this.defaultthumbnailImageSrc;

  search = new FormControl('');
  filteredProjects: any[] = [];
  isSpinnerActive: boolean = false;

  public constructor(
    public readonly projectDataCacheService: ProjectDataCacheService,
    public dialogService: DialogService,
    public projectsService: ProjectsService,
    public router: Router,
    private agentsService: AgentsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly keycloak: KeycloakService,
    private sharedService: SharedService
  ) {}

  public ngOnInit(): void {
    this.isSpinnerActive = true;

    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    this.currentUserName = tokenData?.['name'];
    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];
    this.currentUserId = tokenData?.sub || '';

    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
      this.isAdminLoggedIn = true;
      this.changeDetectorRef.detectChanges();
    }

    this.projectsService
      .getAllProjects(this.currentUserId,this.currentRole)
      .pipe(
        // takeUntil(this.destroy$),
        finalize(() => (this.isSpinnerActive = false))
      )
      .subscribe({
        next: (projects) => {
          this.projects = projects;
          this.projects.forEach((project, index) => {
            this.projects[index].numberOfAcceptedAgents = 0;
            this.projects[index].numberOfSuggestedAgents = 0;
            if (this.projects[index].projectThumbnailPhoto) {
              this.projects[index].projectThumbnailPhoto = this.agentsService.convertImagetoUrl(project.projectThumbnailPhoto.photoBlob);
            }
            this.agentsService
              .getAgentByStatusForDashboard(this.currentRole == 'Partner'?this.currentUserId:'',project.id)
              .subscribe((agents:any) => {
                this.projects[index].numberOfAcceptedAgents = agents[AgentStatus.ApplicantConfirmed]?.length || 0;
                this.projects[index].numberOfSuggestedAgents = agents[AgentStatus.ApplicantGetsSuggested]?.length || 0;
              });
          });
          this.filteredProjects = this.projects;
        },
        error: (error) => {
          console.error('Error fetching projects: ', error);
        },
      });

      this.search.valueChanges
      .pipe(debounceTime(300))
      .subscribe((newValue: any) => {
        if (this.projects) {
          this.filterProjects(this.projects, newValue);
        }
      });
  }
  destroy$(destroy$: any) {
    throw new Error("Method not implemented.");
  }

  ngOnDestroy() {
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  selectProject(event: Event, id: number | null): void {
    event.stopPropagation();
    this.selectedProjectId = id
    this.dialogService.showNewProjectDialog = true
    if (id !== null)
      this.selectedProject = this.projects.find((p) => p.id === id)
    else this.selectedProject = null
  }

  navigateToSingleProjectView(projectId: number): void {
    this.router.navigate([`/singleProjectView/${projectId}`]);
  }

  getNumberOfAcceptedAgents(project: Project): number {

    return 0
  }

  updateProjectThumnail(updatedThumbnail: any) {
    const index = this.projects.indexOf(this.projects.find((project: any) => project.id == updatedThumbnail.projectId));
    this.projects[index].projectThumbnailPhoto = updatedThumbnail.thumbnailImageSrc;
  }

  filterProjects(projects: any[], newValue: string) {
    const searchValue = newValue.trim().toLowerCase();

    if (!searchValue) {
      this.filteredProjects = projects;
      return;
    }

    this.filteredProjects = projects.filter((project) => {
      const clientName = project.customer
        ? project.clientInfo.map((client: any) => `${client.firstName.toLowerCase()} ${client.lastName.toLowerCase()}`).join(' ')
        : '';

      return (
        project.name.toLowerCase().includes(searchValue) || clientName.includes(searchValue)
      );
    });
  }
}
