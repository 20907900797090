import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { ConfigService } from 'src/app/config.service';

interface RecordedVideoOutput {
  blob: Blob;
  url: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class RecordingService {

  private stream: any;
  private recorder:any;
  private interval:any;
  private startTime:any;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  private apiUrl = '';
  private agentApiUrl = '';
  private publicUrl = '';

  agentVoicesSubject = new BehaviorSubject([]);
  agentVoices$ = this.agentVoicesSubject.asObservable();
  isRecordingSubject = new BehaviorSubject(false);

  constructor(private readonly configService: ConfigService, private readonly http: HttpClient,  private snackBar: MatSnackBar) {
    this.apiUrl = `${this.configService.baseUrl}/recording`;
    this.agentApiUrl = `${this.configService.baseUrl}/agents`;
    this.publicUrl = `${this.configService.baseUrl}/agents/public`;
  }

  getRecordedBlob(): Observable<any> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }


  startRecording() {
    if (this.recorder) {
      return;
    }

    this._recordingTime.next('00:00');
    return new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 800,
        height: 600
      },
      audio: true
    }).then(stream => {
        this.stream = stream;
        this.record();
        resolve(stream);
      })
      .catch(error => {
        this.snackBar.open('Aufnahmegerät nicht gefunden!', '', {
          duration: 4000,
          horizontalPosition:'end',
          verticalPosition:  'top',
        });
        console.error('Recording failed:', error);
        reject(error);
      });
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm',
      bitsPerSecond: 128000,
      bufferSize: 512,
      numberOfAudioChannels: 1,
      recorderType: StereoAudioRecorder
    });

    this.recorder.record();
    this.startTime = performance.now();
    this.interval = setInterval(() => {
      const currentTime = performance.now();
      const duration = Math.floor((currentTime - this.startTime) / 1000);
      const time = this.toString(Math.floor(duration / 60)) + ':' + this.toString(duration % 60);
      this._recordingTime.next(time);
    }, 500);
  }

  private toString(value:any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop((blob:any) => {
        if (this.startTime) {
          const mp3Name = encodeURIComponent('audio_' + new Date().getTime());
          this.stopMedia();
          this._recorded.next({ blob: blob, title: mp3Name, url: '' });
        }
      }, () => {
        this.stopMedia();
        // this._recordingFailed.next();
      });
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track:any) => track.stop());
        this.stream = null;
      }
    }
  }

  uploadRecording(publicId: string, recording: any, type: string): Observable<any> {
    if (type == 'Audio') {
      return this.http.post(`${this.publicUrl}/${publicId}/voice-files`, recording);
    } else if (type == 'Video'){
      return this.http.post(`${this.publicUrl}/${publicId}/video-files`, recording);
    } else {
      return EMPTY;
    }
  }

  deleteRecording(publicId: string, fileId: string, type: string) {
    if (type == 'Audio') {
      return this.http.delete(`${this.publicUrl}/${publicId}/voice-files/${fileId}`);
    } else if (type == 'Video'){
      return this.http.delete(`${this.publicUrl}/${publicId}/video-files/${fileId}`);
    } else {
      return EMPTY;
    }
  }
  arrayToBase64(array: number[]): string {
    let binaryString = '';
    for (let i = 0; i < array.length; i++) {
      binaryString += String.fromCharCode(array[i]);
    }
    return btoa(binaryString);
  }

  blobToUrl(base64String: string, mimeType: string): string {
    try {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      const url = URL.createObjectURL(blob);
      return url;
    } catch (error: any) {
      console.error('Failed to create Blob URL:', error);
      return error;
    }
  }

  updateAudioStatus(audioId: number, status: string) {
    const endpoint = `${this.agentApiUrl}/voice-files/${audioId}/${status}`;
    return this.http.put(endpoint, null);
  }

  updateVideoStatus(videoId: number, status: string) {
    const endpoint = `${this.agentApiUrl}/video-files/${videoId}/${status}`;
    return this.http.put(endpoint, null);
  }

  requestVoiceFile(agentId: number) {
    return this.http.patch(`${this.agentApiUrl}/request-for-voicefile/${agentId}`, null);
  }

  changeMediaFilePlayedStatus(id:number,data:any){
    return this.http.patch(`${this.agentApiUrl}/change-file-played-statue/${id}`, data);
  }
}
