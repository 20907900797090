<div class="app">
  <div class="heading">
    <h2>Archiv</h2>
    <mat-form-field appearance="outline" class="dense-4 cust-outline-on-focus mat-form-field-no-padding" style="width: 25%;">
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput placeholder="Suche" [formControl]="search">
    </mat-form-field>
    <label class="toggle">
      <input class="toggle-checkbox" type="checkbox" [(ngModel)]="isAllAgent" (change)="onToggleSwitch()">
      <div class="toggle-switch"></div>
      <span class="toggle-label">In allen Agenten suchen</span>
    </label>
  </div>
  <div class="heading">
    <h2>Filter</h2>
    <app-agents-filter #agentsfilter [dataSource]="allAgents"></app-agents-filter>
    <button class="default-button" (click)="filter()">Filtern</button>
  </div>
  <div *ngIf="selectedAgents.size > 0" class="heading">
    <h2>Verschieben</h2>
    <ejs-dropdownlist [dataSource]="projects" [(ngModel)]="selectedProject" [fields]="dropdownFields" class="outlined-dropdown" placeholder="Projekt auswählen"></ejs-dropdownlist>
    <button class="default-button" (click)="onMoveToProject()">Ins Projekt verschieben</button>
  </div>

  <div class="mb-4">
    <div class="applicants-details">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th style="width: 5%;"></th>
              <th style="width: 16%;">Bewerbungseingang</th>
              <th class="ps-lg-5" style="width: 22%;">Name</th>
              <th style="width: 22%;">Standort</th>
              <th style="width: 10%;">Voicefile</th>
              <th style="width: 25%;">Partner</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let agent of filteredAgents">
              <td style="width: 5%;">
                <div class="d-flex align-items-center justify-content-center mt-2">
                  <ejs-checkbox (change)="onSelectAgent($event, agent)"></ejs-checkbox>
                </div>
              </td>
              <td style="width: 16%;">
                <p class="mb-0 mt-2">{{ agent.createdAt }}</p>
              </td>
              <td style="width: 22%;">
                <div class="d-flex align-items-center gap-3">
                  <div class="d-flex align-items-center justify-content-center">
                      @if (agent.profileImageSrc) {
                        <div class="profile-img mb-1">
                          <img [src]="agent.profileImageSrc" alt="applicants" #profileImage (load)="adjustImage(profileImage)" />
                        </div>
                      } @else {
                        <div class="profile-img mb-1">
                          <img src="../../assets/Icon avatar 1.svg" alt="applicants" #profileImage (load)="adjustImage(profileImage)" />
                        </div>
                      }
                  </div>
                  <p class="mb-0">{{agent.firstname}} {{agent.lastname}}</p>
                </div>
              </td>
              <td style="width: 22%;">
                <p class="mb-0 mt-2">{{agent.city}} {{agent.country}}</p>
              </td>
              <td style="width: 10%;">
                <div class="small-button mt-0" (click)="openMediaModal($event, agent.id)" *ngIf="isPlayIconVisible(agent)" [ngStyle]="{'background-color': getPlayIconColor(agent)}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#FFFFFF"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM10.6219 8.41459C10.5562 8.37078 10.479 8.34741 10.4 8.34741C10.1791 8.34741 10 8.52649 10 8.74741V15.2526C10 15.3316 10.0234 15.4088 10.0672 15.4745C10.1897 15.6583 10.4381 15.708 10.6219 15.5854L15.5008 12.3328C15.5447 12.3035 15.5824 12.2658 15.6117 12.2219C15.7343 12.0381 15.6846 11.7897 15.5008 11.6672L10.6219 8.41459Z"></path></svg>
                </div>
              </td>
              <td style="width: 40%;">
                <div class="d-flex align-items-center justify-content-between mt-2">
                  <p class="mb-0">{{agent?.partnerFirstName}} {{agent?.partnerLastName}}</p>
                  <button class="d-flex align-items-center justify-content-center" (click)="onClickAgent(agent)">
                    <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20"
                      xmlns="http://www.w3.org/2000/svg" id="screenshot-bb74426a-280e-8067-8003-ed21d11004ec"
                      version="1.1" viewBox="22899 1213 20 20" height="20">
                      <g id="shape-bb74426a-280e-8067-8003-ed21d11004ec" style="fill:#000000" width="1em" height="1em"
                        rx="0" ry="0">
                        <g id="shape-bb74426a-280e-8067-8003-ed21d1111ecb">
                          <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d1111ecb">
                            <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                              d="M22909.000,1218.000L22908.106,1218.871L22911.594,1222.375L22904.000,1222.375L22904.000,1223.625L22911.594,1223.625L22908.106,1227.108L22909.000,1228.000L22914.000,1223.000Z">
                            </path>
                          </g>
                        </g>
                        <g id="shape-bb74426a-280e-8067-8003-ed21d11151d4">
                          <g class="fills" id="fills-bb74426a-280e-8067-8003-ed21d11151d4">
                            <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.8"
                              d="M22909.000,1231.750C22904.168,1231.750,22900.250,1227.832,22900.250,1223.000C22900.250,1218.168,22904.168,1214.250,22909.000,1214.250C22913.832,1214.250,22917.750,1218.168,22917.750,1223.000C22917.744,1227.830,22913.830,1231.744,22909.000,1231.750ZM22909.000,1215.500C22904.858,1215.500,22901.500,1218.858,22901.500,1223.000C22901.500,1227.142,22904.858,1230.500,22909.000,1230.500C22913.142,1230.500,22916.500,1227.142,22916.500,1223.000C22916.495,1218.860,22913.140,1215.505,22909.000,1215.500Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isMediaPlay" class="backdrop"></div>
<div class="modalView" *ngIf="isMediaPlay">
  <div class="close-btn ms-auto mb-3" style="margin-right: -8px;" (click)="isMediaPlay = false;selectedAudioUrl='';selectedVideoUrl=''">
    <span class="d-block" style="color: rgb(102, 102, 102);">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <div class="w-100 overflow-y-auto">
    <div class="detailArea">
      <div class="video-wrapper mx-auto d-block mb-3" *ngIf="selectedVideoUrl">
        <div class="player-heading">
          Videodatei
        </div>
        <video width="500" controls>
          <source [src]="selectedVideoUrl" type="video/webm">
        </video>
      </div>
      <div class="video-wrapper mx-auto d-block" *ngIf="selectedAudioUrl">
        <div class="player-heading">
          Audiodatei
        </div>
        <audio controls class="w-100">
          <source [src]="selectedAudioUrl" type="audio/mpeg">
        </audio>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dialogService.showAgentView" class="backdrop"></div>
<div class="app-agentview" [ngClass]="{ dataModal: dialogService.showAgentView }">
  <app-agentview
    [data]="data"
    [projectViewsCurrentRole]="currentRole"
    *ngIf="dialogService.showAgentView"
    (onClose)="onClose($event)"
    [partners]="partnersService.partnerNames"
    [partnerId]="partnerId"
    [partnerName]="partnerName"
  ></app-agentview>
</div>

@if (isSpinnerActive) {
  <app-spinner></app-spinner>
}
