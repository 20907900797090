import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public constructor() {
  }

  showNewProjectDialog: boolean = false
  showTimeSlotDialog: boolean = false
  showCreateAgentDialog: boolean = false
  showWithdrawApplicationDialog: boolean = false;
  showAgentView: boolean = false;
  showMoveAgentDialog: boolean = false;
  showNewUserDialog: boolean = false;
  showNewCustomerDialog: boolean = false;
  showNewPartnerDialog: boolean = false;
  showMoveAgentWithoutVoicefileDialog: boolean = false;

  performDropAction: boolean = true;
}
