<div class="backdrop" id="backdrop" *ngIf="isSpinner"></div>
<div class="spinner" *ngIf="isSpinner">
  <mat-spinner></mat-spinner>
</div>
<div class="record-main-wrapper">
  <div
    class="d-flex align-items-center justify-content-center audio-main-wrapper"
  >
    <div
      class="mic-wrapper"
      (click)="startAudioRecording()"
      *ngIf="!isAudioRecording"
      [ngClass]="{ 'disabled': isVideoRecording }"
    >
      <i class="fa-solid fa-microphone"></i>
    </div>
    <div
      class="pause-wrapper"
      (click)="stopAudioRecording()"
      *ngIf="isAudioRecording"
    >
      <i class="fa-solid fa-circle-stop"></i>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <span>Audioaufnahme</span>
    </div>
    <div id="audioTimer" *ngIf="isAudioRecording && !audioBlobUrl">
      {{ audioRecordedTime }}
    </div>
  </div>

  <div
    class="d-flex justify-content-center align-items-center video-main-wrapper"
  >
    <div class="d-flex" style="align-items: center;">
      <div class="video-wrapper">
        <div
          class="cam-wrapper"
          (click)="videoPerview()"
          *ngIf="!isVideoRecording"
          [ngClass]="{ 'disabled': isAudioRecording }"
        >
          <i class="fa-solid fa-video"></i>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <div>Videoaufnahme</div>
        </div>
      </div>
    </div>
  </div>
</div>
@if (isVideoRecording) {
<div class="backdrop" id="backdrop"></div>
<div class="video-modal" id="video-modal">
   <!-- CLOSE BTN START -->
   <div class="close-btn ms-auto mb-3 position-absolute" (click)="abortVideoRecording()">
    <span class="d-block" style="color: rgb(102, 102, 102);">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
        <path _ngcontent-ng-c3535417517=""
          d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
        </path>
      </svg>
    </span>
  </div>
  <!-- CLOSE BTN END -->
  <div class="video-preview-wrapper">
    <video #videoElement class="videoPreview">
      <source [src]="videoBlobUrl" />
    </video>
    <div
      class="play-wrapper-video-modal"
      (click)="startVideoRecording()"
      *ngIf="!isVideoRecordingStart"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 256 256">
        <path fill="#24B89B" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88m72-88a72 72 0 1 1-72-72a72.08 72.08 0 0 1 72 72"/>
      </svg>
    </div>
    <div
      class="pause-wrapper-video-modal"
      (click)="stopVideoRecording()"
      *ngIf="isVideoRecordingStart"
    >
      <div>
        <i class="fa-solid fa-circle-stop"></i>
      </div>
    </div>
    <div id="audioTimer" *ngIf="isVideoRecording">
      {{ videoRecordedTime }}
    </div>
  </div>
</div>
}
