import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  expirationTimeSubject = new BehaviorSubject<string>('00:00:00');
  public expirationTime$: Observable<string> = this.expirationTimeSubject.asObservable();
  private timerSubscription: Subscription | null = null;

  constructor() { }

  startTimer(receivedAt: string | Date): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    const receivedDate = new Date(receivedAt);
    const expirationTime = receivedDate.getTime() + 24 * 60 * 60 * 1000;

    this.timerSubscription = timer(0, 1000).pipe(
      map(() => {
        const receivedDate = new Date(receivedAt);
        const expirationTime = receivedDate.getTime() + 24 * 60 * 60 * 1000;
        const now = new Date().getTime();
        const diff = expirationTime - now;

        if (diff <= 0) {
          this.timerSubscription?.unsubscribe();
          return "00:00:00";
        }

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      })
    )
.subscribe(time => {
      this.expirationTimeSubject.next(time);
    });
  }


}
