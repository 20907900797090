<div class="App" [ngClass]="{ modalView: isSingleProjectView, 'publicUrl': !isSingleProjectView }" *ngIf="!isAgentUser || (isAgentUser && !agent?.isApplicationWithdraw)">
  <!-- <div class="modal-full-screen"> -->
    <div class="close-btn ms-auto mb-3" (click)="dialogService.showAgentView = false;">
      <span class="d-block" style="color: rgb(102, 102, 102);">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
          <path
            d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
          </path>
        </svg>
      </span>
    </div>
    <div class="position-relative modalViewContent mb-0 w-100 mb-lg-3"
    [ngClass]="{ 'modalViewContent': isSingleProjectView}">
      <div class="agent-profile-wrapper mb-4">
        <div class="agent-profile">
          <img [src]="profileImageSrc" alt="Agent profile image" #profileImage (load)="adjustImage(profileImage)">
        </div>
        <button mat-stroked-button [matMenuTriggerFor]="menu" *ngIf="!isSingleProjectView && deviceType === 'android'">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          Profilbild ändern
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
          <label for="gallery-upload-android">aus der Galerie</label>
          <input type="file" id="gallery-upload-android" class="a-file-upload" (change)="chooseProfilePhoto($event)" accept="image/*">
          </button>
          <button mat-menu-item>
            <label for="camera-upload">Mit Kamera aufnehmen</label>
            <input type="file" id="camera-upload" class="a-file-upload" (change)="chooseProfilePhoto($event)" accept="image/*" capture="user">
          </button>
        </mat-menu>

        <div class="d-flex align-items-center justify-content-center" *ngIf="!isSingleProjectView && deviceType === 'iphone'"
          style="gap: 6px; max-width: 188px; width: 100%; padding: 0 6px; border: 1px solid #e0e0e0; border-radius: 5px; cursor: pointer;">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          <input type="file" class="file-upload" (change)="chooseProfilePhoto($event)" accept="image/*">
        </div>

        <div class="d-flex align-items-center justify-content-center" *ngIf="!isSingleProjectView && deviceType === 'desktop' && !hasWebcam"
          style="gap: 6px; max-width: 188px; width: 100%; padding: 0 6px; border: 1px solid #e0e0e0; border-radius: 5px; cursor: pointer;">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          <input type="file" class="file-upload" (change)="chooseProfilePhoto($event)" accept="image/*">
        </div>

        <button mat-stroked-button [matMenuTriggerFor]="desktopMenu"
        *ngIf="!isSingleProjectView && deviceType === 'desktop' && hasWebcam">
          <span class="fa-solid fa-upload" style="color: #24B89B;"></span>
          Profilbild ändern
        </button>
        <mat-menu #desktopMenu="matMenu">
          <label for="gallery-upload-desktop" style="cursor: pointer;" mat-menu-item>aus der Galerie</label>
          <input type="file" id="gallery-upload-desktop" class="a-file-upload" (change)="chooseProfilePhoto($event)" accept="image/*">
          <button mat-menu-item (click)="startCamera()">
            Mit Kamera aufnehmen
          </button>
        </mat-menu>

      </div>
      <p class="user-name"><strong>{{ agent?.firstname }} {{ agent?.lastname }}</strong></p>
      <div class="Layout">
        <div class="project-wrapper flex-shrink-0 d-flex align-items-end" *ngIf="agent?.project?.partnerInfo?.firstName">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="24" xmlns="http://www.w3.org/2000/svg"
            style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51c0" version="1.1"
            viewBox="3666.602 -284.714 24 24" height="24">
            <g id="shape-34614192-5454-8094-8003-c77b7f5b51c0" style="fill: #000000" width="24" height="24" rx="0" ry="0">
              <g id="shape-34614192-5454-8094-8003-c77b7f5b82b5">
                <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b5">
                  <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                    d="M3684.602,-269.714L3682.602,-269.714L3682.602,-267.714L3684.602,-267.714M3684.602,-273.714L3682.602,-273.714L3682.602,-271.714L3684.602,-271.714M3686.602,-265.714L3678.602,-265.714L3678.602,-267.714L3680.602,-267.714L3680.602,-269.714L3678.602,-269.714L3678.602,-271.714L3680.602,-271.714L3680.602,-273.714L3678.602,-273.714L3678.602,-275.714L3686.602,-275.714M3676.602,-277.714L3674.602,-277.714L3674.602,-279.714L3676.602,-279.714M3676.602,-273.714L3674.602,-273.714L3674.602,-275.714L3676.602,-275.714M3676.602,-269.714L3674.602,-269.714L3674.602,-271.714L3676.602,-271.714M3676.602,-265.714L3674.602,-265.714L3674.602,-267.714L3676.602,-267.714M3672.602,-277.714L3670.602,-277.714L3670.602,-279.714L3672.602,-279.714M3672.602,-273.714L3670.602,-273.714L3670.602,-275.714L3672.602,-275.714M3672.602,-269.714L3670.602,-269.714L3670.602,-271.714L3672.602,-271.714M3672.602,-265.714L3670.602,-265.714L3670.602,-267.714L3672.602,-267.714M3678.602,-277.714L3678.602,-281.714L3668.602,-281.714L3668.602,-263.714L3688.602,-263.714L3688.602,-277.714Z">
                  </path>
                </g>
              </g>
            </g>
          </svg>
          <span class="font-14px">
            {{agent?.project?.partnerInfo?.firstName}}
            {{agent?.project?.partnerInfo?.lastName}}
          </span>
        </div>

        <div class="interview-status-main-wrapper">
          <div class="interviw-wrapper" *ngIf="agent?.interviewAppointment">
            <div style="border-right: 1px solid #ddd; padding: 8px; display: flex; align-items: center; width: 35%;" class="font-14px">
              <i class="fa-solid fa-calendar-days"></i>
              <span class="ms-2">Interview</span>
            </div>
            <!-- <div class="vl"></div> -->
            <div class="interview-slot font-14px d-flex align-items-center" style="padding: 8px;" *ngIf="currentRole !== 'Admin'">
              <span>{{(agent?.interviewAppointment | date: 'dd.MM.yyyy HH:mm' )|| '- - -'}}</span>
            </div>
            <div class="interview-slot font-14px d-flex align-items-center">
              <ejs-dropdownlist
                id="appointmentDropdown"
                [(value)]="agentAppointment"
                (change)="onAppointmentChange()"
                *ngIf="currentRole === 'Admin' && timeSlotList"
                [dataSource]="timeSlotList"
                [fields]="appointmentDropdownFields"
                placeholder="Zeitfenster auswählen"
                [popupHeight]="'200px'"
                [popupWidth]="'230px'"
                class="outlined-none-dropdown"
                [enabled]="agent?.status=='AppointmentScheduled'"
              >
                <ng-template #noRecordsTemplate>
                  <span>Kein Zeitfenster verfügbar</span>
                </ng-template>
              </ejs-dropdownlist>
            </div>
          </div>
          <div class="status-wrapper">
            <div style="border-right: 1px solid #ddd; padding: 8px;display: flex;align-items: center; width: 35%;" class="font-14px">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="18.667"
                xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust:exact"
                id="screenshot-96759b49-23b5-80e9-8003-ed0ff0c362b6" version="1.1" viewBox="14069 -3440 18.667 18.667"
                height="18.667">
                <g id="shape-96759b49-23b5-80e9-8003-ed0ff0c362b6">
                  <g class="fills" id="fills-96759b49-23b5-80e9-8003-ed0ff0c362b6">
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" rx="0" ry="0" style="fill:none"
                      d="M14078.333,-3439.000L14078.333,-3435.667M14078.333,-3425.667L14078.333,-3422.333M14072.442,-3436.558L14074.800,-3434.200M14081.867,-3427.133L14084.225,-3424.775M14070.000,-3430.667L14073.333,-3430.667M14083.333,-3430.667L14086.667,-3430.667M14072.442,-3424.775L14074.800,-3427.133M14081.867,-3434.200L14084.225,-3436.558">
                    </path>
                  </g>
                  <g id="strokes-96759b49-23b5-80e9-8003-ed0ff0c362b6" class="strokes">
                    <g class="stroke-shape">
                      <path stroke-linecap="round" stroke-linejoin="round" rx="0" ry="0"
                        style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-dasharray:"
                        d="M14078.333,-3439.000L14078.333,-3435.667M14078.333,-3425.667L14078.333,-3422.333M14072.442,-3436.558L14074.800,-3434.200M14081.867,-3427.133L14084.225,-3424.775M14070.000,-3430.667L14073.333,-3430.667M14083.333,-3430.667L14086.667,-3430.667M14072.442,-3424.775L14074.800,-3427.133M14081.867,-3434.200L14084.225,-3436.558">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              <span class="ms-2 font-14px">Status</span>
            </div>
            <div class="status font-14px d-flex align-items-center pe-3"
              *ngIf="((currentRole !== 'Client' && currentRole !== 'Admin')
              || isAgentUser
              || agent?.status === 'ApplicantArchived')">
              <p class="mb-0"
                style="color: #24b89b;">{{sharedService.statuses[agent?.status]?.display||agent?.status}}</p>
            </div>
            <ejs-dropdownlist
            class="outlined-none-dropdown"
            id="agentStatusDropdown"
            *ngIf="(currentRole === 'Client' || currentRole === 'Admin') && !isAgentUser && agent?.status !== 'ApplicantArchived' && !!agentStatus"
            [dataSource]="kanbanStatus"
            [fields]="dropdownFields"
            [popupHeight]="'200px'"
            [popupWidth]="'250px'"
            [(value)]="agentStatus"
            (change)="onChange()"
            placeholder="Status auswählen"
            >
            </ejs-dropdownlist>
          </div>
          <div class="move-agent-wrapper" [matMenuTriggerFor]="menuMoveAgent" *ngIf="currentRole === 'Admin' && agent?.status !=='ApplicantArchived' && !isAgentUser ">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="18" xmlns="http://www.w3.org/2000/svg" height="18"
              id="screenshot-96759b49-23b5-80e9-8003-ed12494f75a4" viewBox="14275 736 18 18"
              style="-webkit-print-color-adjust: exact;" fill="none" version="1.1">
              <g id="shape-96759b49-23b5-80e9-8003-ed12494f75a4">
                <g class="fills" id="fills-96759b49-23b5-80e9-8003-ed12494f75a4">
                  <path rx="0" ry="0"
                    d="M14277.000,754.000C14276.450,754.000,14275.979,753.804,14275.588,753.413C14275.197,753.022,14275.001,752.551,14275.000,752.000L14275.000,749.000L14277.000,749.000L14277.000,752.000L14291.000,752.000L14291.000,740.000L14277.000,740.000L14277.000,743.000L14275.000,743.000L14275.000,738.000C14275.000,737.450,14275.196,736.979,14275.588,736.588C14275.980,736.197,14276.451,736.001,14277.000,736.000L14291.000,736.000C14291.550,736.000,14292.021,736.196,14292.413,736.588C14292.805,736.980,14293.001,737.451,14293.000,738.000L14293.000,752.000C14293.000,752.550,14292.804,753.021,14292.413,753.413C14292.022,753.805,14291.551,754.001,14291.000,754.000ZM14283.500,750.500L14282.100,749.100L14284.175,747.000L14275.000,747.000L14275.000,745.000L14284.175,745.000L14282.100,742.900L14283.500,741.500L14288.000,746.000Z"
                    style="fill: rgb(108, 108, 108); fill-opacity: 1;" />
                </g>
              </g>
            </svg>
          </div>
          <mat-menu #menuMoveAgent="matMenu">
            <div mat-menu-item (click)="openMoveAgent('archive')">
              <span>Ins Archiv verschieben</span>
            </div>
            <div mat-menu-item (click)="openMoveAgent('otherProject')">
              <span>In anderes Projekt verschieben</span>
            </div>
          </mat-menu>
        </div>
      </div>
      <div style="margin-top: 8px; display: flex; align-items: center;">
      </div>
      <div class="contentarea">
        <div class="leftSide">
          <span class="agent-info-txt">
            Agent Informationen
          </span>

          <div class="Zeile2345">
            <div class="Layout23456 agent-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                <path fill="currentColor"
                  d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14c-1.981 0-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.503 37.503 0 0 0 15.303 0" />
                <circle cx="12" cy="8" r="5" fill="currentColor" />
              </svg>
              Partner
            </div>
            <ng-container *ngIf="agent && (currentRole === 'Partner' || currentRole === 'Admin') && !isAgentUser">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]="partners" [(ngModel)]="agent.partnerName" class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="agent && ((currentRole !== 'Partner' && currentRole !== 'Admin') || isAgentUser)">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.partnerName }}</div>
            </ng-container>
          </div>

          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <!-- Shape: svg -->
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb7fdc6a701a"
                version="1.1" viewBox="7920 -2319 20 20" class="svg-height-width">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6a701a" style="fill:#000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6c741b" style="fill:#000000" rx="0" ry="0">
                    <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6da8ab">
                      <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb7fdc6da8ab">
                        <path rx="0" ry="0" style="fill:#666666"
                          d="M7934.583,-2309.000C7934.585,-2307.738,7934.065,-2306.532,7933.146,-2305.667C7932.296,-2304.862,7931.170,-2304.415,7930.000,-2304.417C7928.782,-2304.417,7927.675,-2304.892,7926.854,-2305.667C7925.935,-2306.532,7925.415,-2307.738,7925.417,-2309.000Z">
                        </path>
                      </g>
                    </g>
                    <g id="shape-43c4c9b0-5d53-809a-8003-cb7fdc6e8779">
                      <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb7fdc6e8779">
                        <path fill-rule="evenodd" clip-rule="evenodd" rx="0" ry="0" style="fill:#666666"
                          d="M7920.833,-2313.167C7920.833,-2315.928,7923.072,-2318.167,7925.833,-2318.167L7934.167,-2318.167C7936.928,-2318.167,7939.167,-2315.928,7939.167,-2313.167L7939.167,-2304.833C7939.167,-2302.072,7936.928,-2299.833,7934.167,-2299.833L7920.833,-2299.833ZM7923.125,-2309.000C7923.125,-2312.797,7926.203,-2315.875,7930.000,-2315.875C7933.797,-2315.875,7936.875,-2312.797,7936.875,-2309.000C7936.875,-2305.203,7933.797,-2302.125,7930.000,-2302.125C7926.203,-2302.125,7923.125,-2305.203,7923.125,-2309.000Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              Anrede
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout234567">
                <ejs-dropdownlist [dataSource]='salutation' [(ngModel)]="agent.salutation" class="outlined-dropdown"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.salutation }}</div>
            </ng-container>
          </div>
          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bc"
                version="1.1" viewBox="3666.602 -154.714 20 20" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b51bc" style="fill: #000000" width="56" height="56" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82ad">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ad">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3676.602,-136.176C3681.264,-136.176,3685.140,-140.043,3685.140,-144.714C3685.140,-149.376,3681.256,-153.251,3676.593,-153.251C3671.923,-153.251,3668.064,-149.376,3668.064,-144.714C3668.064,-140.043,3671.931,-136.176,3676.602,-136.176ZM3676.602,-141.859C3674.082,-141.859,3672.141,-140.964,3671.295,-139.959C3670.173,-141.214,3669.495,-142.880,3669.495,-144.714C3669.495,-148.656,3672.643,-151.829,3676.594,-151.829C3680.536,-151.829,3683.708,-148.656,3683.717,-144.714C3683.717,-142.872,3683.039,-141.206,3681.909,-139.951C3681.063,-140.955,3679.122,-141.859,3676.602,-141.859ZM3676.602,-143.282C3678.209,-143.265,3679.473,-144.638,3679.473,-146.438C3679.473,-148.129,3678.201,-149.535,3676.601,-149.535C3674.994,-149.535,3673.714,-148.129,3673.731,-146.438C3673.739,-144.638,3674.986,-143.299,3676.602,-143.282Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Vorname
            </div>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.firstname }}</div>
            </ng-container>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <input class="Layout234567 font-14px agent-detail" [(ngModel)]="agent.firstname"
                (focus)="onInputFocus($event)" (blur)="onInputBlur($event)">
            </ng-container>
          </div>
          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bc"
                version="1.1" viewBox="3666.602 -154.714 20 20" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b51bc" style="fill: #000000" width="56" height="56" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82ad">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82ad">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3676.602,-136.176C3681.264,-136.176,3685.140,-140.043,3685.140,-144.714C3685.140,-149.376,3681.256,-153.251,3676.593,-153.251C3671.923,-153.251,3668.064,-149.376,3668.064,-144.714C3668.064,-140.043,3671.931,-136.176,3676.602,-136.176ZM3676.602,-141.859C3674.082,-141.859,3672.141,-140.964,3671.295,-139.959C3670.173,-141.214,3669.495,-142.880,3669.495,-144.714C3669.495,-148.656,3672.643,-151.829,3676.594,-151.829C3680.536,-151.829,3683.708,-148.656,3683.717,-144.714C3683.717,-142.872,3683.039,-141.206,3681.909,-139.951C3681.063,-140.955,3679.122,-141.859,3676.602,-141.859ZM3676.602,-143.282C3678.209,-143.265,3679.473,-144.638,3679.473,-146.438C3679.473,-148.129,3678.201,-149.535,3676.601,-149.535C3674.994,-149.535,3673.714,-148.129,3673.731,-146.438C3673.739,-144.638,3674.986,-143.299,3676.602,-143.282Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Nachname
            </div>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout2345672 font-14px agent-detail">{{ agent?.lastname }}</div>
            </ng-container>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <input class="Layout234567 font-14px agent-detail" [(ngModel)]="agent.lastname"
                (focus)="onInputFocus($event)" (blur)="onInputBlur($event)">
            </ng-container>
          </div>

          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <!-- Shape: svg -->
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb831b18b86f"
                version="1.1" viewBox="7921 -2189.2 20 20" class="svg-height-width">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb831b18b86f" style="fill:#000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb831b1b34ce">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb831b1b34ce">
                      <path rx="0" ry="0" style="fill:#666666"
                        d="M7930.583,-2188.783C7931.000,-2188.575,7931.833,-2187.200,7931.833,-2186.283C7931.833,-2185.367,7931.275,-2185.033,7930.583,-2185.033C7929.892,-2185.033,7929.333,-2185.158,7929.333,-2186.075C7929.333,-2186.992,7930.167,-2187.533,7930.583,-2188.783ZM7936.417,-2181.700C7938.500,-2181.700,7940.167,-2180.033,7940.167,-2177.950C7940.167,-2176.650,7939.508,-2175.508,7938.500,-2174.833L7938.500,-2170.033L7923.500,-2170.033L7923.500,-2174.833C7922.492,-2175.508,7921.833,-2176.650,7921.833,-2177.950C7921.833,-2180.033,7923.500,-2181.700,7925.583,-2181.700L7929.333,-2181.700L7929.333,-2184.200L7931.833,-2184.200L7931.833,-2181.700ZM7931.000,-2175.867C7932.151,-2175.867,7933.083,-2176.799,7933.083,-2177.950L7934.333,-2177.950C7934.333,-2176.799,7935.266,-2175.867,7936.417,-2175.867C7937.567,-2175.867,7938.500,-2176.799,7938.500,-2177.950C7938.500,-2179.101,7937.567,-2180.033,7936.417,-2180.033L7925.583,-2180.033C7924.433,-2180.033,7923.500,-2179.101,7923.500,-2177.950C7923.500,-2176.799,7924.433,-2175.867,7925.583,-2175.867C7926.734,-2175.867,7927.667,-2176.799,7927.667,-2177.950L7928.917,-2177.950C7928.917,-2176.799,7929.849,-2175.867,7931.000,-2175.867Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Geburtstag
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout234567 w-50">
                  <mat-form-field appearance="outline" class="cust-mat-cal">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="agent.birthday">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.birthday | date: 'dd.MM.YYYY'}}</div>
            </ng-container>
          </div>
          <div class="Zeile234522">
            <div class="Layout2345622 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51be"
                version="1.1" viewBox="3666.602 -65.714 20 20" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b51be" style="fill: #000000" width="32" height="32" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82b1">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b1">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3679.308,-52.289L3680.708,-53.689C3681.093,-54.069,3681.667,-54.187,3682.170,-53.989L3683.877,-53.307C3684.386,-53.100,3684.722,-52.607,3684.727,-52.057L3684.727,-48.932C3684.724,-48.563,3684.570,-48.210,3684.301,-47.956C3684.032,-47.703,3683.671,-47.570,3683.302,-47.589C3671.345,-48.332,3668.933,-58.457,3668.477,-62.332C3668.434,-62.717,3668.558,-63.102,3668.817,-63.390C3669.076,-63.678,3669.446,-63.841,3669.833,-63.839L3672.852,-63.839C3673.403,-63.837,3673.898,-63.500,3674.102,-62.989L3674.783,-61.282C3674.988,-60.780,3674.872,-60.204,3674.489,-59.820L3673.089,-58.420C3673.089,-58.420,3673.895,-52.964,3679.308,-52.289Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Telefonnummer
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout23456722" style="width: min-content;">
                  <ejs-dropdownlist
                  id='ddlelement'
                  [dataSource]='countryCode'
                  [allowFiltering]='true'
                  [(ngModel)]='agent.countryCode'
                  class="outlined-dropdown"
                  [fields]="fields"
                  (filtering)='onFiltering($event)'
                  ></ejs-dropdownlist>
                <input #thisinput_phonenumber type="text" [value]="agent.phoneNumber" [(ngModel)]="agent.phoneNumber"
                  (input)="thisinput_phonenumber.value" [style.backgroundcolor]="'#ffffff'" (focus)="onInputFocus($event)"
                  (blur)="onInputBlurForPhoneNumber($event)" class="Layout234567 font-14px agent-detail">
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
                <div class="Layout23456722 font-14px agent-detail">{{ agent?.countryCode }} {{ agent?.phoneNumber }}</div>
            </ng-container>
          </div>

          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <!-- Shape: svg -->
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb8128f3c743"
                version="1.1" viewBox="7920 -2100 20 20" class="svg-height-width">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f3c743" style="fill:#000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f45516" style="fill:#000000" rx="0" ry="0">
                    <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4a0d0">
                      <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4a0d0">
                        <path fill="none" stroke-linecap="round" rx="0" ry="0" style="fill:none"
                          d="M7937.500,-2090.000C7937.500,-2093.213,7935.453,-2096.069,7932.411,-2097.102C7929.368,-2098.135,7926.006,-2097.115,7924.050,-2094.566C7922.094,-2092.016,7921.979,-2088.505,7923.764,-2085.833C7925.549,-2083.162,7928.838,-2081.924,7931.942,-2082.756">
                        </path>
                      </g>
                      <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4a0d0" class="strokes">
                        <g class="stroke-shape">
                          <path stroke-linecap="round" rx="0" ry="0"
                            style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                            d="M7937.500,-2090.000C7937.500,-2093.213,7935.453,-2096.069,7932.411,-2097.102C7929.368,-2098.135,7926.006,-2097.115,7924.050,-2094.566C7922.094,-2092.016,7921.979,-2088.505,7923.764,-2085.833C7925.549,-2083.162,7928.838,-2081.924,7931.942,-2082.756">
                          </path>
                        </g>
                      </g>
                    </g>
                    <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4a0d1">
                      <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4a0d1">
                        <ellipse fill="none" rx="3.333333333333485" ry="3.3333333333332575" style="fill:none" cx="7930"
                          cy="-2090" transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)">
                        </ellipse>
                      </g>
                      <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4a0d1" class="strokes">
                        <g class="stroke-shape">
                          <ellipse rx="3.333333333333485" ry="3.3333333333332575"
                            style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                            cx="7930" cy="-2090"
                            transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)">
                          </ellipse>
                        </g>
                      </g>
                    </g>
                    <g id="shape-43c4c9b0-5d53-809a-8003-cb8128f4ce61">
                      <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb8128f4ce61">
                        <path fill="none" stroke-linecap="round" rx="0" ry="0" style="fill:none"
                          d="M7933.333,-2092.500L7933.333,-2088.750C7933.333,-2087.599,7934.266,-2086.667,7935.417,-2086.667L7935.417,-2086.667C7936.567,-2086.667,7937.500,-2087.599,7937.500,-2088.750L7937.500,-2090.000">
                        </path>
                      </g>
                      <g id="strokes-43c4c9b0-5d53-809a-8003-cb8128f4ce61" class="strokes">
                        <g class="stroke-shape">
                          <path stroke-linecap="round" rx="0" ry="0"
                            style="fill:none;fill-opacity:none;stroke-width:2;stroke:#666666;stroke-opacity:1;stroke-dasharray:"
                            d="M7933.333,-2092.500L7933.333,-2088.750C7933.333,-2087.599,7934.266,-2086.667,7935.417,-2086.667L7935.417,-2086.667C7936.567,-2086.667,7937.500,-2087.599,7937.500,-2088.750L7937.500,-2090.000">
                          </path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              Mailadresse
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout23456722">
                <input class="Layout234567 font-14px agent-detail" [(ngModel)]="agent.emailAddress"
                  (focus)="onInputFocus($event)" (blur)="onInputBlur($event)">
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.emailAddress }}</div>
            </ng-container>
          </div>
          <div class="Zeile2345222">
            <div class="Layout23456222 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5b51bf"
                version="1.1" viewBox="3663.602 -20.714 24 24" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5b51bf" style="fill: #000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82b3">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82b3">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3675.602,-9.214C3674.221,-9.214,3673.102,-10.333,3673.102,-11.714C3673.102,-13.094,3674.221,-14.214,3675.602,-14.214C3676.982,-14.214,3678.102,-13.094,3678.102,-11.714C3678.102,-10.333,3676.982,-9.214,3675.602,-9.214ZM3675.602,-18.714C3671.736,-18.714,3668.602,-15.580,3668.602,-11.714C3668.602,-6.464,3675.602,1.286,3675.602,1.286C3675.602,1.286,3682.602,-6.464,3682.602,-11.714C3682.602,-15.580,3679.468,-18.714,3675.602,-18.714Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Standort
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout23456722" style="width: min-content;gap: 0.5rem;">
                <input class="Layout234567 font-14px agent-detail" [(ngModel)]="agent.city" (focus)="onInputFocus($event)"
                  (blur)="onInputBlur($event)" placeholder="Ort">
                <input class="Layout234567 font-14px agent-detail" [(ngModel)]="agent.country"
                  (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" placeholder="Land">
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout234567222 font-14px agent-detail">{{ agent?.city }} {{ agent?.country }}</div>
            </ng-container>
          </div>

          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <!-- Shape: svg -->
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-43c4c9b0-5d53-809a-8003-cb80b3a55666"
                version="1.1" viewBox="7917 -2010.8 24 24" class="svg-height-width">
                <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55666" style="fill:#000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55668">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb80b3a55668">
                      <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.6"
                        d="M7924.000,-1999.800L7926.000,-1999.800L7926.000,-1997.800L7924.000,-1997.800ZM7924.000,-1995.800L7926.000,-1995.800L7926.000,-1993.800L7924.000,-1993.800ZM7928.000,-1999.800L7930.000,-1999.800L7930.000,-1997.800L7928.000,-1997.800ZM7928.000,-1995.800L7930.000,-1995.800L7930.000,-1993.800L7928.000,-1993.800ZM7932.000,-1999.800L7934.000,-1999.800L7934.000,-1997.800L7932.000,-1997.800ZM7932.000,-1995.800L7934.000,-1995.800L7934.000,-1993.800L7932.000,-1993.800Z">
                      </path>
                    </g>
                  </g>
                  <g id="shape-43c4c9b0-5d53-809a-8003-cb80b3a55669">
                    <g class="fills" id="fills-43c4c9b0-5d53-809a-8003-cb80b3a55669">
                      <path rx="0" ry="0" style="fill:#000000;fill-opacity:0.6"
                        d="M7922.000,-1988.800L7936.000,-1988.800C7937.103,-1988.800,7938.000,-1989.697,7938.000,-1990.800L7938.000,-2004.800C7938.000,-2005.903,7937.103,-2006.800,7936.000,-2006.800L7934.000,-2006.800L7934.000,-2008.800L7932.000,-2008.800L7932.000,-2006.800L7926.000,-2006.800L7926.000,-2008.800L7924.000,-2008.800L7924.000,-2006.800L7922.000,-2006.800C7920.897,-2006.800,7920.000,-2005.903,7920.000,-2004.800L7920.000,-1990.800C7920.000,-1989.697,7920.897,-1988.800,7922.000,-1988.800ZM7936.000,-2002.800L7936.001,-1990.800L7922.000,-1990.800L7922.000,-2002.800Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Kündigungsfrist
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin') && !isAgentUser">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]='noticePeriod' [(ngModel)]="agent.noticePeriod" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="agent && ((currentRole !=='Partner' && currentRole !=='Admin') || isAgentUser)">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.noticePeriod }}</div>
            </ng-container>
          </div>
          <div class="Zeile23452223">
            <div class="Layout234562223 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a2"
                version="1.1" viewBox="3664.602 26.286 24 24" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a2" style="fill: #000000" width="24" height="24" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b829d">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b829d">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3671.602,37.286L3673.602,37.286L3673.602,39.286L3671.602,39.286ZM3671.602,41.286L3673.602,41.286L3673.602,43.286L3671.602,43.286ZM3675.602,37.286L3677.602,37.286L3677.602,39.286L3675.602,39.286ZM3675.602,41.286L3677.602,41.286L3677.602,43.286L3675.602,43.286ZM3679.602,37.286L3681.602,37.286L3681.602,39.286L3679.602,39.286ZM3679.602,41.286L3681.602,41.286L3681.602,43.286L3679.602,43.286Z">
                      </path>
                    </g>
                  </g>
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b829e">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b829e">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3669.602,48.286L3683.602,48.286C3684.705,48.286,3685.602,47.389,3685.602,46.286L3685.602,32.286C3685.602,31.183,3684.705,30.286,3683.602,30.286L3681.602,30.286L3681.602,28.286L3679.602,28.286L3679.602,30.286L3673.602,30.286L3673.602,28.286L3671.602,28.286L3671.602,30.286L3669.602,30.286C3668.499,30.286,3667.602,31.183,3667.602,32.286L3667.602,46.286C3667.602,47.389,3668.499,48.286,3669.602,48.286ZM3683.602,34.286L3683.603,46.286L3669.602,46.286L3669.602,34.286Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              ab wann verfügbar
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin' || isAgentUser)">
              <div class="Layout2345672223 w-50">
                  <mat-form-field appearance="outline" class="cust-mat-cal">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="agent.availableFrom">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole ==='Client' && !isAgentUser">
              <div class="Layout2345672223 font-14px agent-detail">{{ agent?.availableFrom | date: 'dd.MM.YYYY'}}</div>
            </ng-container>
          </div>
          <div class="Zeile234523">
            <div class="Layout2345623 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a3"
                version="1.1" viewBox="3664.602 78.286 20 16" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a3" style="fill: #000000" width="16" height="16" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82a0">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82a0">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3666.789,81.286C3666.271,81.286,3665.852,81.622,3665.852,82.036C3665.852,82.451,3666.271,82.786,3666.789,82.786L3682.414,82.786C3682.932,82.786,3683.352,82.451,3683.352,82.036C3683.352,81.622,3682.932,81.286,3682.414,81.286ZM3666.789,84.286C3666.271,84.286,3665.852,84.622,3665.852,85.036C3665.852,85.451,3666.271,85.786,3666.789,85.786L3682.414,85.786C3682.932,85.786,3683.352,85.451,3683.352,85.036C3683.352,84.622,3682.932,84.286,3682.414,84.286ZM3665.852,88.036C3665.852,87.622,3666.271,87.286,3666.789,87.286L3682.414,87.286C3682.932,87.286,3683.352,87.622,3683.352,88.036C3683.352,88.451,3682.932,88.786,3682.414,88.786L3666.789,88.786C3666.271,88.786,3665.852,88.451,3665.852,88.036ZM3666.789,90.286C3666.271,90.286,3665.852,90.622,3665.852,91.036C3665.852,91.451,3666.271,91.786,3666.789,91.786L3677.414,91.786C3677.932,91.786,3678.352,91.451,3678.352,91.036C3678.352,90.622,3677.932,90.286,3677.414,90.286Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Erfahrungen
            </div>
            <ng-container *ngIf="agent && (currentRole ==='Partner' || currentRole ==='Admin') && !isAgentUser">
              <div class="Layout23456723 font-14px">
                <textarea #thisinput_experiences class="textarea" [value]="agent.experiences"
                  [(ngModel)]="agent.experiences" (input)="agent.experiences" [style.backgroundcolor]="'#ffffff'"
                  (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" style="width: 100%;"></textarea>
              </div>
            </ng-container>
            <ng-container *ngIf="agent && ((currentRole !=='Partner' && currentRole !=='Admin') || isAgentUser)">
              <div class="Layout23456723 font-14px" style="padding-left: 6px;">
                {{ agent?.experiences }}
              </div>
            </ng-container>
          </div>

          <div class="Zeile2345" *ngIf="!isAgentUser">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" style="stroke: #000000; stroke-opacity: 0.6" d="M3 21h18M5 21V7l8-4v18m6 0V11l-6-4M9 9v.01M9 12v.01M9 15v.01M9 18v.01"/></svg>
              B2B Fähig
            </div>
            <ng-container *ngIf="agent && (currentRole === 'Partner' || currentRole === 'Admin' || isAgentUser)">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]='question' [(ngModel)]="agent.b2bCapable" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole === 'Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.b2bCapable }}</div>
            </ng-container>
          </div>
          <div class="Zeile2345" *ngIf="!isAgentUser">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path fill="#ec1c24" d="M0 25h64v14H0z"/><path fill="#25333a" d="M54 10H10C3.373 10 0 14.925 0 21v4h64v-4c0-6.075-3.373-11-10-11"/><path fill="#f9cb38" d="M0 43c0 6.075 3.373 11 10 11h44c6.627 0 10-4.925 10-11v-4H0z"/></svg>
              Deutsch
            </div>
            <ng-container *ngIf="agent && (currentRole === 'Partner' || currentRole === 'Admin' || isAgentUser)">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]='languageLevel' [(ngModel)]="agent.germanLevel" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole === 'Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.germanLevel }}</div>
            </ng-container>
          </div>
          <div class="Zeile2345" *ngIf="!isAgentUser">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path fill="#2e3192" d="M38 54h16c1.977 0 3.657-.446 5.052-1.223L38 40.219zm25.66-7.79c.228-1.017.344-2.094.344-3.211v-5h-14.11l13.762 8.211M.35 17.759A14.6 14.6 0 0 0 0 21v5h14.164zM26 10H10c-1.963 0-3.632.44-5.021 1.206L26 23.746zM5.043 52.826C6.419 53.57 8.066 54 10 54h16V40.324zM0 38v5c0 1.151.122 2.26.363 3.303L14.282 38zm59.115-26.745C57.709 10.457 56.006 10 54 10H38v13.851zM64 26v-5c0-1.094-.113-2.149-.332-3.147L50.012 26z"/><path fill="#e6e7e8" d="m50.012 26l13.656-8.147c-.626-2.864-2.15-5.235-4.553-6.598L38 23.851V10h-2v18h28v-2zM0 36v2h14.282L.363 46.303c.661 2.855 2.231 5.199 4.68 6.523L26 40.324V54h2V36zm64 0H36v18h2V40.219l21.052 12.559c2.421-1.348 3.964-3.706 4.604-6.566L49.894 38H64zM26 10v13.746L4.979 11.206C2.549 12.546.996 14.9.349 17.759L14.164 26H0v2h28V10z"/><path fill="#be1e2d" d="M36 28V10h-8v18H0v8h28v18h8V36h28v-8z"/><path fill="#be1e2d" d="M21.938 26L1.888 14.031c-.431.64-.777 1.344-1.063 2.094L17.372 26h4.563M63.09 48.09L46.277 38h-4.656l20.313 12.219a10 10 0 0 0 1.156-2.125m-2.371-35.703L37.969 26l4.619.003L62.219 14.25c-.438-.797-.9-1.311-1.5-1.859M1.813 49.875a9 9 0 0 0 1.609 1.844L26.063 38H21.5z"/></svg>
              Englisch
            </div>
            <ng-container *ngIf="agent && (currentRole === 'Partner' || currentRole === 'Admin' || isAgentUser)">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]='languageLevel' [(ngModel)]="agent.englishLevel" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole === 'Client' && !isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ agent?.englishLevel }}</div>
            </ng-container>
          </div>
          <div class="Zeile2345">
            <div class="Layout23456 font-14px agent-info">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust: exact" id="screenshot-34614192-5454-8094-8003-c77b7f5ac6a3"
                version="1.1" viewBox="3664.602 78.286 20 16" class="svg-height-width">
                <g id="shape-34614192-5454-8094-8003-c77b7f5ac6a3" style="fill: #000000" width="16" height="16" rx="0"
                  ry="0">
                  <g id="shape-34614192-5454-8094-8003-c77b7f5b82a0">
                    <g class="fills" id="fills-34614192-5454-8094-8003-c77b7f5b82a0">
                      <path rx="0" ry="0" style="fill: #000000; fill-opacity: 0.6"
                        d="M3666.789,81.286C3666.271,81.286,3665.852,81.622,3665.852,82.036C3665.852,82.451,3666.271,82.786,3666.789,82.786L3682.414,82.786C3682.932,82.786,3683.352,82.451,3683.352,82.036C3683.352,81.622,3682.932,81.286,3682.414,81.286ZM3666.789,84.286C3666.271,84.286,3665.852,84.622,3665.852,85.036C3665.852,85.451,3666.271,85.786,3666.789,85.786L3682.414,85.786C3682.932,85.786,3683.352,85.451,3683.352,85.036C3683.352,84.622,3682.932,84.286,3682.414,84.286ZM3665.852,88.036C3665.852,87.622,3666.271,87.286,3666.789,87.286L3682.414,87.286C3682.932,87.286,3683.352,87.622,3683.352,88.036C3683.352,88.451,3682.932,88.786,3682.414,88.786L3666.789,88.786C3666.271,88.786,3665.852,88.451,3665.852,88.036ZM3666.789,90.286C3666.271,90.286,3665.852,90.622,3665.852,91.036C3665.852,91.451,3666.271,91.786,3666.789,91.786L3677.414,91.786C3677.932,91.786,3678.352,91.451,3678.352,91.036C3678.352,90.622,3677.932,90.286,3677.414,90.286Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Outbound Erfahrung
            </div>
            <ng-container *ngIf="agent && (currentRole === 'Partner' || currentRole === 'Admin') && !isAgentUser">
              <div class="Layout234567 notice-period-wrapper">
                <ejs-dropdownlist [dataSource]='outboundExperienceLevel' [(ngModel)]="agent.outboundExperienceLevel" [fields]='field' class="outlined-dropdown notice-period"></ejs-dropdownlist>
              </div>
            </ng-container>
            <ng-container *ngIf="currentRole === 'Client' || isAgentUser">
              <div class="Layout234567 font-14px agent-detail">{{ getTextForValueForOutboundExperienceLevel(agent?.outboundExperienceLevel) }}</div>
            </ng-container>
          </div>

          <div class="Zeile234523 audio-video" *ngIf="!isAccessedPublicly && !isFromOpenTasks">
            <div class="Layout2345623 font-14px agent-info">

              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-96759b49-23b5-80e9-8003-ed11d69d5524"
                version="1.1" viewBox="13237 -1108 20 20" height="20">
                <g id="shape-96759b49-23b5-80e9-8003-ed11d69d5524" style="fill:#000000" width="48" height="48" rx="0"
                  ry="0">
                  <g id="shape-96759b49-23b5-80e9-8003-ed11d69d5526" style="fill:#000000" rx="0" ry="0">
                    <g id="shape-96759b49-23b5-80e9-8003-ed11d69d5527">
                      <g class="fills" id="fills-96759b49-23b5-80e9-8003-ed11d69d5527">
                        <path fill="none" rx="0" ry="0" style="fill:none"
                          d="M13247.000,-1089.667C13251.602,-1089.667,13255.333,-1093.398,13255.333,-1098.000C13255.333,-1102.602,13251.602,-1106.333,13247.000,-1106.333C13242.398,-1106.333,13238.667,-1102.602,13238.667,-1098.000C13238.667,-1093.398,13242.398,-1089.667,13247.000,-1089.667ZZ">
                        </path>
                      </g>
                      <g id="strokes-96759b49-23b5-80e9-8003-ed11d69d5527" class="strokes">
                        <g class="stroke-shape">
                          <path rx="0" ry="0"
                            style="fill:none;fill-opacity:none;stroke-width:1.5;stroke:#000000;stroke-opacity:0.6;stroke-dasharray:"
                            d="M13247.000,-1089.667C13251.602,-1089.667,13255.333,-1093.398,13255.333,-1098.000C13255.333,-1102.602,13251.602,-1106.333,13247.000,-1106.333C13242.398,-1106.333,13238.667,-1102.602,13238.667,-1098.000C13238.667,-1093.398,13242.398,-1089.667,13247.000,-1089.667ZZ">
                          </path>
                        </g>
                      </g>
                    </g>
                    <g id="shape-96759b49-23b5-80e9-8003-ed11d69d5528">
                      <g class="fills" id="fills-96759b49-23b5-80e9-8003-ed11d69d5528">
                        <path fill="none" stroke-linecap="round" rx="0" ry="0" style="fill:none"
                          d="M13249.500,-1100.500L13249.500,-1095.500M13252.000,-1098.833L13252.000,-1097.167M13244.500,-1100.500L13244.500,-1095.500M13242.000,-1098.833L13242.000,-1097.167M13247.000,-1102.167L13247.000,-1093.833">
                        </path>
                      </g>
                      <g id="strokes-96759b49-23b5-80e9-8003-ed11d69d5528" class="strokes">
                        <g class="stroke-shape">
                          <path stroke-linecap="round" rx="0" ry="0"
                            style="fill:none;fill-opacity:none;stroke-width:1.5;stroke:#000000;stroke-opacity:0.6;stroke-dasharray:"
                            d="M13249.500,-1100.500L13249.500,-1095.500M13252.000,-1098.833L13252.000,-1097.167M13244.500,-1100.500L13244.500,-1095.500M13242.000,-1098.833L13242.000,-1097.167M13247.000,-1102.167L13247.000,-1093.833">
                          </path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              Audio-/Video-Dateien
            </div>
            <div class="Layout23456723 font-14px voice-player" style="padding-left: 6px;" >
              <app-voice-player [isDeleteActive]=false [isAdminLoggedIn]="isAdminLoggedIn"
                [currentRole]="currentRole" [currentUserEmail]="currentUser" class="w-100"></app-voice-player>
            </div>
          </div>
          <div class="btn-withdraw-app-wrapper" *ngIf="isAgentUser">
            <button class="btn-withdraw-app" (click)="showWithdraw()">
              <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="20" xmlns="http://www.w3.org/2000/svg"
                style="-webkit-print-color-adjust:exact" id="screenshot-96759b49-23b5-80e9-8003-ed0191b15990"
                version="1.1" viewBox="13643 -2865 20 20" height="20">
                <g id="shape-96759b49-23b5-80e9-8003-ed0191b15990">
                  <g class="fills" id="fills-96759b49-23b5-80e9-8003-ed0191b15990">
                    <path fill-rule="evenodd" clip-rule="evenodd" rx="0" ry="0" style="fill:#FFFFFF;fill-opacity:1"
                      d="M13644.464,-2863.536C13643.000,-2862.070,13643.000,-2859.714,13643.000,-2855.000C13643.000,-2850.286,13643.000,-2847.929,13644.464,-2846.465C13645.930,-2845.000,13648.286,-2845.000,13653.000,-2845.000C13657.714,-2845.000,13660.071,-2845.000,13661.535,-2846.465C13663.000,-2847.928,13663.000,-2850.284,13663.000,-2855.000C13663.000,-2859.714,13663.000,-2862.071,13661.535,-2863.536C13660.072,-2865.000,13657.714,-2865.000,13653.000,-2865.000C13648.286,-2865.000,13645.929,-2865.000,13644.464,-2863.536ZM13650.259,-2859.026C13650.456,-2859.208,13650.541,-2859.481,13650.481,-2859.743C13650.422,-2860.004,13650.228,-2860.214,13649.972,-2860.294C13649.716,-2860.373,13649.437,-2860.310,13649.240,-2860.128L13646.990,-2858.051C13646.836,-2857.909,13646.749,-2857.709,13646.749,-2857.500C13646.749,-2857.291,13646.836,-2857.091,13646.990,-2856.949L13649.240,-2854.872C13649.544,-2854.591,13650.019,-2854.610,13650.300,-2854.914C13650.581,-2855.218,13650.562,-2855.693,13650.258,-2855.974L13649.418,-2856.750L13655.038,-2856.750C13656.515,-2856.722,13657.698,-2855.516,13657.698,-2854.038C13657.698,-2852.561,13656.515,-2851.355,13655.038,-2851.327L13650.500,-2851.327C13650.086,-2851.327,13649.750,-2850.991,13649.750,-2850.577C13649.750,-2850.163,13650.086,-2849.827,13650.500,-2849.827L13655.039,-2849.827C13657.340,-2849.862,13659.186,-2851.738,13659.186,-2854.038C13659.186,-2856.339,13657.340,-2858.215,13655.039,-2858.250L13649.419,-2858.250Z">
                    </path>
                  </g>
                </g>
              </svg>
              <span style="color: #FFFFFF; font-weight: 400;" class="ms-3">Bewerbung zurückziehen</span>
            </button>
            <button class="default-button" (click)="updateAgent()">Speichern</button>
          </div>
        </div>
        <div class="rightSide" *ngIf="isAgentUser">
          <p style="font-weight: 500; margin-bottom: 40px;" class="user-name text-center">Stimme oder Video aufnehmen</p>
          <div class="d-flex">
            <div class="w-100">
              <app-record [agent]="agent"></app-record>
              <div>
                <app-voice-player [isDeleteActive]=true [isAdminLoggedIn]="isAdminLoggedIn" [isPublic]="isAgentUser"></app-voice-player>
              </div>
            </div>
          </div>
          <p class="text-center">(Die Videoaufnahme ist optional, maximal eine Minute.)</p>
        </div>
        <div class="rightSide" *ngIf="!isAgentUser">
          <p style="font-weight: 500" class="user-name mb-4">Verlauf</p>

          @if (isAdminLoggedIn && currentRole === 'Admin' && projectViewsCurrentRole === 'Admin') {
            <div class="mb-4" style="display: flex; flex-direction: row; gap: 8px;">
              <button
                class="default-button"
                [ngStyle]="{'background-color': openChat === 'chat' ? '#F5F5F5' : 'transparent'}"
                (click)="openChat = 'chat'"
                >
                  Nachrichten
                </button>
              <button
                class="default-button"
                [ngStyle]="{'background-color': openChat === 'whatsapp' ? '#F5F5F5' : 'transparent'}"
                (click)="openChat = 'whatsapp'">
                  WhatsApp
              </button>
            </div>
          }

          @if (openChat === 'chat') {
            <div #chatBot class="chat-bot mb-4">
              @for (msg of messages; track msg) {
                <div class="mb-2 mt-2">
                  <div class="d-flex" style="display: flex; flex-direction: row; justify-content: space-between;">
                    <div class="d-flex">
                      <p class="chat-user-name text-uppercase me-2 fw-medium mb-0">
                        {{ msg.sendersRole === 'Admin' ? 'SMYLO' : msg.sendersRole === 'Partner' ? 'Partner A GmbH' :
                        msg.sendersRole === 'Agent'? 'Agent':
                        'Kunde'}}
                      </p>
                      <span class="font-14px chat-time">{{ formatTimestamp(msg.timestamp) }}</span>
                    </div>
                    @if (isAdminLoggedIn && currentRole === 'Admin' && projectViewsCurrentRole === 'Admin') {
                      <div
                        style="display: flex; flex-direction: row; align-items: center; gap: 8px; padding: 2px; border-radius: 4px;"
                        [ngStyle]="{ 'background-color': msg.intern ? 'transparent' : 'red' }"
                      >
                        <span class="font-14px chat-time" [ngStyle]="{ 'color': msg.intern ? '#9da5b1' : 'white' }">{{ msg.intern ? 'Intern' : 'Extern' }}</span>
                        <ejs-switch (change)="onSwitchToggle($event, msg)" [checked]="msg.intern" [disabled]="disabledSwitch.get(msg.id!)" cssClass="e-small"></ejs-switch>
                      </div>
                    }
                  </div>
                  @if(msg.content.includes('Voicefile angefordert von')){
                    <div class="font-14px" [innerHTML]="AddTimeStamp(msg)"></div>
                  } @else {
                    <div class="font-14px" [innerHTML]="sanitizerHtml(msg.content)"></div>
                  }
                </div>
              }
            </div>
          } @else if (openChat === 'whatsapp') {
            <div #chatBot class="chat-bot mb-4">
              @for (msg of whatsAppMessages; track msg) {
                <div class="mb-4">
                  <div class="d-flex">
                    <p class="chat-user-name text-uppercase me-2 fw-medium mb-0">
                      {{ msg.senderName }}
                    </p>
                    <span class="font-14px chat-time">{{ formatTimestamp(msg.receivedAt) }}</span>
                  </div>
                  @if(msg.content.includes('Voicefile angefordert von')){
                    <div class="font-14px" [innerHTML]="AddTimeStamp(msg)"></div>
                  } @else {
                    <div class="font-14px" [innerHTML]="sanitizerHtml(msg.content)"></div>
                  }
                </div>
              }
            </div>
          }

          <div>
            <editor [init]="{ plugins: 'emoticons', promotion: false, branding: false, toolbar: 'bold italic underline', menubar: false, statusbar: false, width: '100%', height: '98.66px'
                        }" [(ngModel)]="messageToSent" outputFormat="html"></editor>

            <button class="send-btn d-block ms-auto" type="submit" (click)="sendMessage(false)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22" fill="currentColor">
                <path
                  d="M3.5 1.34558C3.58425 1.34558 3.66714 1.36687 3.74096 1.40747L22.2034 11.5618C22.4454 11.6949 22.5337 11.9989 22.4006 12.2409C22.3549 12.324 22.2865 12.3924 22.2034 12.4381L3.74096 22.5924C3.499 22.7255 3.19497 22.6372 3.06189 22.3953C3.02129 22.3214 3 22.2386 3 22.1543V1.84558C3 1.56944 3.22386 1.34558 3.5 1.34558ZM5 4.38249V10.9999H10V12.9999H5V19.6174L18.8499 11.9999L5 4.38249Z">
                </path>
              </svg>
            </button>
          </div>

          <div class="project-history" *ngIf="currentRole === 'Admin' && agent?.projectHistory">
            <h3 class="fw-semibold mb-2">Projekthistorie:</h3>
              <ul>
                @for (project of agentProjectHistory; track $index) {
                <li (click)="navigateToProject(project.id)">
                  {{ project.name }}
                  <span *ngIf="project.id == agent.project.id">(Laufendes Projekt)</span>
                </li>
              }
              </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="align-items-center justify-content-end w-100 modalViewBtn">
      <button class="default-button" (click)="dialogService.showAgentView = false;">Abbrechen</button>
      <button class="default-button ms-2" *ngIf="(currentRole ==='Client'||currentRole ==='Admin') && agent?.status!=='ApplicantRejected' && !isFromOpenTasks" (click)="currentRole === 'Admin'?openMoveAgent('archive'):changeAgentStatus('ApplicantRejected')">Ablehnen</button>
      <button class="default-button ms-2" *ngIf="currentRole ==='Admin' && !isFromOpenTasks && agent?.interviewAppointment !== null" (click)="onRemoveAppointment()">Interviewtermin entfernen</button>
      <button class="default-button ms-2" *ngIf="(currentRole ==='Partner'||currentRole ==='Admin') && !isFromOpenTasks" (click)="updateAgent()">Speichern</button>
      <button class="default-button ms-2" *ngIf="currentRole ==='Admin' && agent?.status==='ApplicantArchived'"
        (click)="reactivatAgent()">Reaktivieren</button>
      <button class="save-button flex-shrink-0 ms-2" *ngIf="(currentRole ==='Partner'||currentRole ==='Admin') && agent?.status==='AgentCreatedFilesMissing'" (click)="changeAgentStatus('ApplicantGetsChecked')">Weiter zur Prüfung</button>
      <button class="save-button flex-shrink-0 ms-2" *ngIf="currentRole ==='Client' && agent?.status==='ApplicantGetsSuggested'" (click)="changeAgentStatus('AppointmentScheduled')">Termin gewünscht</button>
    </div>

    <div class="camera-container"  [ngStyle]="{'display':!isWebcamOn?'none':''}">
      <video #videoElement autoplay></video>
      <canvas #canvasElement width="640" height="480" style="display: none;"></canvas>
      <div class="d-flex gap-4">
        <button (click)="closeWebcam()" mat-stroked-button class="mt-3">Abbrechen</button>
        <button (click)="takeSnapshot()" mat-stroked-button class="mt-3">Aufnehmen</button>
      </div>
    </div>
  <!-- </div> -->
</div>
<div *ngIf="isAgentUser && agent?.isApplicationWithdraw" class="withdraw-text">
  <p>
    Du hast deine Bewerbung erfolgreich zurückgezogen
  </p>
</div>
<div *ngIf="dialogService.showWithdrawApplicationDialog" class="backdrop"></div>
<app-agent-withdraw-application *ngIf="dialogService.showWithdrawApplicationDialog" (onWithdrawApplication)="onApplicationWithdraw()"></app-agent-withdraw-application>

<div *ngIf="dialogService.showMoveAgentDialog" class="backdrop"></div>
<app-move-agent *ngIf="dialogService.showMoveAgentDialog" [data]="agentMoveDate" (onAgentMove)="onAgentMove()"></app-move-agent>


<div *ngIf="isWebcamOn" class="backdrop"></div>

@if(isSpinnerActive) {
  <app-spinner></app-spinner>
}
