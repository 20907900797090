import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Agent, AgentProfilePhoto, AgentStatus, VideoFile, VoiceFile } from '../models/agent.model';
import { ConfigService } from 'src/app/config.service';
import { TimeSlots } from '../models/time-slot.model';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  private apiUrl = '';
  private feedbackApiUrl = '';
  private timeSlotUrl = '';
  private streamBaseUrl = '';

  constructor(private readonly configService: ConfigService, private readonly http: HttpClient) {
    this.apiUrl = `${this.configService.baseUrl}/agents`;
    this.timeSlotUrl = `${this.configService.baseUrl}/bookable-time-slots`;
    this.feedbackApiUrl = `${this.configService.baseUrl}/feedback`;
    this.streamBaseUrl = `${this.configService.baseUrl}/media-streaming`
  }

  getAgentsByProjectId(projectId: number) {
    return this.http.get<Agent[]>(`${this.apiUrl}/project/${projectId}`);
  }

  getAgentsByProjectIdAndPartnerName(projectId: number, partnerName: string) {
    let headers: HttpHeaders | undefined;
    if (partnerName !== '') {
      headers = new HttpHeaders({ partnerName });
    }
    return this.http.get<Agent[]>(`${this.apiUrl}/project/${projectId}`,{ headers });
  }

  getAgentById(id: number) {
    return this.http.get<Agent>(`${this.apiUrl}/${id}`);
  }

  createAgentForProject(agent: Agent, projectId: number) {
    return this.http.post<Agent>(`${this.apiUrl}/${projectId}`, agent);
  }

  updateAgent(id: number, agent: Agent) {
    return this.http.put<Agent>(`${this.apiUrl}/${id}`, agent);
  }

  updateAgentStatus(id: number, status: any) {
    return this.http.patch<Agent>(`${this.apiUrl}/update-status/${id}`, status);
  }

  archiveAgent(id: number, data: any) {
    return this.http.patch<Agent>(`${this.apiUrl}/archive-agent/${id}`, data);
  }

  agentMoveToOtherProject(id: number, newProjectId: any){
    return this.http.patch<Agent>(`${this.apiUrl}/move-agent/${id}`, newProjectId);
  }

  partiallyUpdateAgentById(id: number, agent: Partial<Agent>) {
    return this.http.patch<Agent>(`${this.apiUrl}/${id}`, agent);
  }

  deleteAgent(id: number) {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  deleteAgentByPublicId(id: number) {
    return this.http.delete<void>(`${this.apiUrl}/public/${id}`);
  }

  getVoiceFilesByAgentId(id: number) {
    return this.http.get<VoiceFile[]>(`${this.apiUrl}/${id}/voice-files`);
  }

  getVoiceFilesByPublicId(id: number) {
    return this.http.get<VoiceFile[]>(`${this.apiUrl}/public/${id}/voice-files`);
  }

  addVoiceFileToAgent(id: number, voiceFile: VoiceFile) {
    return this.http.post<VoiceFile>(`${this.apiUrl}/${id}/voice-files`, voiceFile);
  }

  deleteVoiceFile(id: number) {
    return this.http.delete<void>(`${this.apiUrl}/voice-files/${id}`);
  }

  deleteVoiceFileByPublicId(publicId: number,id: number) {
    return this.http.delete<void>(`${this.apiUrl}/public/${publicId}/voice-files/${id}`);
  }

  getVideoFilesByAgentId(id: number) {
    return this.http.get<VideoFile[]>(`${this.apiUrl}/${id}/video-files`);
  }

  getVideoFilesByPublicId(id: number) {
    return this.http.get<VideoFile[]>(`${this.apiUrl}/public/${id}/video-files`);
  }

  addVideoFileToAgent(id: number, videoFile: VideoFile) {
    return this.http.post<VideoFile>(`${this.apiUrl}/${id}/video-files`, videoFile);
  }

  deleteVideoFile(id: number) {
    return this.http.delete<void>(`${this.apiUrl}/video-files/${id}`);
  }

  deleteVideoFileByPublicId(publicId: number,id: number) {
    return this.http.delete<void>(`${this.apiUrl}/public/${publicId}/video-files/${id}`);
  }

  getAgentByPublicId(publicId: string) {
    return this.http.get<Agent>(`${this.apiUrl}/public/${publicId}`);
  }

  updateAgentByPublicId(publicId: string, agent: Agent) {
    return this.http.patch<Agent>(`${this.apiUrl}/public/${publicId}`, agent);
  }

  partiallyUpdateAgentByPublicId(publicId: string, agent: Partial<Agent>) {
    return this.http.patch<Agent>(`${this.apiUrl}/public/${publicId}`, agent);
  }

  sendTimeSlotsEmailsToAgent(agentId: number, timeSlots: TimeSlots) {
    return this.http.post<void>(`${this.apiUrl}/${agentId}/time-slots`, timeSlots);
  }

  bookTimeSlotsForAgent(timeSlots: any) {
    return this.http.post(`${this.timeSlotUrl}`, timeSlots);
  }

  updateBookedTimeSlotsForAgent(id: number, timeSlots: any) {
    return this.http.put(`${this.timeSlotUrl}/${id}`, timeSlots);
  }

  togglePublishedStatus(id: number) {
    return this.http.put<Agent>(`${this.apiUrl}/${id}/toggle-published-status`, null);
  }

  sendFeedback(feedback: any) {
    return this.http.post(`${this.feedbackApiUrl}`, feedback);
  }

  getAgentByStatus(status: AgentStatus) {
    return this.http.get<Agent>(`${this.apiUrl}/agents-by-status/${status}`);
  }

  setAgentProfilePhoto(id: number, agentProfilePhoto: any) {
    return this.http.post<AgentProfilePhoto>(`${this.apiUrl}/${id}/profile-photo`, agentProfilePhoto);
  }

  convertImagetoUrl(imageData:any){
    const bytes = new Uint8Array(imageData.data);
    let binary = '';
    bytes.forEach(byte => {
      binary += String.fromCharCode(byte);
    });
    return 'data:image/*;base64,' + btoa(binary);
  }

  getPartnerInfo(id:number){
    return this.http.get<Agent>(`${this.apiUrl}/partner-info/${id}`);
  }

  getClientInfo(id:number){
    return this.http.get<Agent>(`${this.apiUrl}/partner-info/${id}`);
  }

  scheduleAgentAppointment(agentId: string, data: any) {
    return this.http.patch<Agent>(`${this.apiUrl}/schedule-appointment/${agentId}`, data);
  }

  removeAgentAppointment(agentId: string, data: any) {
    return this.http.patch<Agent>(`${this.apiUrl}/remove-appointment/${agentId}`, data);
  }

  getArchiveAgentByReasons(reasons:string[], projectId:number, partnerId:string){
    let headers: HttpHeaders | undefined;
    if (partnerId !== '') {
      headers = new HttpHeaders({ partnerId });
    }
    return this.http.get<Agent[]>(`${this.apiUrl}/get_archive_agent_by_reasons`,{headers, params:{reasons,projectId}});
  }

  getAllAgentsByUserRole(partnerId:string) {
    let headers: HttpHeaders | undefined;
    if (partnerId !== '') {
      headers = new HttpHeaders({ partnerId });
    }
    return this.http.get<Agent[]>(`${this.apiUrl}/get-all-agent`,{headers});
  }

  getAgentByStatusForDashboard(partnerId:string, projectId: number){
    let headers: HttpHeaders | undefined;
    if (partnerId !== '') {
      headers = new HttpHeaders({ partnerId });
    }
    return this.http.get<Agent[]>(`${this.apiUrl}/get-agent-by-status-for-project/${projectId}`,{headers});
  }

  getAudioById(id: number){
    return this.http.get<Agent[]>(`${this.apiUrl}/get-audio-by-id/${id}`);
  }

  getVideoById(id: number){
    return this.http.get<Agent[]>(`${this.apiUrl}/get-video-by-id/${id}`);
  }


  getVideoStreamUrl(id: number): string {
    return `${this.streamBaseUrl}/video/${id}`;
  }

  getAudioStreamUrl(id: number): string {
    return `${this.streamBaseUrl}/audio/${id}`;
  }

  sendRejectionMail(email:string){
    return this.http.post(`${this.apiUrl}/send-rejection-mail`,{email});
  }
}
