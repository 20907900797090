<div class="Projektübersicht">

  <div class="headingLayout">

    <div class="richText2 d-flex gap-3 align-items-center" *ngIf="filteredProjects.length!=0 && !isSpinnerActive">
      <div>
        <span style="font-size: 18pt;">
          <strong>
            <span style="color: rgb(36, 184, 155);">Projekte</span>
          </strong>
        </span>
      </div>
      <div class="mat-search-wrapper">
        <mat-form-field appearance="outline" class="cust-outline-on-focus">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text" matInput placeholder="Suche" [formControl]="search">
        </mat-form-field>
      </div>
    </div>

    <button *ngIf="currentRole != 'Partner'" #thisbutton_cssbutton type="button" class="cssButton"
      (click)="selectProject($event, null)">

      <img [src]="'https://api.iconify.design/grommet-icons/user-new.svg' + '?color=%23' + 'ffffff'"
        style="margin-left: 8px; margin-right: 8px; width: 24px; height: 24px;">

      <div class="focus:outline-0 bg-transparent">
        <span> Neues Projekt </span>
      </div>

    </button>

  </div>

  <div class="projectArea">
    <div *ngFor="let project of filteredProjects" class="project" (click)="navigateToSingleProjectView(project.id)">
      <div class="upperArea">
        <!-- <div class="richText23">
          <div> -->
            <img
            src="{{project.projectThumbnailPhoto ?? 'https://raw.githubusercontent.com/Photonic-Codes-GmbH/resource-collection/main/2024-01-20%2017_48_30-Version%201%20-%20Penpot.png'}}"
            alt="" width="75%" height="75%" class="d-block" style="object-fit: contain;mix-blend-mode: multiply;" *ngIf="currentRole != 'Partner'">
            <img
            src="https://raw.githubusercontent.com/Photonic-Codes-GmbH/resource-collection/main/2024-01-20%2017_48_30-Version%201%20-%20Penpot.png"
            alt="" width="75%" height="75%" class="d-block" style="object-fit: contain;mix-blend-mode: multiply;" *ngIf="currentRole === 'Partner'">
          <!-- </div>
        </div> -->
      </div>
      <div class="lowerArea">
        <div class="richText234">
          <strong class="ellipsis" [matTooltip]="project.name.length > 18 ? project.name : ''">{{project.name}}</strong>
          <div class="editIcon" (click)="selectProject($event, project.id)" *ngIf="currentRole != 'Partner'">
            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0">
              <path
                d="M18.2522 2.6515C18.1951 2.58878 18.1259 2.53829 18.0488 2.50307C17.9716 2.46785 17.8882 2.44864 17.8034 2.44659C17.7186 2.44455 17.6343 2.45971 17.5555 2.49117C17.4768 2.52263 17.4052 2.56973 17.3452 2.62963L16.862 3.11049C16.8034 3.16908 16.7705 3.24854 16.7705 3.33138C16.7705 3.41423 16.8034 3.49368 16.862 3.55228L17.305 3.99447C17.334 4.02364 17.3685 4.04679 17.4065 4.06259C17.4445 4.07838 17.4853 4.08651 17.5264 4.08651C17.5676 4.08651 17.6084 4.07838 17.6464 4.06259C17.6844 4.04679 17.7189 4.02364 17.7479 3.99447L18.219 3.52572C18.4573 3.28783 18.4796 2.90033 18.2522 2.6515ZM15.885 4.08705L8.83347 11.1261C8.79072 11.1687 8.75964 11.2216 8.74324 11.2796L8.41706 12.2511C8.40925 12.2775 8.4087 12.3055 8.41546 12.3321C8.42223 12.3587 8.43606 12.3831 8.4555 12.4025C8.47494 12.422 8.49927 12.4358 8.52592 12.4426C8.55257 12.4493 8.58055 12.4488 8.60691 12.441L9.57761 12.1148C9.63568 12.0984 9.68855 12.0673 9.73113 12.0245L16.7702 4.9722C16.8353 4.90638 16.8718 4.81753 16.8718 4.72494C16.8718 4.63235 16.8353 4.54349 16.7702 4.47767L16.3815 4.08705C16.3156 4.02133 16.2263 3.98443 16.1333 3.98443C16.0402 3.98443 15.9509 4.02133 15.885 4.08705Z"
                fill="black" />
              <path
                d="M15.3772 8.13626L10.6158 12.907C10.4318 13.0914 10.2056 13.2283 9.95686 13.3058L8.94514 13.6445C8.70504 13.7123 8.45119 13.7148 8.20977 13.6519C7.96835 13.5889 7.74808 13.4627 7.57166 13.2863C7.39524 13.1099 7.26905 12.8896 7.20609 12.6482C7.14314 12.4068 7.1457 12.1529 7.2135 11.9128L7.55217 10.9011C7.62942 10.6524 7.76603 10.4262 7.95022 10.2421L12.7209 5.48001C12.7647 5.43632 12.7944 5.38066 12.8065 5.32004C12.8186 5.25943 12.8125 5.19659 12.7888 5.13947C12.7652 5.08235 12.7252 5.03353 12.6738 4.99916C12.6224 4.9648 12.562 4.94644 12.5002 4.94641H4.34827C3.76811 4.94641 3.21171 5.17688 2.80147 5.58712C2.39123 5.99735 2.16077 6.55375 2.16077 7.13391V16.5089C2.16077 17.0891 2.39123 17.6455 2.80147 18.0557C3.21171 18.4659 3.76811 18.6964 4.34827 18.6964H13.7233C14.3034 18.6964 14.8598 18.4659 15.2701 18.0557C15.6803 17.6455 15.9108 17.0891 15.9108 16.5089V8.35696C15.9107 8.29515 15.8924 8.23474 15.858 8.18336C15.8237 8.13198 15.7748 8.09195 15.7177 8.06833C15.6606 8.04471 15.5978 8.03855 15.5371 8.05064C15.4765 8.06273 15.4209 8.09253 15.3772 8.13626Z"
                fill="black" />
            </svg>
          </div>
        </div>
        <div class="Layout234">
          <div class="richText2345">
            <div><img src="https://api.iconify.design/material-symbols/support-agent.svg" alt="" width="22" height="22">
            </div>
          </div>
          <div class="richText23452">
            <div>
              <span [matTooltip]="currentRole !== 'Client'?'Dem Kunden vorgeschlagene Agenten':'vorgeschlagene Agenten'">
                {{ project.numberOfSuggestedAgents }}
              </span>
               /
              <span [matTooltip]="currentRole !== 'Client'?'Die Agenten, die das Angebot vom Kunden angenommen haben':'Zugesagte Agenten'">
                {{ project.numberOfAcceptedAgents }}
              </span>
               /
              <span matTooltip="Gesuchte Agenten">
                {{project.numberOfAgentsRequested}}
              </span>
               Agent
            </div>
          </div>
        </div>
        <div class="partner-badge" *ngIf="currentRole === 'Admin'"
        [ngStyle]="{'background-color':project.assignedPartners&&project.assignedPartners.length>0?'#24B89B':'#FCC419'}">
          {{project.assignedPartners?project.assignedPartners.length:0}} Partner zugewiesen
        </div>
      </div>
    </div>
    <div *ngIf="filteredProjects.length==0 && !isSpinnerActive" class="empty-project">
      Willkommen {{currentUserName}}, Sie haben aktuell noch keine offenen Projekte bei SMYLO
    </div>
  </div>

</div>

<!-- Backdrop -->
<div *ngIf="dialogService.showNewProjectDialog" class="backdrop"></div>

<newProject *ngIf="dialogService.showNewProjectDialog" [selectedProject]="selectedProject" (thumnailUpdated)="updateProjectThumnail($event)"></newProject>
@if (isSpinnerActive) {
  <app-spinner></app-spinner>
}
