import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { Message } from '../models/message.model';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private apiUrl = '';

  constructor(private readonly configService: ConfigService, private readonly http: HttpClient, private socket: Socket) {
    this.apiUrl = `${this.configService.baseUrl}/message`;
  }

  getMessagesByAgentandProjectId(agentId: string | null, projectId: number, role: string, coloumn: string) {
    return this.http.get<Message[]>(`${this.apiUrl}/${projectId}/${agentId}/${role}/${coloumn}`);
  }

  sendMessage(message: Message) {
    return this.http.post<Message>(`${this.apiUrl}`, message);
  }

  joinChannel(channel: string) {
    this.socket.emit('joinChannel', channel);
  }

  leaveChannel(channel: string) {
    this.socket.emit('leaveChannel', channel);
  }

  emitNewMessage(message: Message) {
    this.socket.emit('sendMessage', message);
  }

  getNewMessage(): Observable<any> {
    return this.socket.fromEvent<any>('newMessage');
  }

  countUnreadMessages(projectId: number, publicId: string) {
    return this.http.get(`${this.apiUrl}/${projectId}/${publicId}/count-unread-messages`);
  }

  markMessagesAsRead(message: Message) {
    return this.http.post(`${this.apiUrl}/${message.projectId}/${message.agentPublicId}/read`, {});
  }

  updateInternFlag(message: Message) {
    return this.http.put<Message>(`${this.apiUrl}/${message.id}/toggle`, {});
  }
}
