import { Directive, ElementRef, HostListener, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

@Directive({
  selector: 'input[date-format]',
  standalone: true,
})
export class DateFormatDirective {
  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: any
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const value = this.el.value.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let formattedValue = '';

    if (value.length >= 1 && value.length <= 2) {
      formattedValue = value; // Only day entered
    } else if (value.length >= 3 && value.length <= 4) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2, 4)}`; // Day and month
    } else if (value.length > 4) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2, 4)}.${value.substring(4, 8)}`; // Full date
    }

    this.el.value = formattedValue; // Update input with formatted value
  }

  @HostListener('blur')
  onBlur() {
    const value = this.el.value.trim();
    const parts = value.split('.');

    if (parts.length === 3) {
      const [day, month, year] = parts.map((part) => part.padStart(2, '0'));

      // Validate date parts
      if (
        this.isValidDay(day) &&
        this.isValidMonth(month) &&
        this.isValidYear(year)
      ) {
        const dateString = `${day}.${month}.${year}`;
        const parsedDate = this.dateAdapter.parse(
          dateString,
          this.dateFormats.parse.dateInput
        );

        if (parsedDate) {
          this.el.value = this.dateAdapter.format(
            parsedDate,
            this.dateFormats.display.dateInput
          );
        } 
      }
    } 
  }

  private isValidDay(day: string): boolean {
    const dayNumber = Number(day);
    return dayNumber >= 1 && dayNumber <= 31;
  }

  private isValidMonth(month: string): boolean {
    const monthNumber = Number(month);
    return monthNumber >= 1 && monthNumber <= 12;
  }

  private isValidYear(year: string): boolean {
    const yearNumber = Number(year);
    return yearNumber >= 1000 && yearNumber <= 9999;
  }
}
