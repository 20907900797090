import { Injectable, Inject } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  constructor(@Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    moment.locale(dateLocale);
  }

  override parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (value && typeof value === 'string') {
      const date = moment(value, parseFormat, true); 
      return date.isValid() ? date : null;
    }
    return super.parse(value, parseFormat);
  }

  override format(date: moment.Moment, displayFormat: string): string {
    if (!date.isValid()) {
      return '';
    }
    return date.format(displayFormat); 
  }
}

export const GermanDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY', 
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
