import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '../dialogservice.service';
import { AgentsService } from '../utils/services/agents.service';
import { ProjectsService } from '../utils/services/projects.service';
import { AgentStatus } from '../utils/models/agent.model';
import { SharedService } from '../utils/services/shared.service';
import { KeycloakService } from 'keycloak-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-move-agent',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './move-agent.component.html',
  styleUrl: './move-agent.component.scss',
})
export class MoveAgentComponent {
  reasonToMove = '';
  feedback = '';
  @Input() data: any;
  projectsList: any[] = [];
  newProject = '';
  @Output() onAgentMove: EventEmitter<any> = new EventEmitter();
  isToShowError:boolean = false;

  partnerInfo: {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
  } = { email: '', firstName: '', lastName: '', id: '' };
  isDisplayWarning: boolean = false;
  reasonToMoveInOtherProject: string = '';
  currentRole: string = '';
  currentUser: string = '';

  constructor(
    public dialogService: DialogService,
    public agentService: AgentsService,
    public projectsService: ProjectsService,
    public sharedService: SharedService,
    protected readonly keycloak: KeycloakService,
  ) {}

  ngOnInit() {
    this.getAllProjectList();
    this.getPartnerDetails(this.data.partnerId);
    console.log('data: ', this.data)
    const tokenData = this.keycloak.getKeycloakInstance().tokenParsed;
    const userRoles = tokenData?.realm_access?.roles;
    this.currentUser = tokenData?.['email'];


    if (userRoles?.includes('Client')) {
      this.currentRole = 'Client';
    } else if (userRoles?.includes('Partner')) {
      this.currentRole = 'Partner';
    } else if (userRoles?.includes('Admin')) {
      this.currentRole = 'Admin';
    }
  }

  onClickArchiv() {
    if (this.reasonToMove != '') {
      const obj = {
        reason:
          this.reasonToMove !== 'Other' ? this.reasonToMove : this.feedback,
        AgentStatus: AgentStatus.ApplicantArchived,
      };
      this.agentService
        .archiveAgent(Number(this.data.id), obj)
        .subscribe(() => {
          this.dialogService.showMoveAgentDialog = false;
          if((this.data.status =="ApplicantGetsSuggested"||this.data.status =="AppointmentScheduled") && this.currentRole == 'Admin'){
            this.agentService.sendRejectionMail(this.data.emailAddress).subscribe(()=>{});
          }
          const text = `Mit Begründung ins Archiv verschieben ${this.reasonToMove !== 'Other' ? this.reasonToMove : this.feedback}`;
          this.feedback = '';
          this.reasonToMove = '';
          this.onAgentMove.emit();
          this.sharedService.logAgentMoment(this.data,this.currentUser,this.currentRole,text);
        });
    }
  }

  onClickMove() {
    if (this.newProject != '' && this.reasonToMoveInOtherProject != '') {
      this.isToShowError = false;
      this.agentService
        .agentMoveToOtherProject(Number(this.data.id), {
          projectId: this.newProject,
          reasonToMoveInOtherProject: this.reasonToMoveInOtherProject,
        })
        .subscribe((receivedAgent) => {
          this.dialogService.showMoveAgentDialog = false;
          const text = `Verschieben in ein anderes Projekt mit Grund ${this.reasonToMoveInOtherProject}`;
          this.newProject = '';
          this.reasonToMoveInOtherProject = '';
          this.onAgentMove.emit();
          this.sharedService.logAgentMoment(receivedAgent, this.currentUser, this.currentRole, text);
        });
    } else {
      this.isToShowError = true;
    }
  }

  getAllProjectList() {
    this.projectsService.getAllProjects('', 'Admin').subscribe({
      next: (res: any) => {
        this.projectsList = res;
      },
      error: (error) => {
        console.error('Error fetching projects: ', error);
      },
    });
  }

  getPartnerDetails(partnerId: any) {
    this.agentService.getPartnerInfo(partnerId).subscribe((res: any) => {
      this.partnerInfo = { ...res, id: partnerId };
    });
  }

  onChangeProject() {
    const project = this.projectsList.find(
      (proj: any) => proj.id == this.newProject
    );

    if (
      project.assignedPartners &&
      project.assignedPartners.includes(this.data.partnerId)
    ) {
      this.isDisplayWarning = false;
    } else {
      this.isDisplayWarning = true;
    }
  }
}
