<div class="container h-100 d-flex flex-column align-items-center justify-content-center">
  <h1 style="font-size: 30px;">
    Auf Wiedersehen!
  </h1>
  <p style="font-size: 22px;">Sie wurden erfolgreich abgemeldet.</p>
  <div class="w-100 d-flex justify-content-center">
    <button mat-stroked-button class="mt-3" routerLink="/">
      Zur Anmeldung
    </button>
  </div>
</div>
