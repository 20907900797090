import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard extends KeycloakAuthGuard  {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {

      await this.keycloak.login({

        redirectUri: window.location.origin + state.url
      })

    }

    if (this.authenticated) {
      if(state.url == '/'){
        this.router.navigate(['/projectoverview']);
        return false; // <-- Verhindert das laden der Route durch den Guard, das haben bereits hierdrüber gemacht
      }else{
        return true;
      }
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles']

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}

