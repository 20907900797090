import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  @Input()
  public prefix: string = '';

  public userEmail: string = '';

  public showError: boolean = false;

  public constructor(public readonly projectDataCacheService: ProjectDataCacheService, private router: Router) {
  }

  public ngOnInit(): void {
  }

  /**
   * login method that saves the user email in the Application Storage
   */
  login(): void {
    // check first if userEmail is one of these three values "admin.user@photonic-codes.com", "client.user@photonic-codes.com" or "partner.user@photonic-codes.com"; if not please show an error in the html
    if (this.userEmail !== 'admin.user@photonic-codes.com' && this.userEmail !== 'client.user@photonic-codes.com' && this.userEmail !== 'partner.user@photonic-codes.com') {
      this.showError = true;
    } else {
      this.showError = false;
      localStorage.setItem('userEmail', this.userEmail);
      this.router.navigate(['/projectoverview']);
    }
  }
}
