import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userRoles: string[] = [];
  private userEmail: string = '';

  constructor() { }

  setUserRoles(roles: any): void {
    this.userRoles = roles;
  }

  getUserRoles(): string[] {
    return this.userRoles;
  }

  setUserEmail(email: string): void {
    this.userEmail = email;
  }

  getUserEmail(): string {
    return this.userEmail;
  }

}
