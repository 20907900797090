<div class="backdrop" id="backdrop"></div>
<div class="AgentView">
  <ng-container *ngIf="data.for==='archive'">
    <div class="heading">
      <h1 class="mb-0"><strong>Gründe</strong></h1>
    </div>
    <div class="detailArea">
      <div class="richText222">
        <div>
          <select class="form-select" [(ngModel)]="reasonToMove">
            <option disabled>Grund auswählen</option>
            <option value="No voicefile sent">Kein Voicefile gesendet</option>
            <option value="Rejected by customer for project">Vom Kunden für das Projekt abgelehnt</option>
            <optgroup label="Interessant für zukünftige Projekte">
              <option value="outbound">outbound</option>
              <option value="b2b outbound">b2b outbound</option>
            </optgroup>
            <option value="Other">Andere</option>
          </select>
          <div class="text-area" [hidden]="reasonToMove!=='Other'">
            <textarea rows="3" [(ngModel)]="feedback" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomRow">
      <button #thisbutton_cssbutton type="button" class="cssButton"
        (click)="dialogService.showMoveAgentDialog = false;feedback=''">
        <div class="focus:outline-0 bg-transparent">
          <span> Abbrechen </span>
        </div>
      </button>
      <button #thisbutton_cssbutton2 type="button" class="cssButton2" (click)="onClickArchiv()">
        <div class="focus:outline-0 bg-transparent">
          <span> Archivieren </span>
        </div>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="data.for==='otherProject'">
    <div class="heading">
      <h1 class="mb-0"><strong>Wählen Sie das Projekt</strong></h1>
    </div>
    <div class="detailArea">
      <div class="richText222">
        <div>
          <select class="form-select" [(ngModel)]="newProject" (change)="onChangeProject()">
            <option disabled>Projekt auswählen</option>
            <option *ngFor="let project of projectsList" class="project" [value]="project.id">{{project.name}}</option>
          </select>
          <div class="invalid-form" *ngIf="newProject==''&&isToShowError">
            Bitte auswählen ein Projekt.
          </div>
          <div class="text-area">
            <textarea rows="3" class="form-control" [(ngModel)]="reasonToMoveInOtherProject"
              placeholder="Grund für Umzug in anderes Projekt eingeben">
            </textarea>
          </div>
          <div class="invalid-form" *ngIf="reasonToMoveInOtherProject==''&&isToShowError">
            Bitte geben Sie den Grund an.
          </div>
          <div class="mt-3 warning" *ngIf="isDisplayWarning && !!data.partnerId">
            Warnung! Der Partner "{{partnerInfo.firstName}} {{partnerInfo.lastName}}" ist diesem Projekt nicht
            zugewiesen.
          </div>
        </div>
      </div>
    </div>
    <div class="bottomRow">
      <button #thisbutton_cssbutton type="button" class="cssButton"
        (click)="dialogService.showMoveAgentDialog = false;feedback=''">
        <div class="focus:outline-0 bg-transparent">
          <span> Abbrechen </span>
        </div>
      </button>
      <button #thisbutton_cssbutton2 type="button" class="cssButton" (click)="onClickMove()">
        <div class="focus:outline-0 bg-transparent">
          <span> Verschieben </span>
        </div>
      </button>
    </div>
  </ng-container>
</div>
