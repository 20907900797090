import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // please add the userEmail from the local storage to the request header
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
      request = request.clone({
        setHeaders: {
          userEmail: userEmail
        }
      });
    }

    return next.handle(request);
  }
}
