import { Project } from "./project.model";

export interface Agent {
  id?: number;
  public_id?: string;
  project: Project;
  partnerId?: string;
  partnerName?: string;
  firstname: string;
  lastname: string;
  status?: AgentStatus;
  city: string;
  country: string;
  phoneNumber: string;
  salutation: string;
  birthday: String | null;
  emailAddress: string;
  noticePeriod: string;
  isPublished?: boolean;
  isApplicationWithdraw?: boolean;
  availableFrom: String | null;
  experiences: string | null;
  b2bCapable?: string;
  germanLevel?: string;
  englishLevel?: string;
  outboundExperienceLevel?: string;
  internalNote?: string | null;
  interviewAppointment?: Date | null;
  voiceFiles?: VoiceFile[];
  projectHistory: string;
  createdAt?: Date;
  updatedAt?: Date;
  respondedOnWhatsApp?: boolean;
}

export enum AgentStatus {
  ApplicantChecked = 'ApplicantChecked',
  AppointmentSuggested = 'AppointmentSuggested',
  AppointmentScheduled = 'AppointmentScheduled',
  ApplicantAccepted = 'ApplicantAccepted',
  ApplicantRejected = 'ApplicantRejected',
  ApplicantConfirmed = 'ApplicantConfirmed',
  AgentCreatedFilesMissing = 'AgentCreatedFilesMissing',
  WaitingForAgent = 'WaitingForAgent',
  ApplicantGetsChecked = 'ApplicantGetsChecked',
  ApplicantGetsSuggested = 'ApplicantGetsSuggested',
  ApplicantArchived = 'ApplicantArchived'
}


export interface VoiceFile {
  id: number;
  agent: Agent;
  filename: string;
  path: string;
}

export interface VideoFile {
  id: number;
  agent: Agent;
  filename: string;
  path: string;
}
export interface AgentProfilePhoto {
  id: number;
  agent: Agent;
  filename: string;
  photoBlob: any;
}
